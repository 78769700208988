import React, { useState } from "react";
import { PageTitle } from "../PageTitle";
import AdminSearchService from '../../services/admin_search_service';

const AdminSearchView = () => {
    const [searchType, setSearchType] = useState('USER');
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [searchResults, setSearchResults] = useState(null);

    const executeSearch = (type, value) => {
        setIsSearching(true);
        setSearchResults(null);

        AdminSearchService.search({
            searchType: type,
            searchValue: value,
        }).then((response) => {
            setSearchResults(response.data.results);
            setHasSearched(true);
            setIsSearching(false);
        }).catch((error) => {
            setIsSearching(false);
            console.log(error); // TODO handle error
        });
    };

    return (
        <div>
            <PageTitle title="User and Location Search" />

            <div>
                <div className="op-content-box" style={{ marginBottom: 20 }}>
                    <div className="row">
                        <div className="col-sm-3">
                            <label>
                                <input className="inline" type="radio" checked={searchType == "USER"} onChange={() => {setSearchType("USER"); setSearchResults(null); setHasSearched(false);}} />
                                Search Users
                            </label>
                            <label>
                                <input className="inline" type="radio" checked={searchType == "LOCATION"} onChange={() => {setSearchType("LOCATION"); setSearchResults(null); setHasSearched(false);}} />
                                Search Locations
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 add-margin-top">
                            <label>Search for:</label>
                            <input type="text" style={{ width: "100%" }} value={searchValue} onChange={(event) => {setSearchValue(event.target.value); setSearchResults(null); setHasSearched(false);}} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3 add-margin-top">
                            <button className="btn btn-sm btn-default" onClick={() => executeSearch(searchType, searchValue)}>
                                Search
                            </button>
                        </div>
                    </div>

                    {isSearching ? <div className="loader">Loading...</div> : null}

                    {searchResults && searchType == "USER" && <UserSearchResults searchResults={searchResults} />}
                    {searchResults && searchType == "LOCATION" && <LocationSearchResults searchResults={searchResults} />}

                    {hasSearched && searchResults.length == 0 && <div className="row"><div className="col-sm-12 add-margin-top"><div className="row card-data-card-info" style={{marginLeft: '5px', marginBottom: '10px', marginRight: '5px'}}>No results found</div></div></div>}
                </div>
            </div>
        </div>
    );
};

const UserSearchResults = ({ searchResults }) => {
    return (
        <div className="row">
            <div className="col-sm-12 add-margin-top">
                {searchResults.map((row) => (
                    <div key={row.userId} className="row card-data-card-info" style={{marginLeft: '5px', marginBottom: '10px', marginRight: '5px'}}>
                        <div className="row">
                            <div className="col-sm-3"><label>Name:</label> {row.firstName} {row.lastName} </div>
                            <div className="col-sm-3"><label style={{paddingTop:5}}>Enterprise Name:</label> {row.enterpriseName} </div>
                            <div className="col-sm-3"><label style={{paddingTop:5}}>Username:</label> {row.username} </div>
                        </div>
                        <div className="row">
                            {row.emailAddresses && <div className="col-sm-3"><label style={{paddingTop:5}}>Email Addresses:</label> {row.emailAddresses} </div>}
                            {row.smsNumbers && <div className="col-sm-3"><label style={{paddingTop:5}}>SMS Numbers:</label> {row.smsNumbers} </div>}
                            {row.voiceNumbers && <div className="col-sm-3"><label style={{paddingTop:5}}>Voice Numbers:</label> {row.voiceNumbers} </div>}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const LocationSearchResults = ({ searchResults }) => {
    return (
        <div className="row">
            <div className="col-sm-12 add-margin-top">
                {searchResults.map((row) => (
                    <div key={row.locationId} className="row card-data-card-info" style={{marginLeft: '5px', marginBottom: '10px', marginRight: '5px' }}>
                        <div className="col-sm-3"><label style={{ paddingTop: 5 }}>Location Name:</label> {row.locationName}</div>
                        <div className="col-sm-3"><label style={{ paddingTop: 5 }}>Enterprise Name:</label> {row.enterpriseName}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminSearchView;