import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { TextInput } from '../TextInput';
import { PasswordInput } from '../PasswordInput';
import i18next from "i18next";
import { ValidationHelper } from '../../helpers';

const validate = values => {
  const errors = {}
  if (!values.username) {
    errors.username =  i18next.t('messages.please-enter-user-name');
  } else if (!ValidationHelper.validateUsername(values.username)) {
    errors.username = i18next.t('messages.username-invalid');
  }

  if (!values.password) {
    errors.password = i18next.t('messages.please-enter-password');
  }

  return errors
}
class SigninForm extends React.Component {

  renderAlert() {
    if (this.props.errorMessage) {
      return <div className="alert alert-danger">
        <strong>{ i18next.t('messages.oops') }</strong>{this.props.errorMessage}
      </div>
    }
  }

  render() {
    const {handleSubmit} = this.props

    return (
      <div className="login-wrapper">
        <div className="login-form-wrapper">
          {this.renderAlert()}
          <div className="login-form">
            <div className="login-form-branding text-center"/>
            <form onSubmit={handleSubmit}>
              <div>
              <Field
                  name="username"
                  type="text"
                  component={TextInput}
                  label={ i18next.t('form-labels.username') }
                  login={true}
	          login_label={"username"}
                />
              </div>
              <div>
                <Field
                  name="password"
                  type="password"
                  component={PasswordInput}
                  label={ i18next.t('form-labels.password') }
	            />
              </div>
              <button type="submit" className="btn btn-primary btn-block">{ i18next.t('pages.sign-in.log-in') }</button>
            </form>
            <div className="login-form-forgot-password">
              <Link to="/forgotpassword">{ i18next.t('pages.sign-in.forgot-my-password') }</Link>
            </div>
            <div className="login-form-terms">
              <a href="https://www.opsense.com/terms-of-use/">{ i18next.t('terms-of-use') }</a> &nbsp; <a href="https://www.opsense.com/data-use/">{ i18next.t('data-use') }</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'signin', validate
})(SigninForm)
