import React from 'react';
import { PageTitle } from '../../PageTitle';
import update from 'immutability-helper';
import { Message } from '../../Message';
import { UsersService } from '../../../services';
import NotificationAddEmail from './add_email';
import NotificationAddPhone from './add_phone';
import { Button, Modal} from 'react-bootstrap';
import i18next from 'i18next';

// TODO need to add a success and error alert banner to this

class NotificationPreferences extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      notificationPreferences: null
    };
  }

  componentDidMount() {
    UsersService.getMyNotificationPreferences().then(response => {
      this.setState({ notificationPreferences: response.notificationPreferences });
    }).catch(error => {
      this.handleLocalError(i18next.t('messages.error-getting-notification-preferences'));
      console.error(error);
    });
  }

  render() {
    if (this.state.notificationPreferences) {
      return (<NotificationPreferencesList notificationPreferences={this.state.notificationPreferences} />);
    } else {
      return (
          <div className="loader">{i18next.t('loading')}</div>
      );
    }
  }
}


class NotificationPreferencesList extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      notificationPreferences: props.notificationPreferences,
      showEmailModal: false,
      showSMSModal: false,
      deleted: false,
      localErrorMessage: null,
      localSuccessMessage: null,
    };

    this.toggleEmailModal = this.toggleEmailModal.bind(this);
    this.toggleSMSModal = this.toggleSMSModal.bind(this);
  }

  handleLocalError(localErrorMessage) {
    this.setState({ localErrorMessage: localErrorMessage });
  }

  messageDismissCallback() {
    this.setState({
      localErrorMessage: null,
      localSuccessMessage: null
    });
  }

  handleEmailSubmit({email, textOnlyEmail}) {
    textOnlyEmail = textOnlyEmail !== undefined && textOnlyEmail;

    const localNotificationPreferences = update(this.state.notificationPreferences, { $push: [{ email: email, active: true, textOnlyEmail: textOnlyEmail }] });

    const existingnotificationPreferences = this.state.notificationPreferences;
    this.setState({ notificationPreferences: null });

    UsersService.saveMyNotificationPreferences(localNotificationPreferences).then(response => {
      this.toggleEmailModal();
      this.setState({ notificationPreferences : response.notificationPreferences, localSuccessMessage: i18next.t('messages.email-added')});
    }).catch(error => {
      this.toggleEmailModal();
      this.setState({ notificationPreferences: existingnotificationPreferences, localErrorMessage: i18next.t('messages.error-adding-email') });
      console.error(error);
    });
  }

  formatNumber(phone) {
    return phone.charAt(1) + phone.substring(phone.indexOf('(')+1,phone.indexOf(')'))
    + phone.substring(phone.indexOf(')')+2,phone.indexOf('-')) + phone.substring(phone.indexOf('-')+1);
  }

  handlePhoneSubmit({phone}) {
    //parsing phone number into 1xxxxxxxxxx format
    const newPhone = this.formatNumber(phone);

    
    const preference = { active: true };
    switch(this.state.showSMSModal) {
      case "sms": { 
        preference.sms = newPhone;
      } break;
      case "voice":  {
        preference.voice = newPhone;
      } break;
    }

    const localNotificationPreferences = update(this.state.notificationPreferences, { $push: [preference] });

    const existingnotificationPreferences = this.state.notificationPreferences;
    this.setState({ notificationPreferences: null });
    UsersService.saveMyNotificationPreferences(localNotificationPreferences).then(response => {
      this.toggleSMSModal();
      this.setState({ notificationPreferences : response.notificationPreferences,
        localSuccessMessage: i18next.t('messages.phone-number-saved') });
    }).catch(error => {
      this.toggleSMSModal();
      this.setState({ notificationPreferences: existingnotificationPreferences, localErrorMessage: i18next.t('messages.error-adding-phone-number') });
      console.error(error);
    });
  }

  toggleEmailModal() {
    this.setState({
      showEmailModal: !this.state.showEmailModal
    });
  }

  toggleSMSModal(value=false) {
    this.setState({
      showSMSModal: value
    });
  }

  addPhoneNumberTitle() {
    switch(this.state.showSMSModal) {
      case "sms": { 
        return i18next.t('pages.users.add-sms-number');
      } break;
      case "voice":  {
        return i18next.t('pages.users.add-voice-number');
      } break;
    }
    return "";
  }

  removePrefAction(toRemove) {
    let notificationType = i18next.t('email');
    if( toRemove.sms != null) {
      notificationType = i18next.t('sms-number');
    } else if( toRemove.userDevicePushRegistration != null) {
      notificationType = i18next.t('push-device');
    } else if( toRemove.voice != null) {
      notificationType = i18next.t('voice-number');
    }

    const existingnotificationPreferences = this.state.notificationPreferences;
    this.setState({ notificationPreferences: null }, () => {

      UsersService.deleteMyNotificationPreference(toRemove.id).then(response => {
        this.setState({ notificationPreferences : response.notificationPreferences, localSuccessMessage: i18next.t('messages.notification-type-removed', {type: notificationType})});
      }).catch(error => {
        this.setState({ notificationPreferences: existingnotificationPreferences, localErrorMessage: i18next.t('messages.error-deleting-notification-preference', {type: notificationType})});
        console.error(error);
      });

    });
  }

  sendTestPrefAction(toTest) {
    const existingnotificationPreferences = this.state.notificationPreferences;
    this.setState({ notificationPreferences: null }, () => {

      UsersService.testMyNotificationPreference(toTest.id).then(response => {
        this.setState({ notificationPreferences: existingnotificationPreferences, localSuccessMessage: i18next.t('messages.test-notification-sent') });
      }).catch(error => {
        this.setState({ notificationPreferences: existingnotificationPreferences, localErrorMessage: i18next.t('messages.error-sending-test-notification') });
        console.error(error);
      });

    });
  }

  render () {
    if(!this.state.notificationPreferences) {
      return ( <div className="loader">{i18next.t('loading')}</div> );
    }

    const emailPreferences = this.state.notificationPreferences
        .filter(preference => preference.email != null)
        .map(preference => <PreferenceRow key={preference.id} preference={preference} sendTestPrefAction={this.sendTestPrefAction.bind(this)} removePref={this.removePrefAction.bind(this)} />);
    const smsPreferences = this.state.notificationPreferences
        .filter(preference => preference.sms != null)
        .map(preference => <PreferenceRow key={preference.id} preference={preference} sendTestPrefAction={this.sendTestPrefAction.bind(this)} removePref={this.removePrefAction.bind(this)} />);
    const voicePreferences = this.state.notificationPreferences
        .filter(preference => preference.voice != null)
        .map(preference => <PreferenceRow key={preference.id} preference={preference} sendTestPrefAction={this.sendTestPrefAction.bind(this)} removePref={this.removePrefAction.bind(this)} />);
    const pushPreferences = this.state.notificationPreferences
        .filter(preference => preference.userDevicePushRegistration != null)
        .map(preference => <PreferenceRow key={preference.id} preference={preference} sendTestPrefAction={this.sendTestPrefAction.bind(this)} removePref={this.removePrefAction.bind(this)} />);

    return (
        <div>
          <div className="row">
            <PageTitle title={i18next.t('pages.users.notifications-and-report-info')}
            backLink="/user/profile" />
            <div className="col-sm-12" style={{paddingBottom: '16px'}}>
              <Message
                  messages={{ state: { successMessage: this.state.localSuccessMessage } }}
                  errorMessage={this.state.localErrorMessage}
                  messageDismissCallback={this.messageDismissCallback.bind(this)}
              />
            </div>
          </div>
          <div className="content-wrapper__centered-content">

            <div className="container-child__50">
              <div className="op-content-box">
                <table className="table table-hover">
                  <thead>
                  <tr>
                    <th><h4>{i18next.t('email')}</h4></th>
                    <th colSpan="2" className="text-right"><Button className="btn btn-xs btn-primary" onClick={this.toggleEmailModal}>{i18next.t('add')} <span className="hidden-xs">{i18next.t('email')}</span></Button></th>
                  </tr>
                  </thead>
                  <tbody>
                  {emailPreferences}
                  </tbody>
                </table>

                <Modal show={this.state.showEmailModal} onHide={this.toggleEmailModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>{i18next.t('pages.users.add-email')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <NotificationAddEmail onSubmit={this.handleEmailSubmit.bind(this)} onCancel={this.toggleEmailModal} />
                  </Modal.Body>
                </Modal>

              </div>
            </div>
            <Modal show={this.state.showSMSModal!=false} onHide={() => this.toggleSMSModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>{this.addPhoneNumberTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <NotificationAddPhone onSubmit={this.handlePhoneSubmit.bind(this)} onCancel={this.toggleSMSModal} />
              </Modal.Body>
            </Modal>
            <div className="container-child__50">
              <div className="card op-content-box">
                <div className="card-block">
                  <table className="table table-hover">
                    <thead>
                    <tr>
                      <th><h4>{i18next.t('sms-number')}</h4></th>
                      <th colSpan="2" className="text-right"><Button className="btn btn-xs btn-primary" onClick={() => this.toggleSMSModal("sms")}>{i18next.t('add')} <span className="hidden-xs">{i18next.t('sms-number')}</span></Button></th>
                    </tr>
                    </thead>
                    <tbody>
                    {smsPreferences}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="container-child__50">
              <div className="card op-content-box">
                <div className="card-block">
                  <table className="table table-hover">
                    <thead>
                    <tr>
                      <th><h4>{i18next.t('voice-number')}</h4></th>
                      <th colSpan="2" className="text-right"><Button className="btn btn-xs btn-primary" onClick={() => this.toggleSMSModal("voice")}>{i18next.t('add')} <span className="hidden-xs">{i18next.t('voice-number')}</span></Button></th>
                    </tr>
                    </thead>
                    <tbody>
                    {voicePreferences}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="container-child__50">
              <div className="card op-content-box">
                <div className="card-block">
                  <table className="table table-hover">
                    <thead>
                    <tr>
                      <th><h4>{i18next.t('push-devices')}</h4></th>
                      <th colSpan="2" className="text-right">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pushPreferences}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

class PreferenceRow extends React.Component {

  smsFormat(sms) {
    return `+${sms.charAt(0)} (${sms.substring(1,4)}) ${sms.substring(4,7)}-${sms.substring(7)}`;
  }

  printEmail(preference) {
    return preference.textOnlyEmail ? `${preference.email} ${i18next.t('pages.users.text-only-emails')}` : `${preference.email}`;
  }

  render() {
    let rowValue = '';
    if (this.props.preference.sms) {
      rowValue = this.smsFormat(this.props.preference.sms);
    } else if (this.props.preference.email) {
      rowValue = this.printEmail(this.props.preference);
    } else if (this.props.preference.userDevicePushRegistration) {
      rowValue = this.props.preference.userDevicePushRegistration.name;
    } else if (this.props.preference.voice) {
      rowValue = this.smsFormat(this.props.preference.voice);
    }

    return (
        <tr>
          <td className="break-word">{rowValue}</td>
          <td className="text-right">
            <Button className="btn btn-xs" onClick={() => this.props.sendTestPrefAction(this.props.preference)}>{i18next.t('test')}</Button>
          </td>
          <td className="text-right">
            <Button className="btn btn-xs" onClick={() => this.props.removePref(this.props.preference)}>{i18next.t('remove')}</Button>
          </td>
        </tr>
    );
  }
}

export default NotificationPreferences;
