import React from 'react';
import i18next from "i18next";
//means to pivot styles on i18next library
const TranslatedStyles = () => (
  <style>{`
  .op-icon-refresh:after {
    content: '${ i18next.t('refresh')}';
  }
  .top-nav__mobile-nav-button::after {
      content: '\\2261  ${ i18next.t('menus.menu')}';
    }
    .top-nav__mobile-nav-button.open::after {
      content: '\\D7  ${ i18next.t('menus.close')}';
    }
  }
  `}</style>
);

export default TranslatedStyles;
