import React from 'react';
import QueryString from 'query-string';

import ReactTable from 'react-table';

import { Redirect } from 'react-router-dom';
import { PageTitle } from "../PageTitle";
import { Message } from '../Message';
import { BaseTable } from '../../helpers';
import { CommissioningService, EnterprisesService, TimeFormattingService } from "../../services";

class Fleets extends React.Component {
  constructor(props) {
    super(props);

    const passedState = props.history.location ? props.history.location.state : null;

    this.DEFAULT_SORTED = [{id: 'description', desc: false}];

    const parsedOptions = QueryString.parse(this.props.location.search);

    this.state = {
      searchValue: parsedOptions && parsedOptions.serialNumber ? parsedOptions.serialNumber : "",
      addFleetName: null,
      addFleetEnterprise: null,
      enterpriseOptions: [],
      pageCount: 0,
      forcePage: null,
      parsedOptions: parsedOptions,
      searching: false,
      currentHistoryCount: passedState && passedState.currentHistoryCount ? passedState.currentHistoryCount : 0,      
      localErrorMessage: null,
      localSuccessMessage: null, 
      data: [],
      pages: null,
      totalElements: null,
    };

    this.refresh = this.refresh.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.datafetched = this.datafetched.bind(this);
    this.makeDatatableRequest = this.makeDatatableRequest.bind(this);    
  }

  handleLocalSuccess(localSuccessMessage) {
    this.setState({localSuccessMessage: localSuccessMessage});
  }

  localErrorHandler(localErrorMessage) {
    this.setState({ localErrorMessage: localErrorMessage });
  }

  messageDismissCallback() {
    this.setState({
      localErrorMessage: null,
      localSuccessMessage: null
    });
  }

  componentDidMount() {
    EnterprisesService.getAll().then(response => {
      this.setState({
        enterpriseOptions: response.enterprises.map(enterprise => {return {value: enterprise.id, label: enterprise.name}})
      });      
    }).catch(error => {
      console.error(error);
      this.localErrorHandler(error.message);
      this.setState({searching: false, hasError: true});
    });
  }

  refresh() {
    if (this.refs.fleetTable) {
        this.fetchData(this.refs.fleetTable)
    }
  }

  makeDatatableRequest (data, callback) {
    let options = [
      { 'size': data.pageSize },
      { 'page': data.page }
    ];

    let neworder = data.sorted ? data.sorted : this.refs.fleetTable ? this.refs.fleetTable.state.sorted : null;

     if (neworder && neworder.length > 0) {
       neworder.forEach(order => {
         options.push({ 'sort': `${order.id},${order.desc ? "desc":"asc"}` });
       })
     }

    CommissioningService.listFleets(options).then(response => {
      callback({
        data: response.fleets,
        recordsTotal: response.page.totalElements,
        recordsFiltered: response.page.totalElements
      });
    }).catch(error => {
      console.error(error);
      this.localErrorHandler(error.message);
      this.setState({searching: false, hasError: true});
    });
  }

  datafetched(res) {
    let size = this.refs.fleetTable.state.pageSize;
    let page = this.refs.fleetTable.state.page;
    this.setState({
      data: res.data,
      pages: Math.ceil(res.recordsTotal / size),
      page: page,
      pageSize: size,
      searching: false,
      totalElements: res.recordsTotal
    });
  }
  
  fetchData(state) {
    this.setState({
      searching: true
    });
    this.makeDatatableRequest(state, this.datafetched);
  }

  manageClicked(fleet) {
    this.setState({
      linkTo: `/admin-devices/fleets/manage/${fleet.id}`
    });
  }

  renderNormalTime(data) {
    const timezone = data.original.deviceGrouping && data.original.deviceGrouping.timezone ? data.original.deviceGrouping.timezone : null;
    const date = TimeFormattingService.renderDate(data.value, timezone);
    const time = TimeFormattingService.renderTime(data.value, timezone);
    return (<span>{date} {time}</span>);
  }

  addFleetCalled() {
    this.setState({searching: true});

    const newFleet = {
      description: this.state.addFleetName, 
      commissionedForEnterpriseId: this.state.addFleetEnterprise.value,
      deviceInventoryIdsToCommission: []
    };

    CommissioningService.createFleet(newFleet).then(response => {
      if (response.errors.length > 0) {
        this.localErrorHandler("One or more of the devices beeing added failed to commission and will need to be tried again: " + response.errors.join(", "));
        this.setState({searching: false, hasError: true});
      } else {
        this.setState({
          searching: false
        }, () => {
          this.manageClicked(response.fleet)
        });
      }
    }).catch(error => {
      console.error(error);
      this.localErrorHandler(error.message);
      this.setState({searching: false, hasError: true});
    });
  }

  render() {
      if (this.state.linkTo) {
        if (this.state.query) {
          return <Redirect push to={{ pathname: this.state.linkTo, query: this.state.query, state: { backLinkSearch: this.state.backLinkSearch }}} />;
        } else {
          return <Redirect push to={{ pathname: this.state.linkTo, state: { backLinkSearch: this.state.backLinkSearch }}} />;
        }
      }

    let dynamicOptions = BaseTable.buildDynamicOptions(this.state.parsedOptions, this.state.forcePage, this.DEFAULT_SORTED);

    const { data, pages, searching, totalElements } = this.state;

    return (
      <div className = "admin-devices">
        <div className="row">
          <PageTitle title={ "Fleets" } refreshFunction={this.refresh.bind(this)} />
        </div>
        
        { this.state.localErrorMessage ? <Message errorMessage={ this.state.localErrorMessage } messageDismissCallback={ this.messageDismissCallback.bind(this) } /> : null }

        { this.state.localSuccessMessage ? <Message messages={{ state: { successMessage: this.state.localSuccessMessage } }} messageDismissCallback={ this.messageDismissCallback.bind(this) } /> : null}

        <div className="inner op-content-box">
          <div className="row">
            <div className="col-sm-12">
              <div className="card-block">
                <div className="react-table-custom">
                  <ReactTable
                    className="-striped -highlight"
                    ref="fleetTable"
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    data={data}
                    pages={pages} // Display the total number of pages
                    loading={searching} // Display the loading overlay when we need it
                    onFetchData={this.fetchData} // Request new data when things change
                    totalElements={totalElements}
                    showPaginationTop
                    columns={[
                                  {
                                  Header: "Description",
                                  id: 'description',
                                  accessor: "description",
                                  minResizeWidth: 200,
                                  sortable: true,
                                  width: 400
                                },                    
                                {
                                  Header: "Creation Time",
                                  accessor: "startedAt",
                                  Cell: cell => this.renderNormalTime(cell),
                                  sortable: true,
                                  minResizeWidth: 100,
                                },                    
                                {
                                  Header: "Commissioned For",
                                  accessor: "commissionedFor.name",
                                  sortable: true,
                                  minResizeWidth: 100,
                                },
                                {
                                  Cell: cell => (<span>
                                      <button className="btn btn-sm btn-default" onClick={() => this.manageClicked(cell.original)}>Manage</button>
                                    </span>),
                                  sortable: false,
                                  minResizeWidth: 50,
                                  width: 300
                                }
                            ]}
                    {...dynamicOptions}
                  />
                </div>
              </div>
            </div>
          </div>          
        </div>
        
      </div>
    );
  }
}

export default Fleets;
