import React from 'react';

import { FormatHelper } from '../../helpers';

const SelectIconRenderer = (props) => {
  if (props.thresholdClassification) {
    return (
      <div className="option-with-icon" value={props.value}>
        <img src={`images/icon/sensor_${props.thresholdClassification.token}.svg`} />
        {props.label}
      </div>
    );
  } else if (props.deviceDataType) {
    return (
      <div className="option-with-icon" value={props.value}>
        <img src={`images/icon/sensor_${props.deviceDataType.token}.svg`} />
        {props.label}
      </div>
    );
  } else if (props.alertLevel) {
    return (
      <div className={`criticality-indicator criticality-${props.alertLevel.token}`} value={props.value}>
        {props.label}
      </div>
    );
  } else if (props.threshold) {
    let measurement = FormatHelper.formatMeasurement(props.threshold);
    return (
      <div className="option-with-icon" value={props.value}>
        <img src={`images/icon/sensor_${props.threshold.thresholdClassification.token}.svg`} />
        {props.threshold.name} ({props.threshold.thresholdEvaluationType.shortForm} {measurement})
      </div>
    );
  } else {
    return (
      <div value={props.value}>
        {props.label}
      </div>
    );
  }
}

export default SelectIconRenderer;
