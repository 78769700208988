import React, { useEffect, useState } from "react";
import { PageTitle } from "../PageTitle";
import ReactTable from "react-table";
import { InventoryService, TimeFormattingService } from "../../services";

const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const renderNormalTime = (data) => {
    const date = TimeFormattingService.renderDate(data, null);
    const time = TimeFormattingService.renderTime(data, null);
    return (<span>{date} {time}</span>);
};

const columns = [
    {
        Header: "MAC Address",
        accessor: "deviceRadioMacAddress",
        minResizeWidth: 100,
        width: 200,
        sortable: false,
    },
    {
        Header: "Device Type",
        accessor: "deviceType.name",
        minResizeWidth: 200,
        width: 400,
        sortable: false,
    },
    {
        Header: "Import Date",
        accessor: "importedOn",
        Cell: ({original}) => renderNormalTime(original.importedOn),
        minResizeWidth: 100,
        width: 200,
    },
    {
        Header: "Actions",
        sortable: false,
        Cell: ({original}) => (
            <div>
                <button className="btn btn-sm btn-default" onClick={() => console.log(original.referenceId)}>View</button>
                {" "}
                <button className="btn btn-sm btn-default" onClick={() => console.log(original.referenceId)}>Edit</button>
            </div>
        ),
    },
];

const InventoryList = () => {
    const [loading, setLoading] = useState(false);
    const [tableConfig, setTableConfig] = useState({});
    const [searchConfig, setSearchConfig] = useState({});
    const [inventory, setInventory] = useState([]);

    useEffect(() => {
        setLoading(true);

        let queryConfig = [];
        Object.keys(searchConfig).forEach((key) => {
            if (searchConfig[key] != null) {
                var entry = {};
                entry[key] = searchConfig[key];
                queryConfig.push(entry);
            }
        });

        InventoryService.getInventory(queryConfig)
            .then(({ inventory, page }) => {
                setInventory(inventory);
                setTableConfig({ ...tableConfig, pageCount: page.totalPages, totalElements: page.totalElements });
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, [searchConfig]);

    const fetchData = (tableConfig) => {
        let sortOptions = [];

        if (tableConfig.sorted && tableConfig.sorted.length > 0) {
            sortOptions = [];
            tableConfig.sorted.forEach((order) => {sortOptions.push(`${camelToSnakeCase(order.id)},${order.desc ? "desc" : "asc"}`);});
        } else {
            sortOptions.push("imported_on,desc");
        }

        setSearchConfig({ page: tableConfig.page, size: tableConfig.pageSize, sort: sortOptions });
    };

    return (
        <div>
            <div className="row">
                <PageTitle title="Inventory" addLink={{ link: { pathname: "/admin-fulfillment/inventory/add" } }} />
            </div>
            <div className="inner op-content-box">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="react-table-custom">   
                            <ReactTable
                                className="-striped -highlight"
                                data={inventory}
                                loading={loading}
                                showPaginationTop
                                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                onFetchData={fetchData} // Request new data when things change
                                pages={tableConfig.pageCount} // Display the total number of pages
                                totalElements={tableConfig.totalElements}
                                columns={columns}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InventoryList;