import React from 'react';
import { connect } from 'react-redux';

import { PageTitle } from '../PageTitle';
import { LocalesService, UsersService } from "../../services";
import UserUpdatePasswordForm from "./user_update_password_form";
import UserUpdateLocaleForm from "./user_update_locale_form";
import Message from "../Message/message";
import i18next from "i18next";

@connect(state => {
  return {
    me: state.user.me
  }
})
class UserProfile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      updated: null,
      errorMessage: null,
      localErrorMessage: null,
      localSuccessMessage: null,
      user: this.props.user
    };
  }

  componentDidMount() {
    LocalesService.getAll().then(response => {
      let selectedLocale = response.locales.find(locale => locale.languageTag === this.props.me.locale.languageTag);
      this.setState({
        locales: response.locales.map(locale => {return {value: locale.languageTag, label: locale.description}}),
        selectedLocale: selectedLocale ? {value: selectedLocale.languageTag, label: selectedLocale.description} : {value: response.locales[0].languageTag, label: response.locales[0].description}
      });
    }).catch(err => {
      console.error(err);
      this.setState({localErrorMessage: i18next.t('messages.could-not-load-languages')});
    })
  }

  renderMessages() {
    if (this.state.localErrorMessage) {
      return (
          <Message messages={{ state: { errorMessage: this.state.localErrorMessage } }} messageDismissCallback={ this.messageDismissCallback.bind(this) } />
      );
    }

    if (this.state.localSuccessMessage) {
      return (
          <Message messages={{ state: { successMessage: this.state.localSuccessMessage } }} messageDismissCallback={ this.messageDismissCallback.bind(this) } />
      );
    }

    if (this.props.location.state) {
      return (
          <Message messages={ this.props.location } />
      );
    }
    return null;
  }

  messageDismissCallback() {
    this.setState({ localSuccessMessage: null });
  }

  handleSubmit({password}) {
    let user = this.props.me;
    user.locale = user.locale.languageTag;
    user.password = password;
    this.setState({ localSuccessMessage: null, localErrorMessage: null });
    UsersService.saveMe(user).then(() => {
      this.setState({ localSuccessMessage: i18next.t('messages.password-updated-successfully') });
    }).catch(error => {
      console.error(error);
      this.setState({ localErrorMessage: error.message });
    });
  }

  handleLocaleSubmit({locale}) {
    let user = this.props.me;
    user.locale = locale.value;
    UsersService.saveMe(user).then(() => {
      this.setState({localSuccessMessage: i18next.t('messages.language-updated-successfully')});
      i18next.changeLanguage(locale.value);
    }).catch(error => {
      console.error(error);
      this.setState({localeErrorMessage: error.message});
    });
  }

  render() {
    if (this.props.me && this.state.locales && this.state.selectedLocale) {
      const user = this.props.me;
      return (
          <div>
            <div className="row">
              <PageTitle title={user.firstName + ' ' + user.lastName}
                         customButton={{
                           link: { pathname: "/user/profile/notifications" },
                           title: i18next.t('pages.users.notifications-info') }} />
            </div>
            <div className="content-wrapper__centered-content">
              <div className="container-child__50">
                <div>
                  {this.renderMessages()}
                </div>
                <div className="op-content-box">
                  <div className="list-group remove-margin-bottom">
                    <div className="list-group-item">
                      <div>
                        <span className="list-title">{i18next.t('pages.users.your-name')}</span>
                      </div>
                      <div>{user.firstName} {user.lastName}</div>
                    </div>
                    <div className="list-group-item">
                      <div>
                        <span className="list-title">{i18next.t('pages.users.login-username')}</span>
                      </div>
                      <div>{user.username}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-child__50">
                <UserUpdateLocaleForm 
                  locales={this.state.locales}
                  selectedLocale={this.state.selectedLocale}
                  onSubmit={this.handleLocaleSubmit.bind(this)}
                />
              </div>
              <div className="container-child__50">
                <UserUpdatePasswordForm
                    user={this.state.user}
                    onSubmit={this.handleSubmit.bind(this)}
                    errorMessage={this.props.errorMessage}
                />
              </div>
            </div>
          </div>
      );
    } else {
      return (<div className="loader">{i18next.t('loading')}</div>)
    }
  }

}

export default UserProfile;
