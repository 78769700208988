import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';

const TextInputPhone = ({ input, label, type, textarea, meta: { touched, error, warning, invalid } }) => {
  const phoneInput = <ReactPhoneInput input {...input} type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} country={'us'} onlyCountries ={['us']} disableDropdown = {true} countryCodeEditable={false} />
  return (
    <div className={"form-group " + (touched && error  ? 'has-error' : '')} >
      <label>{label}</label>
      <div>
          {phoneInput}
          {touched && ((error && <span className="input-error">{error}</span>) || (warning && <span className="input-warning">{warning}</span>))}
      </div>
    </div>
  );
};

export default TextInputPhone;
