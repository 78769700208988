import React from 'react';
import { Link } from "react-router-dom";


class MobileNavLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkTo: this.props.linkProperties.place,
      spanClassName: this.props.linkProperties.spanClassName,
      linkText: this.props.linkProperties.linkText,
      subNavItemClass: this.props.linkProperties.subNavItemClass,
      showMobileNav: this.props.showMobileNav,
      dataCategory: this.props.linkProperties.dataCategory
    };
  }

  render() {
    if (this.state.subNavItemClass && !this.state.spanClassName) {
      this.state.subNavItemClass += ' collapsed';
    }
    if (!this.state.subNavItemClass) {
      this.state.subNavItemClass = 'primary';
      if (this.state.spanClassName) {
        this.state.subNavItemClass += ' open';
      }
    }
    return (
        <li className={ this.state.subNavItemClass }>
        <span className={ this.state.spanClassName }>
          <Link to={ this.state.linkTo } onClick={ this.state.showMobileNav.bind(this)} data-category={this.state.dataCategory} >{ this.state.linkText }</Link>
        </span>
        </li>
    )
  }
}

export default MobileNavLink;