import React from 'react';
import Dropzone from 'react-dropzone';

import { PageTitle } from '../PageTitle';
import { SetupService } from '../../services';

class Setup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processing: false
        }
    }

    bulkImport(files) {
        this.setState({results: null, processing: true});

        SetupService.post(files[0]).then(response => {
            this.setState({importSucceeded: true, results: response.results, processing: false});
        }).catch(error => {
            const errorMessage = error.response?.data?.genericSystemErrorMessage ? error.response.data.genericSystemErrorMessage : "Unexpected error occurred";
            this.setState({importSucceeded: false, results: [errorMessage], processing: false});
        });
    }

    render() {
        return (
            <div>
                <div className="row">
                    <PageTitle 
                        title="Setup"
                        subTitle="Bulk Import"
                    />
                </div>
                <div className="op-content-box">
                    {this.state.processing ?
                    <div className="loader">Loading...</div>
                    :
                    <div>
                        <div>
                            <Dropzone className="dropzone-format" onDrop={files => this.bulkImport(files)}>
                                <div style={{textAlign: "center", top: "45%", position: "relative"}}>Drag ZIP here or...</div>
                                <button type="button" style={{margin: "0 auto", display: "block", position: "relative", top: "110px"}} className="btn btn-sm btn-default">Select ZIP</button>
                            </Dropzone>
                        </div> 
                        {this.state.results &&
                        <div style={{paddingTop: '10px'}}>
                            <p>Bulk import {this.state.importSucceeded && this.state.results.length === 0 ? `succeeded` : this.state.importSucceeded ? `succeeded with errors` : `failed. Nothing was added`}.</p>
                            {this.state.results.length > 0 &&
                            <div>
                                {this.state.results.length === 1 ?
                                <p>Error: {this.state.results[0]}</p>
                                :
                                <div>
                                    Errors:
                                    <ul>
                                        {this.state.results.map(result => {return <li>{result}</li>})}
                                    </ul>
                                </div>}
                            </div>}
                        </div>}
                    </div>} 
                </div>
            </div>
        );
    }
}

export default Setup;