import React, { Component } from 'react';
import classnames from 'classnames';
import i18next from 'i18next';

const defaultButton = props => (
  <button type="button" {...props} className="-btn">
    {props.children}
  </button>
);

export default class CBReactTablePagination extends Component {
  constructor(props) {
    super();

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);

    this.state = {
      page: props.page
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.page });
  }

  getSafePage(page) {
    if (isNaN(page)) {
      page = this.props.page;
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1);
  }

  changePage(page) {
    page = this.getSafePage(page);
    this.setState({ page });
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  }

  applyPage(e) {
    if (e) { e.preventDefault(); }
    const page = this.state.page;
    this.changePage(page === '' ? this.props.page : page);
  }

  render() {
    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      showResolveButton,
      customButtons,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      PreviousComponent = defaultButton,
      NextComponent = defaultButton,
      exportData
    } = this.props;

    const pageBool = pages <= 1 ? ' visibility' : '';

    const pagClasses = classnames({
      className: true,
      '-pagination': true,
      '-checklist': this.props.isTop,
      '-fullWidth': exportData || customButtons
      // [`${pageBool}`]: true
    });

    let rowMin = 0;
    let rowMax = 0;
    let totalElements = 0;
    if (this.props.totalElements) {
      const rowMinL = (this.props.page * this.props.pageSize) + 1;
      const rowMaxL = Math.min((this.props.page + 1) * this.props.pageSize,
        this.props.totalElements);

      rowMin = rowMinL >= 0 ? rowMinL : 0;
      rowMax = rowMaxL >= 0 ? rowMaxL : 0;
      totalElements = this.props.totalElements >= 0 ? this.props.totalElements : 0;
    }

    return (
      <div className={pagClasses} style={this.props.style}>
        {this.props.totalElements ?
          <span className="-rowInfo">
            <i>
              <span className="-rowMin">{rowMin}</span>
              <span> { i18next.t('to') } </span>
              <span className="-rowMax">{rowMax}</span>
              <span> { i18next.t('of') } </span>
              <span className="-rowCount">{totalElements}</span>
              <span> { i18next.t('rows') }</span>
            </i>
          </span>
            : ''}
        <div className={`-previous ${pageBool}`}>
          <PreviousComponent
            onClick={() => {
              if (!canPrevious) return;
              this.changePage(page - 1);
            }}
            disabled={!canPrevious}
          >
            {this.props.previousText}
          </PreviousComponent>
        </div>
        <div className={exportData || customButtons ? `-center -fullWidth  ${pageBool}` : `-center ${pageBool}`}>
          <span className={'-pageInfo'}>
            {this.props.pageText}{' '}
            {showPageJump
              ? <div className="-pageJump">
                <input
                  type={this.state.page === '' ? 'text' : 'number'}
                  onChange={(e) => {
                    const val = e.target.value;
                    const safePage = val - 1;
                    if (val === '') {
                      return this.setState({ page: val });
                    }
                    this.setState({ page: this.getSafePage(safePage) });
                    return false;
                  }}
                  value={this.state.page === '' ? '' : this.state.page + 1}
                  onBlur={this.applyPage}
                  onKeyPress={(e) => {
                    if (e.which === 13 || e.keyCode === 13) {
                      this.applyPage();
                    }
                  }}
                />
              </div>
              : <span className="-currentPage">
                {page + 1}
              </span>}&nbsp;{ i18next.t('of') }&nbsp;
            <span className="-totalPages">{pages || 1}</span>
          </span>
          {showPageSizeOptions &&
            <span className="select-wrap -pageSizeOptions" style={{ visibility: 'visible', marginRight: '5px' }}>
              <select
                onChange={e => onPageSizeChange(Number(e.target.value))}
                value={pageSize}
              >
                {pageSizeOptions.map((option, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={i} value={option}>
                    {option} { i18next.t('rows') }
                  </option>
                ))}
              </select>
            </span>
          }
          {this.props.isTop && exportData &&
            (this.props.exportLoading ? <a className="pull-right tooltip tooltip" role="button" tabIndex="0" style={{ visibility: 'visible', marginLeft: 'auto', marginRight: '10px' }} onClick={exportData}><img id="export-icon" className="spinner" src={`images/icon/themes/${window.globalBrand}/export_icon.svg`} alt={i18next.t('table-headers.export-data')} /> <span className="tooltiptext">{ i18next.t('table-headers.export-data') }</span></a> :
            <a className="pull-right tooltip" role="button" tabIndex="0" style={{ visibility: 'visible', marginLeft: 'auto', marginRight: '10px' }} onClick={exportData}><img id="export-icon" src={`images/icon/themes/${window.globalBrand}/export_icon.svg`} alt={i18next.t('table-headers.export-data')} /><span className="tooltiptext">{ i18next.t('table-headers.export-data') }</span></a>)
          }
          {showResolveButton &&
            <span className="resolve-selected" style={{ visibility: 'visible' }}>
              <button className="btn btn-sm btn-default" onClick={this.props.onResolveSelected}>
                { i18next.t('table-headers.resolve-selected') }
              </button>
            </span>
          }
          {this.props.isTop && customButtons &&
          <span className="header-buttons">
            {customButtons.map((button, i) => (
              <button key={`button${i}`} className="btn btn-sm btn-default" onClick={button.method}>
                {button.label}
              </button>
          ))}
          </span>
          }
        </div>
        <div className={`-next ${pageBool}`}>
          <NextComponent
            onClick={() => {
              if (!canNext) return;
              this.changePage(page + 1);
            }}
            disabled={!canNext}
          >
            {this.props.nextText}
          </NextComponent>
        </div>
      </div>
    );
  }
}
