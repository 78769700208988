import React from "react";
import { Link } from 'react-router-dom';
import { PageLevelAccess } from '../PageLevelAccess';
import i18next from "i18next";

import PropTypes from 'prop-types';

const PageTitle = ({ title, subTitle, backLink, backLinkWithParams, refreshFunction, addLink, addLinkWithParams, addButtonCallback, editLink, editLinkWithParams, customButton }) => (
  <div className="op-page-header">
    <div className="op-page-header__left">
      {backLink && (<Link to={{ pathname: backLink }}><div className="op-page-header__back">{ i18next.t('back') }</div></Link>)}
      {backLinkWithParams && (<Link to={backLinkWithParams.link}><div className="op-page-header__back">{ i18next.t('back') }</div></Link>
      )}
      <h2 className="op-page-header__title">{title} { subTitle && <em>{ subTitle }</em>}</h2>
    </div>
    <div className="op-page-header__right">
      {customButton && 
      <PageLevelAccess to={customButton.link.pathname}>
        <Link to={customButton.link}><div className="btn btn-sm btn-default">{ customButton.title }</div></Link>
      </PageLevelAccess>}
      {addButtonCallback &&<div className="btn btn-sm btn-default" onClick={() => addButtonCallback()}>{ i18next.t('add') }</div>}
      {addLink && 
      /*<PageLevelAccess to={addLink.link.pathname}>*/
        <Link to={addLink.link}><div className="btn btn-sm btn-default">{ i18next.t('add') }</div></Link>
      /*</PageLevelAccess>*/}
      {addLinkWithParams && 
      <PageLevelAccess to={addLinkWithParams.link.pathname}>
        <Link to={addLinkWithParams.link}><div className="btn btn-sm btn-default">{ i18next.t('add') }</div></Link>
      </PageLevelAccess>}
      {editLink && 
      /*<PageLevelAccess to={editLink.link.uiroute}>*/
        <Link to={{ pathname: editLink }}><div className="btn btn-sm btn-default">{ i18next.t('edit') }</div></Link>
      /*</PageLevelAccess>*/}
      {editLinkWithParams && 
        <PageLevelAccess to={editLinkWithParams.link.uiroute}>
          <Link to={editLinkWithParams.link}><div className="btn btn-sm btn-default">{ i18next.t('edit') }</div></Link>
      </PageLevelAccess>}
      {refreshFunction && <a onClick={() => { refreshFunction(); }}><div className="op-page-header__controls"><i className="op-icon op-icon-refresh" /></div></a>}
    </div>
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired, 
  subTitle: PropTypes.string, 
  backLink: PropTypes.string, 
  backLinkWithParams: PropTypes.object, 
  refreshFunction: PropTypes.func, 
  addLink: PropTypes.object, 
  addLinkWithParams: PropTypes.object, 
  editLink: PropTypes.object, 
  editLinkWithParams: PropTypes.object, 
  customButton: PropTypes.object
};

export default PageTitle;
