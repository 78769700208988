
const findGroupingByBusinessStructureUUID = (grouping, uuid) => {
    if  (grouping == null) {
        return null;
    }

    if (grouping.businessStructure.uuid === uuid) {
        return grouping;
    } else {
        if (grouping.children.length > 0) {
            for (const child of grouping.children) {
                const found = findGroupingByBusinessStructureUUID(child, uuid);
                if (found != null) {
                    return found;
                }
            }
        }

        return null;
    }
};

const findInTreeByUUID = (tree, uuid) => {
    if  (tree == null) {
        return null;
    }

    if (tree.uuid === uuid) {
        return tree;
    } else {
        if (tree.children.length > 0) {
            for (const child of tree.children) {
                const found = findInTreeByUUID(child, uuid);
                if (found != null) {
                    return found;
                }
            }
        }

        return null;
    }
};

const findInTreeByName = (tree, name) => {
    if  (tree == null) {
        return null;
    }

    if (tree.name === name) {
        return tree;
    } else {
        if (tree.children.length > 0) {
            for (const child of tree.children) {
                const found = findInTreeByName(child, name);
                if (found != null) {
                    return found;
                }
            }
        }

        return null;
    }
};

const findGroupingByUUID = (grouping, uuid) => findInTreeByUUID(grouping, uuid);

const findGroupByUUID = (group, uuid) => findInTreeByUUID(group, uuid);

const findGroupingByName = (grouping, name) => findInTreeByName(grouping, name);

const findGroupByName = (group, name) => findInTreeByName(group, name);

const modifyGroupingNode = (groupingNode, parentNode, updatedNode) => {
    var updatedStructure = groupingNode.uuid === updatedNode.uuid ? 
            { ...updatedNode, parent: parentNode, children: [] } : 
            { ...groupingNode, parent: parentNode, children: [] };

    groupingNode.children.forEach((child) => {
        updatedStructure.children.push(modifyGroupingNode(child, groupingNode, updatedNode));
    });

    return updatedStructure;
};

const deleteGroupingNode = (groupingNode, node) => {
    if (groupingNode.uuid === node.uuid) {
        return null;
    }

    var updatedStructure = { ...groupingNode, children: [] };

    groupingNode.children.forEach((child) => {
        const updatedNode = deleteGroupingNode(child, node);
        if (updatedNode != null) {
            updatedStructure.children.push(updatedNode);
        }
    });

    return updatedStructure;
};    

const pushGroupingNode = (groupingNode, parentNode, addNode) => {
    var updatedStructure = { ...groupingNode, parent: parentNode, children: [] };
    
    groupingNode.children.forEach((child) => {
        updatedStructure.children.push(pushGroupingNode(child, groupingNode, addNode));
    });

    if (addNode.parent && groupingNode.uuid === addNode.parent.uuid) {
        updatedStructure.children.push(addNode);
    }

    return updatedStructure;
};

const modifyBusinessStructureNode = (businessStructureNode, parentNode, updatedNode) => {
    var updatedStructure = businessStructureNode.uuid === updatedNode.uuid ? 
            { ...updatedNode, parent: parentNode, children: [] } : 
            { ...businessStructureNode, parent: parentNode, children: [] };

    businessStructureNode.children.forEach((child) => {
        updatedStructure.children.push(modifyBusinessStructureNode(child, businessStructureNode, updatedNode));
    });

    return updatedStructure;
};

const deleteBusinessStructureNode = (businessStructureNode, node) => {
    if (businessStructureNode.uuid === node.uuid) {
        return null;
    }

    var updatedStructure = { ...businessStructureNode, children: [] };

    businessStructureNode.children.forEach((child) => {
        const updatedNode = deleteBusinessStructureNode(child, node);
        if (updatedNode != null) {
            updatedStructure.children.push(updatedNode);
        }
    });

    return updatedStructure;
};

const pushBusinessStructureNode = (businessStructureNode, parentNode, addNode) => {
    var updatedStructure = { ...businessStructureNode, parent: parentNode, children: [] };
    
    businessStructureNode.children.forEach((child) => {
        updatedStructure.children.push(pushBusinessStructureNode(child, businessStructureNode, addNode));
    });

    if (addNode.parent && businessStructureNode.uuid === addNode.parent.uuid) {
        updatedStructure.children.push(addNode);
    }

    return updatedStructure;
};

const syncGroupsToGroupings = (groupingNode, rootGroup) => {
    var updatedStructure = { ...groupingNode, children: [] };
    
    updatedStructure.businessStructure = updatedStructure.businessStructure == null ? null : findGroupByUUID(rootGroup, updatedStructure.businessStructure.uuid);

    if (groupingNode.children != null) {
        groupingNode.children.forEach((child) => {
            updatedStructure.children.push(syncGroupsToGroupings(child, rootGroup));
        });    
    }

    return updatedStructure;
};

export { 
    findGroupingByBusinessStructureUUID, 
    findGroupingByUUID, 
    findGroupingByName,
    findGroupByUUID,
    findGroupByName,
    modifyGroupingNode,
    pushGroupingNode,
    deleteGroupingNode,
    modifyBusinessStructureNode,
    pushBusinessStructureNode,
    deleteBusinessStructureNode,
    syncGroupsToGroupings
};