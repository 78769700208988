import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageTitle } from '../PageTitle';
import { Message } from '../Message';
import DocumentForm from './document_form';
import DocumentsService from '../../services/documents_service';

class DocumentEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            updated: false,
            docId: props.match.params.docId,
            document: null,
            localErrorMessage: null
        };
    }

    componentDidMount() {
        DocumentsService.get(this.state.docId).then((response) => {
            this.setState({
                document: response.document
            });
        }).catch((error) => {
            console.error(error);
            this.setState({ localErrorMessage: 'Could not load document for editing.' });
        })
    }

    handleSubmit(document) {
        DocumentsService.save(document, this.state.docId).then(response => {
            this.setState({updated: response, successMessage: 'Document Updated'});
        }).catch(error => {
            console.error(error);
            this.setState({ localErrorMessage: error.message });
      });
    }

    handleDelete() {
        DocumentsService.delete(this.state.docId).then(response => {
            this.setState({deleted: response, successMessage: 'Document Deleted'});
        }).catch(error => {
            console.log(error);
            this.setState({ localErrorMessage: error.message});
        })
    }

    messageDismissCallback() {
        this.setState({ localErrorMessage: null });
        }

    renderMessages() {
        if (this.state.localErrorMessage) {
            return (
            <Message errorMessage={this.state.localErrorMessage} messageDismissCallback={this.messageDismissCallback.bind(this)} />
            );
        }

        return null;
    }

    render() {
        if (this.state.updated || this.state.deleted) {
            return (
                <Redirect to={{
                  pathname: '/admin-admin/docs',
                  state: {
                    from: this.props.location,
                    successMessage: this.state.successMessage
                  }
                }}/>
            )
        } else if (this.state.document) {
           return (
            <div>
                <div className="row">
                    <PageTitle title="Edit Document"/>
                </div>
                {this.renderMessages()}
                <div className="row"> 
                    <DocumentForm
                        document={this.state.document}
                        onDelete={this.handleDelete.bind(this)}
                        onSubmit={this.handleSubmit.bind(this)}
                    />
                </div>
            </div>
        ) 
        }
        else {
            return (
            <div className="loader">Loading...</div>
        );
      }
        
    }
};

export default DocumentEdit;