import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment-timezone';
import { Tree, TreeNode } from "react-organizational-chart";
import { v4 as uuidv4 } from 'uuid';

import { 
    findGroupingByUUID, 
    findGroupingByName, 
    modifyGroupingNode,
    pushGroupingNode,
    deleteGroupingNode,
    syncGroupsToGroupings
} from './enterprise_wizard_helpers';
import EnterpriseGroupingForm from './enterprise_grouping_form';

const GroupingModal = ({showModal, setShowModal, timezones, businessStructureNodes, parentGrouping, grouping, handleSubmitGrouping}) => {
    return (
        <Modal bsSize='large' show = { showModal } onHide = {() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Grouping</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EnterpriseGroupingForm
                    businessStructureNodes={businessStructureNodes}
                    grouping={grouping}
                    parentGrouping={parentGrouping}
                    timezones={timezones}
                    onSubmit={handleSubmitGrouping}
                    onCancel={() => setShowModal(false)}
                />
            </Modal.Body>
        </Modal>
    );
}

const constructTreeFromGroupings = (businessStructureNodes, parentGroupingNode, groupingNode, displayModal, onDelete) => {
    if (groupingNode == null) {
        return (
            <div style={{align: 'center'}}>
                <button type="button" className="btn btn-primary" onClick={() => {displayModal(businessStructureNodes, null, null)}}>Create Root Grouping</button>
            </div>
        );
    } else {
        const label = (
            <div style={{padding: '5px', borderRadius: '8px', display: 'inline-block', border: '2px solid black'}}>
                <h3>
                    {
                        groupingNode.children.length === 0 &&
                        <span>
                            <button className="btn btn-default btn-xs" style={{border: "none"}} type="button">
                                <img src={`images/icon/delete-trash-can.svg`} className="icon icon--small" onClick={() => {onDelete(groupingNode)}} />
                            </button>
                            &nbsp;
                        </span>
                    }
                    {groupingNode.businessStructure.name} : {groupingNode.name} 
                    &nbsp;
                    <button className="btn btn-default btn-xs" style={{border: "none"}} type="button">
                        <img src={`images/icon/edit.svg`} className="icon icon--small" onClick={() => displayModal(parentGroupingNode == null ? businessStructureNodes : parentGroupingNode.businessStructure.children, groupingNode, parentGroupingNode)} />
                    </button>
                </h3>
                
                { 
                    groupingNode.businessStructure.children.length > 0 &&
                    <button type="button" className="btn btn-primary" onClick={() => {displayModal(groupingNode.businessStructure.children, null, groupingNode)}}>Add Child</button>
                }
            </div>
        );

        if (groupingNode.parent == null) {
            return (
                <Tree label={label} lineWidth={'2px'}>
                    { groupingNode.children.map((child) => constructTreeFromGroupings(child.businessStructure.children, groupingNode, child, displayModal, onDelete)) }
                </Tree>
            );
        } else {
            return (
                <TreeNode label={label} key={groupingNode.uuid}>
                    { groupingNode.children.map((child) => constructTreeFromGroupings(child.businessStructure.children, groupingNode, child, displayModal, onDelete)) }
                </TreeNode>
            );
    
        }
    }
};

const EnterpriseWizardGroupingForm = ({enterpriseName, rootGroup, currentRootGrouping, onGroupingsUpdate, goPrevious, onSubmit, onError}) => {
    const [rootGrouping, setRootGrouping] = useState(currentRootGrouping);
    const [workingBusinessStructureNodes, setWorkingBusinessStructureNodes] = useState([rootGroup]);
    const [workingGroupingNode, setWorkingGroupingNode] = useState(currentRootGrouping);
    const [workingParentGroupingNode, setWorkingParentGroupingNode] = useState(null);
    const [showModal, setShowModal] = useState(false);
    
    const timezones = moment.tz.names()
            .filter(timezone => (timezone.includes('US/') || (timezone.includes('DT') && timezone.includes('ST'))))
            .map(timezone => ({value: timezone, label: timezone}));

    useEffect(() => {
        onGroupingsUpdate(rootGrouping);
    }, [rootGrouping]);

    useEffect(() => {
        if (rootGrouping != null) {
            setRootGrouping(syncGroupsToGroupings(rootGrouping, rootGroup));
        } else {
            setRootGrouping({
                uuid: uuidv4(),
                name: enterpriseName,
                parent: null,
                monitoringVisible: false,
                businessStructure: rootGroup,
                deviceGroup: rootGroup,
                timezone: null,
                children: []
            });
        }
    }, [rootGroup]);

    const handleSubmitGrouping = ({uuid, businessStructure, monitoringVisible, name, parent, timezone}) => {
        const editedGrouping = findGroupingByUUID(rootGrouping, uuid);
        const groupingWithName = findGroupingByName(rootGrouping, name);

        if (groupingWithName != null && groupingWithName.uuid != uuid) {
            onError('Error: Grouping name is not unique');
        } else {
            if (editedGrouping) {
                setRootGrouping(modifyGroupingNode(rootGrouping, rootGrouping.parent, {
                    ...editedGrouping, 
                    name: name, 
                    monitoringVisible: monitoringVisible,
                    deviceGroup: businessStructure.node,
                    businessStructure: businessStructure.node,
                    timezone: timezone ? timezone.value : null,
                }));
            } else {
                let newGrouping = {
                    uuid: uuid,
                    name: name,
                    parent: parent,
                    monitoringVisible: monitoringVisible,
                    deviceGroup: businessStructure.node,
                    businessStructure: businessStructure.node,
                    timezone: timezone ? timezone.value : null,
                    children: []
                };

                if (rootGrouping == null) {
                    setRootGrouping(newGrouping);
                } else {
                    setRootGrouping(pushGroupingNode(rootGrouping, rootGrouping.parent, newGrouping));
                }
            }
        }        

        setShowModal(false);
    }

    const displayModal = (bsns, groupingNode, parentGroupingNode) => {
        setWorkingBusinessStructureNodes(bsns);
        setWorkingGroupingNode(groupingNode);
        setWorkingParentGroupingNode(parentGroupingNode);
        setShowModal(true);
    };

    const onDelete = (groupingNode) => {
        setRootGrouping(deleteGroupingNode(rootGrouping, groupingNode));
    };

    return (
        <div>
            <h3>Groupings</h3>
            <div>
                { constructTreeFromGroupings([rootGroup], null, rootGrouping, displayModal, onDelete) }

                <hr/>

                <div className="pull-right">
                    <ul className="list-inline clearfix">
                        <li><button type="button" className="btn btn-primary" onClick={() => goPrevious()}>Previous</button></li>
                        <li><button type="submit" className="btn btn-primary" onClick={() => onSubmit()} disabled={rootGrouping == null}>Next</button></li>
                    </ul>
                </div>
            </div>

            <GroupingModal 
                enterpriseName={enterpriseName}
                showModal={showModal} 
                setShowModal={setShowModal} 
                businessStructureNodes={workingBusinessStructureNodes} 
                grouping={workingGroupingNode} 
                parentGrouping={workingParentGroupingNode}
                timezones={timezones} 
                handleSubmitGrouping={handleSubmitGrouping}
            />
        </div>
    );
}

export default EnterpriseWizardGroupingForm;