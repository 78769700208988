import React from 'react';
import { reduxForm, Field } from 'redux-form';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { TextInput } from '../TextInput';
import { SelectInput } from '../SelectInput';
import { CheckboxInput } from '../CheckboxInput';

const validate = values => {
    const errors = {}
    
    if (!values.name) {
      errors.name = "Name is required"
    } 
    
    if (!values.parent) {
      errors.parent = "Parent Grouping is required";
    }

    if (!values.timezone) {
        errors.timezone = "Timezone is required"
    }
    
    return errors;
  }

class EnterpriseGroupingForm extends React.Component {
    constructor(props) {
        super(props);

        console.log(this.props.businessStructureNodes);
        this.state = {
            businessStructureOptions: this.props.businessStructureNodes.map(node => ({value: node.uuid, label: node.name, node: node})),
            selectedBusinessStructure: this.props.businessStructureNodes[0]
        };

        this.props.initialize({
            uuid: this.props.grouping?.uuid ? this.props.grouping.uuid : uuidv4(),
            name: this.props.grouping?.name ? this.props.grouping.name : '',
            parent: this.props.parentGrouping,
            monitoringVisible: this.props.grouping ? this.props.grouping.monitoringVisible : true,
            timezone: this.props.grouping?.timezone ? {label: this.props.grouping.timezone, value: this.props.grouping.timezone} : null,
            businessStructure: {value: this.state.selectedBusinessStructure.uuid, label: this.state.selectedBusinessStructure.name, node: this.state.selectedBusinessStructure}
        });
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                {
                this.props.parentGrouping &&
                <div>
                    <label>Parent Gropuing</label>
                    <span>{this.props.parentGrouping.name}</span>
                </div>
                }

                <div className="add-margin-top">
                    <label>Business Structure</label>
                    {
                        this.props.businessStructureNodes.length === 1 ?
                        <span>{this.props.businessStructureNodes[0].name}</span> :
                        <Field name="businessStructure" component={SelectInput} options={this.state.businessStructureOptions} onChange={(option) => this.setState({selectedBusinessStructure: option.node})} />
                    }
                </div>
    
                <div className="add-margin-top">
                    <Field name="name" label="Name" type="text" component={TextInput} />
                </div>

                <div className="add-margin-top">
                    <Field name="monitoringVisible" type="checkbox" className="inline" component={CheckboxInput} label="Monitoring Visible" />
                </div>

                {
                this.state.selectedBusinessStructure.requiresTimezone &&
                <div className="add-margin-top">
                    <label>{i18next.t('timezone')}</label>
                    <Field name="timezone" component={SelectInput} options = {this.props.timezones} />
                </div>
                }

                <hr/>

                <ul className="list-inline clearfix">
                    <li><button type="submit" className="btn btn-primary btn-block">{i18next.t('form-labels.save')}</button></li>
                    <li><button type="button" className="btn btn-default" onClick={this.props.onCancel.bind(this)}>{i18next.t('form-labels.cancel')}</button></li>
                </ul>
            </form>
        );
    }
}

export default reduxForm({ form: 'EnterpriseGroupingForm', validate })(EnterpriseGroupingForm);