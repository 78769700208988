import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class ForgotPasswordService {
  static sendEmailInstructions(username) {
    return new Promise((resolve, reject) => {
      axios.post(`${ROOT}forgot-password`, {username}).then((data) => {
        resolve({ action: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static lookupSession(token) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}forgot-password/reset/${token}`).then((data) => {
        resolve({ action: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static resetPasswordAndLogin(token, newPassword, confirmNewPassword) {
    return new Promise((resolve, reject) => {
      axios.patch(`${ROOT}forgot-password/reset`, {uuid: token, newPassword: newPassword, confirmNewPassword: confirmNewPassword}).then((data) => {
        resolve({ action: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }
}

export default ForgotPasswordService;
