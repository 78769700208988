import React, { useState } from "react";
import { PageTitle } from "../PageTitle";
import OrderForm from "./order_form";
import { Redirect } from "react-router-dom";
import { Alert } from "react-bootstrap";

const OrderAdd = () => {
    const [redirect, setRedirect] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const onSuccess = () => {
        setRedirect(true);
    };

    const onError = () => {
        setErrorMessage("Error adding order.");
    };

    if (redirect) {
        return <Redirect to={{ pathname: "/admin-fulfillment/orders", state: { successMessage: "Order added." } }} />;
    }

    return (
        <div className="animated fadeIn">
            <div className="row">
                <PageTitle title="Add Order" />
            </div>
            <div className="op-content-box">
                <div className="row">
                    <div className="col-sm-12">
                        {errorMessage && <Alert className="alert-danger" closeLabel="X">{errorMessage}</Alert>}

                        <OrderForm onSuccess={onSuccess} onError={onError} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderAdd;