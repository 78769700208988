import React, { useCallback, useEffect, useState } from "react";
import { DevicesService } from "../../services";
import { Button } from "react-bootstrap";
import { PageTitle } from "../PageTitle";

const DeviceAdHocCommand = () => {
    const [startTimestamp, setStartTimestamp] = useState(null);
    const [endTimestamp, setEndTimestamp] = useState(null);
    const [deviceSerialNumber, setDeviceSerialNumber] = useState(null);

    const runCommand = useCallback(() => {
        const backlogFetchPayload = {
            deviceType: "draginolht65n", // draginolht65, draginolht65n, etc
            commandType: "backlog-fetch",
            startTime: parseInt(startTimestamp),
            endTime: parseInt(endTimestamp),
        };

        DevicesService.executeAdHocCommand(deviceSerialNumber, backlogFetchPayload).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.error(error);
        });

        // console.log(`${deviceSerialNumber} ${JSON.stringify(backlogFetchPayload)}`);
    }, [deviceSerialNumber, startTimestamp, endTimestamp]);

    return (
        <div>
            <div className="row">
                <PageTitle title="Run Command" />
            </div>
            <div className="inner op-content-box">
                <table style={{ borderSpacing: 10, borderCollapse: "separate" }}>
                    <tbody>
                        <tr>
                            <td style={{ paddingRight: 5 }}>Device</td>
                            <td><input type="text" style={{width: 400}} onChange={({ target }) => setDeviceSerialNumber(target.value)} /></td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: 5 }}>Start</td>
                            <td><input type="text" style={{width: 200}} onChange={({ target }) => setStartTimestamp(target.value)} /></td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: 5 }}>End</td>
                            <td><input type="text" style={{width: 200}} onChange={({ target }) => setEndTimestamp(target.value)} /></td>
                        </tr>
                    </tbody>
                </table>
                
                <br/>

                <Button onClick={runCommand} disabled={!(startTimestamp && endTimestamp && deviceSerialNumber)}>Run</Button>
            </div>
        </div>
    );
};

export default DeviceAdHocCommand;