import React from "react";
import { PageTitle } from "../PageTitle";

const InventoryView = () => (
    <div>
        <div className="row">
            <PageTitle title="Inventory View" />
        </div>
        <div className="inner op-content-box">TODO</div>
    </div>
);

export default InventoryView;