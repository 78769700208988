import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Tree, TreeNode } from "react-organizational-chart";

import {
    modifyBusinessStructureNode,
    pushBusinessStructureNode,
    deleteBusinessStructureNode,
    findGroupingByBusinessStructureUUID,
    findGroupByUUID,
    findGroupByName
} from './enterprise_wizard_helpers';
import EnterpriseGroupForm from './enterprise_group_form';

const BusinessNodeModal = ({showModal, setShowModal, group, onUpdate}) => {
    return (
        <Modal bsSize='large' show = { showModal } onHide = {() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EnterpriseGroupForm
                    group={group}
                    parentGroupName={group?.parent == null ? null : group.parent.name}
                    onSubmit={(updatedGroup) => { onUpdate({...updatedGroup, parent: group?.parent}); setShowModal(false); }}
                    onCancel={() => { setShowModal(false); }}
                />
            </Modal.Body>
        </Modal>    
    );
}

const constructTreeFromBusinessStructure = (businessStructureNode, onUpdate, displayModal, onDelete) => {
    if (businessStructureNode == null) {
        return (
            <div style={{align: 'center'}}>
                <button type="button" className="btn btn-primary" onClick={() => {displayModal(businessStructureNode, false)}}>Create Root</button>
            </div>
        );
    } else {
        const label = (
            <div style={{padding: '5px', borderRadius: '8px', display: 'inline-block', border: '2px solid black'}}>
                <h3>
                    {
                        businessStructureNode.children.length === 0 &&
                        <span>
                            <button className="btn btn-default btn-xs" style={{border: "none"}} type="button">
                                <img src={`images/icon/delete-trash-can.svg`} className="icon icon--small" onClick={() => {onDelete(businessStructureNode)}} />
                            </button>
                            &nbsp;
                        </span>
                    }
                    {businessStructureNode.name} 
                    &nbsp;
                    <button className="btn btn-default btn-xs" style={{border: "none"}} type="button">
                        <img src={`images/icon/edit.svg`} className="icon icon--small" onClick={() => {displayModal(businessStructureNode)}} />
                    </button>
                </h3>
                <button type="button" className="btn btn-primary" onClick={() => {displayModal({parent: businessStructureNode})}}>Add Child</button>
            </div>
        );

        if (businessStructureNode.parent == null) {
            return (
                <Tree label={label} lineWidth={'2px'}>
                    { businessStructureNode.children.map((child) => constructTreeFromBusinessStructure(child, onUpdate, displayModal, onDelete)) }
                </Tree>
            );
        } else {
            return (
                <TreeNode label={label} key={businessStructureNode.uuid}>
                    { businessStructureNode.children.map((child) => constructTreeFromBusinessStructure(child, onUpdate, displayModal, onDelete)) }
                </TreeNode>
            );
    
        }
    }
};

const EnterpriseWizardBusinessForm = ({ goPrevious, onSubmit, onGroupsUpdate, rootGroup, rootGrouping, onError }) => {
    const [businessStructure, setBusinessStructure] = useState(rootGroup);
    const [showModal, setShowModal] = useState(false);
    const [businessStructureNode, setBusinessStructureNode] = useState(null);

    useEffect(() => {
        onGroupsUpdate(businessStructure);
    }, [businessStructure]);

    const onUpdate = (group) => {
        const editedGroup = findGroupByUUID(businessStructure, group.uuid);
        const groupWithName = findGroupByName(businessStructure, group.name);

        if (groupWithName != null && groupWithName.uuid != group.uuid) {
            onError('Error: Business Structure name is not unique');
        } else {
            group.groupAssociableMetadataTypes = group.enableFloorPlan ? [{id: 1, token: 'floor_plan_location'}] : [];    

            if (businessStructure == null) {
                setBusinessStructure(group);
            } else {
                if (editedGroup != null) {
                    setBusinessStructure(modifyBusinessStructureNode(businessStructure, businessStructure.parent, group));
                } else {
                    setBusinessStructure(pushBusinessStructureNode(businessStructure, businessStructure.parent, group));
                }
            }     
        }   
    };

    const onDelete = (node) => {
        if (findGroupingByBusinessStructureUUID(rootGrouping, node.uuid) === null) {
            setBusinessStructure(deleteBusinessStructureNode(businessStructure, node));
        } else {
            onError('Unable to delete the given business structure node because it is in use by a grouping.')
        }
    };

    const displayModal = (businessStructureNode) => {
        setBusinessStructureNode(businessStructureNode);
        setShowModal(true);
    };

    return (
        <div>
            <h3>Business Structure</h3>
            <div>
                { constructTreeFromBusinessStructure(businessStructure, onUpdate, displayModal, onDelete) }
            </div>

            <hr />

            <div className="pull-right">
                <ul className="list-inline clearfix">
                    <li>
                        <button type="button" className="btn btn-primary" onClick={() => goPrevious()}>Previous</button>
                    </li>
                    <li>
                        <button type="submit" className="btn btn-primary" onClick={() => onSubmit()} disabled={businessStructure == null}>Next</button>
                    </li>
                </ul>
            </div>

            <BusinessNodeModal showModal={showModal} setShowModal={setShowModal} group={businessStructureNode} onUpdate={onUpdate} />
        </div>
    );
};

export default EnterpriseWizardBusinessForm;