import React from 'react';
import moment from 'moment';

import { PageTitle } from '../PageTitle';
import { EnterprisesService } from '../../services';
import { HologramHelper } from '../../helpers';
import EnterpriseDataUsage from './cell_usage_results';
import { DatePickerInput } from '../DatePickerInput';

class EnterpriseCellUsage extends React.Component {
    constructor(props) {
        super(props);

        let maxDate = moment().subtract(1, 'month').endOf('month');

        this.state = {
            enterpriseId: props.match.params.enterpriseId,
            enterprise: null,
            hologramTagId: null,
            loading: true,
            showPastUsage: true,
            startingDate: '',
            endingDate: '',
            maxDate: new Date(maxDate),
            dateErrors: {},
            query: null
        }
    }

    componentDidMount() {
        EnterprisesService.get(this.state.enterpriseId).then((response) => {
            let enterprise = response.enterprise;
                this.setState({
                    enterprise: enterprise,
                    hologramTagId: HologramHelper.getHologramTag(enterprise),
                    loading: false
                });   
        }).catch((error) => {
            console.log(error); // TODO handle error
            this.setState({loading: false});
        });
    }

    toggleShowPast() {
        this.setState({showPastUsage: !this.state.showPastUsage});
    }

    startDateChanged(date) {
        this.setState({startingDate: date, dateErrors: {}});
    }

    endDateChanged(date) {
        this.setState({endingDate: date, dateErrors: {}});
    }

    handleSubmit() {
        let {showPastUsage, startingDate, endingDate, hologramTagId} = this.state;
        if (showPastUsage) {
            let dateErrors = this.validate();

            if (!dateErrors.startingDate && !dateErrors.endingDate) {
                let start = moment(startingDate).utc().valueOf();
                let end = moment(endingDate).utc().valueOf();
                this.setState({query: {showPastUsage, start, end, hologramTagId}});
            } else {
                this.setState({dateErrors});
            }
        } else {
            this.setState({query: {showPastUsage, hologramTagId}});
        }
    }

    validate() {
        let dateErrors = {};

        if (!moment(this.state.startingDate).isValid()) {
            dateErrors.startingDate = 'Start Date Required';
        }

        if (!moment(this.state.endingDate).isValid()) {
            dateErrors.endingDate = 'End Date Required';
        }

        return dateErrors;
    }

    render() {
        if (this.state.loading) {
            return (<div className="loader">Loading...</div>);
        } else {
            return (    
                <div>
                    <PageTitle title={this.state.enterprise.name}
                        backLink="/admin-admin/enterprises" />
                        {this.state.hologramTagId ? 
                        <div>
                            <div className="op-content-box" style={{marginBottom: 20}}>
                                <div className = "row">
                                    <div className="col-sm-3">
                                        <label>
                                            <input className="inline" type="radio" checked={this.state.showPastUsage} onChange={() => this.toggleShowPast()} />Show Past Usage
                                        </label>
                                        <label>
                                            <input className="inline" type="radio" checked={!this.state.showPastUsage} onChange={() => this.toggleShowPast()} />Show Current Usage
                                        </label>
                                    </div>
                                    {this.state.showPastUsage &&
                                    <div>
                                        <div className="col-sm-3">
                                        <label>Starting</label>
                                            <DatePickerInput
                                                input={{value: this.state.startingDate}}
                                                meta={{}}
                                                onDayChange={this.startDateChanged.bind(this)}
                                                dayPickerProps={{
                                                    month: this.state.maxDate,
                                                    toMonth: this.state.maxDate
                                                }}
                                            />
                                            {this.state.dateErrors && this.state.dateErrors.startingDate &&
                                                <div className="input-error">{this.state.dateErrors.startingDate}</div>
                                            }
                                        </div>
                                        <div className="col-sm-3">
                                        <label>Ending</label>
                                            <DatePickerInput
                                                input={{value: this.state.endingDate}}
                                                meta={{}}
                                                onDayChange={this.endDateChanged.bind(this)}
                                                dayPickerProps={{
                                                    month: this.state.maxDate,
                                                    fromMonth: this.state.startingDate ? this.state.startingDate : null,
                                                    toMonth: this.state.maxDate
                                                }}
                                            />
                                            {this.state.dateErrors && this.state.dateErrors.endingDate &&
                                                <div className="input-error">{this.state.dateErrors.endingDate}</div>
                                            }
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 add-margin-top">
                                        <button className="btn btn-sm btn-default" onClick={this.handleSubmit.bind(this)}>Check</button>
                                    </div>
                                </div>
                            </div>
                            {this.state.query &&
                            <EnterpriseDataUsage query={this.state.query}/>
                            }
                        </div>
                        :
                        <div className="op-content-box">
                            No Hologram tag found.
                        </div>}
                </div>
            );
        }
    }

}

export default EnterpriseCellUsage;