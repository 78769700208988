import React from 'react';
import Masonry from 'react-masonry-component';

const NeedsAttentionCard = ({nad, locationClicked}) => {
  return (
      <div className="dash-card-region">
        <div className="card-region__header locations__header">
          <div className="location-name" style={{cursor: 'pointer'}} onClick={() => { locationClicked(nad.deviceGroupingId) }}>
            { nad.locationName }
          </div>
        </div>

        <div className="card-region__cards"> 
          <div className={`critical-alerts ${nad.criticalAlertCount > 0 ? "critical" : ""}`}>
            <strong>{nad.criticalAlertCount}</strong>  <br/> 
            <span>Critical Alerts</span>
          </div>
          <div className="online-offline">
            <OnlineOfflineArea offline={nad.gatewaysOffline} total={nad.gatewaysTotal} description={"Gateways Offline"} />
            <OnlineOfflineArea offline={nad.routerOffline} total={nad.routerTotal} description={"Routers Offline"} />
            <OnlineOfflineArea offline={nad.sensorsOffline} total={nad.sensorsTotal} description={"Sensors Offline"} />
          </div>
        </div>
      </div>
  );
}

export const OnlineOfflineArea = ({offline, total, description}) => {
  if (total > 0) {
    return (
      <div className={`online-offline-detail ${offline > 0 ? "critical" : ""}`}>
        <strong>{offline}/{total}</strong> <br/> 
        <span>{description}</span>
      </div>
    );
  } else {
    return null;
  }
}

const MASONRY_OPTIONS = {
    itemSelector: '.masonry-item',
    transitionDuration: 0,
    percentPosition: true,
    columnWidth: '.masonry-sizer',
    gutter: '.gutter-sizer',
    stagger: 30
};

export const NeedsAttentionComponent = ({isMobile, isAdmin, needingAttentionData, allOtherData, locationClicked}) => {
    return (
          <div className={isAdmin ? undefined : "op-content-box"}>

            <div className="d-region">
              <div className="l-region__heading">
                <h2 className = {isMobile ? "header-mobile" : undefined}>Locations Needing Attention</h2>
              </div>
              <Masonry options={MASONRY_OPTIONS}>
                <div className="masonry-sizer" />
                <div className="gutter-sizer" />
                { needingAttentionData.map(nad => <div className="masonry-item" key={nad.deviceGroupingId}><NeedsAttentionCard nad={nad} locationClicked={locationClicked} /></div>) }
              </Masonry>
            </div>          

            { allOtherData &&
              <div className="d-region">
                <div className="l-region__heading">
                  <h2 className = {isMobile ? "header-mobile" : undefined}>All Other Locations</h2>
                </div>
                <Masonry options={MASONRY_OPTIONS}>
                  <div className="masonry-sizer" />
                  <div className="gutter-sizer" />
                  { allOtherData.map(nad => <div className="masonry-item" key={nad.deviceGroupingId}><NeedsAttentionCard nad={nad} locationClicked={locationClicked} /></div>) }
                </Masonry>
              </div> 
            }         

          </div>
  )
}

