import React from 'react';
import ReactTable from "react-table";
import moment from 'moment-timezone';

import { HologramService, TimeFormattingService } from '../../services';

class CellUsageResults extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: this.props.query,
            usageData: null
        }
    }

    componentDidMount() {
        this.refreshUsageData();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.query) !== JSON.stringify(this.props.query)) {
            this.setState({query: this.props.query}, () => this.refreshUsageData());
        }
    }

    refreshUsageData() {
        this.setState({usageData: null});

        if (this.state.query.showPastUsage) {
            let {hologramTagId, start, end} = this.state.query;
            
            HologramService.getPastUsage(hologramTagId, start, end).then(response => {
                this.setState({usageData: response.data});
            }).catch(err => {
                console.error(err);
            });
        } else {
            HologramService.getCurrentUsage(this.state.query.hologramTagId).then(response => {
                this.setState({usageData: response.data});
            }).catch(err => {
                console.error(err);
            });
        }
    }

    renderTime(data) {
        let formattedTs = data !== '0000-00-00 00:00:00' && moment(data).isValid() ? TimeFormattingService.renderNormalTime(data, 'EST5EDT') : 'N/A';
        return (<span>{formattedTs}</span>);
    }

    render() {   
        if (this.state.usageData) {     
        return (  
            <div className="op-content-box">
                <h4>Data Usage</h4>  
                <div className="react-table-custom">
                    {this.state.query.showPastUsage ?
                        <ReactTable
                            className="-striped -highlight"
                            data={this.state.usageData}
                            columns={[
                                {
                                    Header: 'Link ID',
                                    accessor: 'linkid',
                                    minResizeWidth: 50
                                },
                                {
                                    Header: 'Record ID',
                                    accessor: 'record_id',
                                    minResizeWidth: 50
                                },
                                {
                                    Header: 'Month',  
                                    accessor: 'month',
                                    minResizeWidth: 50
                                },
                                {
                                    Header: 'Year',
                                    accessor: 'year',
                                    minResizeWidth: 50
                                },
                                {
                                    Header: 'Session Count',
                                    accessor: 'session_count',
                                    minResizeWidth: 50
                                },
                                {
                                    Header: 'Bytes',
                                    accessor: 'bytes',
                                    minResizeWidth: 50
                                }]}
                        />
                    :
                    <ReactTable
                        className="-striped -highlight"
                        data={this.state.usageData}
                        columns={[
                            {
                                Header: 'Link ID',
                                accessor: 'linkid',
                                minResizeWidth: 50
                            },
                            {
                                Header: 'Record ID',
                                accessor: 'record_id',
                                minResizeWidth: 50
                            },
                            {
                                Header: 'Current Period Ends',
                                accessor: 'cur_period_end',
                                minResizeWidth: 50,
                                Cell: cell => this.renderTime(cell.value)
                            },
                            {
                                Header: 'Periods Back',
                                accessor: 'periods_back',
                                minResizeWidth: 50
                            },
                            {
                                Header: 'Bytes',
                                accessor: 'bytes',
                                minResizeWidth: 50
                            }
                        ]}
                    />}
                </div>
            </div>);
        } else {
            return <div className="loader">Loading...</div>
        }
    }

}

export default CellUsageResults;