import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-component';
import { Tabs, Tab } from 'react-bootstrap';

import { PageTitle } from '../PageTitle';
import { DashboardService } from '../../services';
import { NeedsAttentionComponent, OnlineOfflineArea } from '../Specializations/NeedsAttention/needs_attention_component';
import { size } from 'lodash-es';

const MASONRY_OPTIONS = {
    itemSelector: '.masonry-item',
    transitionDuration: 0,
    percentPosition: true,
    columnWidth: '.masonry-sizer',
    gutter: '.gutter-sizer',
    stagger: 30
};

const SORT_OPTIONS = [
    { id: 'devices_offline', name: "Devices Offline" },
    { id: 'alerts', name: "Critical Alerts" },
    { id: 'percent_offline', name: "Percent Offline" },
    { id: 'name', name: "Name" }
];

const EnterpriseNeedsAttentionCard = ({enterpriseName, enterpriseId, nad, locationClicked}) => (
      <div className="dash-card-region">
        <div className="card-region__header locations__header">
          <div className="location-name" style={{cursor: 'pointer'}} onClick={() => { locationClicked(enterpriseId) }}>
            { enterpriseName }
          </div>
        </div>

       { 
        nad &&
        <div className="card-region__cards"> 
          <div className={`critical-alerts ${nad.criticalAlertCount > 0 ? "critical" : ""}`}>
            <strong>{nad.criticalAlertCount}</strong>  <br/> 
            <span>Critical Alerts</span>
          </div>
          <div className="online-offline">
            <OnlineOfflineArea offline={nad.gatewaysOffline} total={nad.gatewaysTotal} description={"Gateways Offline"} />
            <OnlineOfflineArea offline={nad.routerOffline} total={nad.routerTotal} description={"Routers Offline"} />
            <OnlineOfflineArea offline={nad.sensorsOffline} total={nad.sensorsTotal} description={"Sensors Offline"} />
          </div>
        </div>
      }
      </div>
)

const AdminDashboardGlobalTotals = ({primaryData, secondaryData, enterprises, sortOptionChanged, enterpriseNeedsAttention, disabledAlertLocations}) => ( primaryData && enterprises &&
    <div>
        <div className="d-region">
            <div className="l-region__heading">
                <h2>System Wide Information</h2>
            </div>        
            <Masonry options={MASONRY_OPTIONS}>
                <div className="masonry-sizer" />
                <div className="gutter-sizer" />
                <div className="masonry-item">
                    <div className="dash-card-region">
                        <div className="card-region__header locations__header">
                            <div className="location-name">
                                Totals
                            </div>
                        </div>                    
                        <div style={{paddingLeft: 10}}>
                            Total Enterprises: <strong>{primaryData.clientCount}</strong> <br/> <br/>

                            { 
                            !secondaryData && 
                            <div>
                            Total Gateways: <strong>...</strong> <br/>
                            Total Gateways Offline: <strong>...</strong> <br/>
                            Total Routers: <strong>...</strong> <br/>
                            Total Routers Offline: <strong>...</strong> <br/>
                            Total Devices: <strong>...</strong> <br/>
                            Total Devices Offline: <strong>...</strong> <br/>
                            Total Critical Alerts: <strong>...</strong> <br/> <br/>
                            </div>
                            }
                            {
                            secondaryData &&
                            <div>
                            Total Gateways: <strong>{secondaryData.gatewaysTotal}</strong> <br/>
                            Total Gateways Offline: <strong>{secondaryData.gatewaysOffline}</strong> <br/>
                            Total Routers: <strong>{secondaryData.routerTotal}</strong> <br/>
                            Total Routers Offline: <strong>{secondaryData.routerOffline}</strong> <br/>
                            Total Devices: <strong>{secondaryData.sensorsTotal}</strong> <br/>
                            Total Devices Offline: <strong>{secondaryData.sensorsOffline}</strong> <br/>
                            Total Critical Alerts: <strong>{secondaryData.criticalAlertCount}</strong> <br/> <br/>
                            </div>
                            }

                            Total Users: <strong>{primaryData.totalUserCount}</strong> <br/>
                            Total Locations: <strong>{primaryData.totalLocationCount}</strong> <br/> <br/>
                        </div>
                    </div>
                </div>

                <div className="masonry-item">
                    <div className="dash-card-region">
                        <div className="card-region__header locations__header">
                            <div className="location-name">
                                Top Device Types
                            </div>            
                        </div>
                        <ul>
                            { primaryData.deviceTypeCounts.map(entry => <li key={entry.type}>{entry.type}: <strong>{entry.count}</strong></li>) }
                        </ul>                    
                    </div>
                </div>                
            </Masonry>   
        </div>

        <div className="d-region">
            <div className="l-region__heading">
                <h2>Enterprise Information: Locations With Disabled Alerts</h2>
            </div>

            <Masonry options={MASONRY_OPTIONS}>
                <div className="masonry-sizer" />
                <div className="gutter-sizer" />
                { !disabledAlertLocations && <div>Loading....</div> }
                { disabledAlertLocations &&
                    enterprises.map(enterprise => {
                        return disabledAlertLocations[enterprise.id] && (
                            <div className="masonry-item" key={`da-${enterprise.id}`}>
                                <div className="dash-card-region">
                                    <div className="card-region__header locations__header">
                                        <div className="location-name">
                                            { enterprise.name }
                                        </div>                                            
                                    </div>
                                    <div style={{paddingLeft: 15, paddingBottom: 10}}>
                                    <b>Locations Disabled:</b> <br/>
                                    { disabledAlertLocations[enterprise.id].map(location => <span key={location.id}>{location.name}<br/></span>) }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Masonry>
        </div>

        <div className="d-region">
            <div className="l-region__heading">
                <h2>Enterprise Information: Needs Attention</h2>

                <div style={{padding: 10, textAlign: "right", color: "#000"}}>
                    Order By: &nbsp;
                    <select onChange={e => sortOptionChanged(SORT_OPTIONS[e.target.value])}>
                        {SORT_OPTIONS.map((option, i) => (<option key={i} value={i}>{ option.name }</option>))}
                    </select>
                </div>
            </div>

            <Masonry options={MASONRY_OPTIONS}>
                <div className="masonry-sizer" />
                <div className="gutter-sizer" />
                { !enterpriseNeedsAttention && <div>Loading....</div> }
                { enterpriseNeedsAttention && 
                    enterprises.map(enterprise => 
                        <div className="masonry-item" key={`nad-${enterprise.id}`}>
                            <EnterpriseNeedsAttentionCard 
                                enterpriseName={enterprise.name}
                                enterpriseId={enterprise.id}
                                nad={enterpriseNeedsAttention[enterprise.id]} 
                            />
                        </div>
                  )
                }
            </Masonry>
        </div>
    </div>
)

const AdminDashboardEnterpriseDetail = ({needingAttentionData}) => (
    <div>
        {
            needingAttentionData?.length == 0 &&
            <div style={{padding: 20, fontSize: 20}}>
                No data to show for this enterprise
            </div>
        }
        {
            needingAttentionData?.length > 0 &&
            <div style={{paddingLeft: 10}}>
                <NeedsAttentionComponent 
                    needingAttentionData={needingAttentionData} 
                    locationClicked={() => {}} 
                    isMobile={false}
                    isAdmin={true}
                />            
            </div>
        }
    </div>
)

const AdminDashboardEnterpriseDetailSelect = ({enterprises, enterpriseToNeedsAttentionDashboardData}) => {
    const [needingAttentionData, setNeedingAttentionData] = useState(null);
    const [selectedEnterpriseId, setSelectedEnterpriseId] = useState(null);

    useEffect(() => {
        if (enterprises && enterpriseToNeedsAttentionDashboardData) {
            if (selectedEnterpriseId) {
                if (enterpriseToNeedsAttentionDashboardData[selectedEnterpriseId]) {
                    const data = enterpriseToNeedsAttentionDashboardData[selectedEnterpriseId];

                    const needingAttentionData = data.filter(entry => 
                        (entry.sensorsTotal > 0 || entry.routerTotal > 0 || entry.gatewaysTotal > 0) && (entry.sensorsOffline != 0 || entry.routerOffline != 0 || entry.gatewaysOffline != 0 || entry.criticalAlertCount != 0)
                    ).sort((a, b) => a.locationName.localeCompare(b.locationName));

                    setNeedingAttentionData(needingAttentionData);
                } else {
                    setNeedingAttentionData([]);
                }
            } else {
                setSelectedEnterpriseId(enterprises[0].id);
            }
        }
    }, [enterprises, enterpriseToNeedsAttentionDashboardData, selectedEnterpriseId]);

    if (enterprises && enterpriseToNeedsAttentionDashboardData) {
        return (
            <div>
                <div className="row" style={{padding: 10, textAlign: "right"}}>
                    <select onChange={e => setSelectedEnterpriseId(enterprises[e.target.value].id)}>
                        {enterprises.map((enterprise, i) => (<option key={i} value={i}>{ `${enterprise.name}${enterprise.retired ? ' (retired)' : ''}` }</option>))}
                    </select>
                </div>

                <div className="row">
                {
                    needingAttentionData &&
                        <AdminDashboardEnterpriseDetail 
                            needingAttentionData={needingAttentionData}
                        />       
                }
                </div>
            </div>
        );
    } else {
        return <div className="loader">Loading...</div>;
    }
}

class AdminDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSortOption: SORT_OPTIONS[0],
            loading: true,
        };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setState({
            secondaryData: null,
            enterpriseNeedsAttention: null,
            enterpriseToNeedsAttentionDashboardData: null,
            loading: true,
        });

        DashboardService.getAdminDashboardData()
            .then((response) => {
                const { enterprises, adminDashboardEnterpriseSummaries } = response.data;
                
                let primaryData = {
                    totalUserCount: 0,
                    totalLocationCount: 0,
                    deviceTypeCounts: [],                    
                    clientCount: size(enterprises),
                };

                let deviceTypeCounts = {};

                for (var enterpriseId in adminDashboardEnterpriseSummaries) {
                    const entry = adminDashboardEnterpriseSummaries[enterpriseId];

                    for (var deviceType in entry.deviceTypeCounts) {
                        if (!deviceTypeCounts[deviceType]) {
                            deviceTypeCounts[deviceType] = 0;
                        }
                        deviceTypeCounts[deviceType] += entry.deviceTypeCounts[deviceType];
                    }

                    primaryData.totalUserCount += entry.activeUserCount;
                    primaryData.totalLocationCount += size(entry.adminDashboardLocations);
                }

                primaryData.deviceTypeCounts = Object.entries(deviceTypeCounts)
                    .map((kv) => ({ type: kv[0], count: kv[1] }))
                    .sort((a, b) => b.count - a.count)
                    .slice(0, 5);

                this.setState({
                    primaryData: primaryData,
                    liveEnterprises: enterprises,
                    loading: false,
                }, () => {
                    DashboardService.getAdminNeedsAttentionDashboardData()
                        .then((response) => {
                            var secondaryData = {
                                gatewaysOffline: 0,
                                gatewaysTotal: 0,
                                routerOffline: 0,
                                routerTotal: 0,
                                sensorsOffline: 0,
                                sensorsTotal: 0,
                                criticalAlertCount: 0,
                            };

                            var enterpriseNeedsAttentionValues = {};
                            Object.keys(response.data).forEach((enterpriseId) => {
                                var enterpriseNeedsAttention = {
                                    gatewaysOffline: 0,
                                    gatewaysTotal: 0,
                                    routerOffline: 0,
                                    routerTotal: 0,
                                    sensorsOffline: 0,
                                    sensorsTotal: 0,
                                    criticalAlertCount: 0,
                                };

                                response.data[enterpriseId].forEach((location) => {
                                    secondaryData.gatewaysOffline += location.gatewaysOffline;
                                    secondaryData.gatewaysTotal += location.gatewaysTotal;
                                    secondaryData.routerOffline += location.routerOffline;
                                    secondaryData.routerTotal += location.routerTotal;
                                    secondaryData.sensorsOffline += location.sensorsOffline;
                                    secondaryData.sensorsTotal += location.sensorsTotal;
                                    secondaryData.criticalAlertCount += location.criticalAlertCount;

                                    enterpriseNeedsAttention.gatewaysOffline += location.gatewaysOffline;
                                    enterpriseNeedsAttention.gatewaysTotal += location.gatewaysTotal;
                                    enterpriseNeedsAttention.routerOffline += location.routerOffline;
                                    enterpriseNeedsAttention.routerTotal += location.routerTotal;
                                    enterpriseNeedsAttention.sensorsOffline += location.sensorsOffline;
                                    enterpriseNeedsAttention.sensorsTotal += location.sensorsTotal;
                                    enterpriseNeedsAttention.criticalAlertCount += location.criticalAlertCount;
                                });

                                enterpriseNeedsAttentionValues[enterpriseId] = enterpriseNeedsAttention;
                            });

                            this.setState({
                                secondaryData: secondaryData,
                                enterpriseNeedsAttention: enterpriseNeedsAttentionValues,
                                enterpriseToNeedsAttentionDashboardData: response.data
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                        });

                    DashboardService.getAdminLocationsWithDisabledAlertsDashboardData()
                        .then((response) => {
                            this.setState({
                                disabledAlertLocations: response.data
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                        });  
                });
            })
            .catch((err) => {
                console.error(err);
            });              
    }

    sortOptionChanged(selectedOption) {
        this.setState({
            selectedSortOption: selectedOption,
        });
    }

    sortBySelectedSortOption(enterprises) {
        return enterprises.sort((enterpriseA, enterpriseB) => {
            switch (this.state.selectedSortOption.id) {
                case "name": {
                    const nameA = enterpriseA.name.toUpperCase();
                    const nameB = enterpriseB.name.toUpperCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                }
                case "alerts": {
                    if (this.state.enterpriseNeedsAttention) {
                        const enaA = this.state.enterpriseNeedsAttention[enterpriseA.id];
                        const enaB = this.state.enterpriseNeedsAttention[enterpriseB.id];
                        if (enaA && enaB) {
                            return enaB.criticalAlertCount - enaA.criticalAlertCount;
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }
                }
                case "devices_offline": {
                    if (this.state.enterpriseNeedsAttention) {
                        const enaA = this.state.enterpriseNeedsAttention[enterpriseA.id];
                        const enaB = this.state.enterpriseNeedsAttention[enterpriseB.id];
                        if (enaA && enaB) {
                            return enaB.sensorsOffline - enaA.sensorsOffline;
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }
                }
                case "percent_offline": {
                    if (this.state.enterpriseNeedsAttention) {
                        const enaA = this.state.enterpriseNeedsAttention[enterpriseA.id];
                        const enaB = this.state.enterpriseNeedsAttention[enterpriseB.id];
                        if (enaA && enaB) {
                            const pOfflineA = enaA.sensorsTotal && enaA.sensorsTotal > 0 ? enaA.sensorsOffline / enaA.sensorsTotal : 0.0;
                            const pOfflineB = enaB.sensorsTotal && enaB.sensorsTotal > 0 ? enaB.sensorsOffline / enaB.sensorsTotal : 0.0;
                            return pOfflineB - pOfflineA;
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }
                }
                default:
                    return 0;
            }
        });
    }

    render() {
        if (this.state.loading) {
            return <div className="loader">Loading...</div>;
        } else {
            return (
                <div className="animated fadeIn needs-attention-dash">
                    <div className="row">
                        <PageTitle title="Admin Dashboard" refreshFunction={() => this.refresh()} />
                    </div>
                    <div className="row">
                        <div className="op-content-box">
                            <Tabs>
                                <Tab eventKey="gtotals" title="Totals" id="1">
                                    <AdminDashboardGlobalTotals
                                        primaryData={this.state.primaryData}
                                        secondaryData={this.state.secondaryData}
                                        enterprises={this.state.liveEnterprises}
                                        enterpriseNeedsAttention={this.state.enterpriseNeedsAttention}
                                        disabledAlertLocations={this.state.disabledAlertLocations}
                                        liveEnterprises={this.sortBySelectedSortOption(this.state.liveEnterprises)}
                                        sortOptionChanged={this.sortOptionChanged.bind(this)}
                                    />
                                </Tab>

                                <Tab eventKey="edetail" title="Enterprise Detail" id="2">
                                    <AdminDashboardEnterpriseDetailSelect
                                        enterprises={this.state.liveEnterprises.sort((a, b) => a.name.localeCompare(b.name))}
                                        enterpriseToNeedsAttentionDashboardData={this.state.enterpriseToNeedsAttentionDashboardData}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default AdminDashboard;