import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import i18next from "i18next";

export const PrivateRoute = ({ component: ComposedComponent, ...rest }) => {

  @connect(state => {
    return { authenticated: state.auth.authenticated };
  })
  class AuthenticationContainer extends React.Component {

    // redirect if not authenticated; otherwise, return the component input into <PrivateRoute />
    handleRender(props) {
      if (!this.props.authenticated) {
        return (
          <Redirect to={{
            pathname: '/signin',
            state: {
              from: props.location,
              message: i18next.t('messages.auth-required')
            }
          }}/>
        );
      } else {
        return (
           <ComposedComponent {...props} />
        );
      }
    }

    render() {
      return (
        <Route { ...rest } render={ this.handleRender.bind(this) } />
      );
    }
  }

  return (
    <AuthenticationContainer />
  );
}
