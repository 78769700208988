import React from 'react';
import { Message } from '../Message';
import { PageTitle } from '../PageTitle';
import { ScrollModule } from '../ScrollModule';
import ScrollArea from 'react-scrollbar';
import DocumentList from './document_list';

class Documents extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          localErrorMessage: null
        };
    }

    messageDismissCallback() {
        this.setState({ localErrorMessage: null });
    }
    
    renderMessages() {
        if (this.state.localErrorMessage) {
            return (
                <Message errorMessage={this.state.localErrorMessage} messageDismissCallback={this.messageDismissCallback.bind(this)} />
            );
        }

        if (this.props.location.state) {
            return (
                <Message messages={this.props.location} />
            );
        }
        return null;
    }

    handleLocalError(localErrorMessage) {
        this.setState({ localErrorMessage: localErrorMessage });
    }

    handleHistoryUpdate(params) {
        this.props.history.push(params);
    }

    render() {
        return (
        <div className="animated fadeIn">
            <div className="row">
            <PageTitle title="Help Documents"                          
                       addLinkWithParams={{
                           link: {
                             pathname: "/admin-admin/docs/add"},
                             state: {
                               backLinkSearch: this.props.location.search}
                            }} />
            </div>
            {this.renderMessages()}
            <div className="op-content-box">
                <ScrollModule/>
                <ScrollArea
                    speed={0.8}
                    className="scroll-container"
                    contentClassName="scrollable-content"
                    horizontal={true} >
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-block">
                                    <DocumentList
                                    localErrorHandler={this.handleLocalError.bind(this)}
                                    currLocation={this.props.history.location}
                                    currSearch={this.props.location.search}
                                    pushHistory={this.handleHistoryUpdate.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollArea>
            </div> 
        </div>
        );
    }
};

export default Documents;