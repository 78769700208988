import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class ActionRoleTemplatesService {

    static getAll() {
        return new Promise((resolve, reject) => {
          axios.get(`${ROOT}action-role-templates`).then((response) => {
            resolve({ actionRoleTemplates: response.data });
          }).catch((err) => {
            reject(err);
          });
        });
      }
}

export default ActionRoleTemplatesService;