import React from 'react';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import i18next from 'i18next';

import {DatePickerInput} from '../DatePickerInput';

class SchedulerErrors extends React.Component {
    constructor(props) {
        super(props);

        moment.locale(i18next.language);
        let defaultStart = moment().startOf('day').subtract(1, 'week');
        let defaultEnd = moment().endOf('day');
        let filteredErrors = this.props.errors.filter(error => error.timestamp >= defaultStart.valueOf() && error.timestamp <= defaultEnd.valueOf());
        
        this.state = {
            filteredErrors: filteredErrors,
            startTS: defaultStart,
            endTS: defaultEnd
        }
    }

    handleStartChange(newStart) {
        if (newStart <= this.state.endTS) {
            this.setState({
                startTS: moment(newStart).startOf('day'),
                localErrorMessage: null}, () => this.filterErrorsByTimestamp());
        } else {
            this.setState({filteredErrors: [], localErrorMessage: i18next.t('messages.time-error')});
        }
    }


    handleEndChange(newEnd) {
        if (newEnd >= this.state.startTS) {
            this.setState({
                endTS: moment(newEnd).endOf('day'), 
                localErrorMessage: null}, () => this.filterErrorsByTimestamp());
        } else {
            this.setState({filteredErrors: [], localErrorMessage: i18next.t('messages.time-error')});
        }
    }

    filterErrorsByTimestamp() {
        const { startTS, endTS } =  this.state;
        
        this.setState({
            filteredErrors: this.props.errors.filter(error => error.timestamp >= startTS.valueOf() && error.timestamp <= endTS.valueOf())
        })
    }


    renderCallerClass(data) {
        let clazz = data.value.substring(data.value.lastIndexOf(".") + 1, data.value.length);
        return <span title={data.value}>{clazz}</span>;
    }

    render() {
        return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <ul className="list-inline clearfix">
                        <li>
                            <label>{i18next.t('starting')}</label>
                            <DatePickerInput
                                input={{value: this.state.startTS.format('L')}}
                                meta={{}}
                                dayPickerProps={{
                                    toDate: this.state.endTS ? this.state.endTS.toDate() : null,
                                    disabledDays: {after: this.state.endTS ? this.state.endTS.toDate() : null}
                                }}
                                onDayChange={this.handleStartChange.bind(this)}
                            />
                        </li>
                        <li> - </li>
                        <li>
                            <label>{i18next.t('ending')}</label>
                            <DatePickerInput
                                input={{value: this.state.endTS.format('L')}}
                                meta={{}}
                                dayPickerProps={{
                                    fromDate: this.state.startTS ? this.state.startTS.toDate() : null,
                                    disabledDays: {before: this.state.startTS ? this.state.startTS.toDate() : null}
                                }}
                                onDayChange={this.handleEndChange.bind(this)}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        <hr style={{margin: '0'}}/>
        <div className="react-table-custom">
            <ReactTable
                        className="-striped -highlight"
                        data={this.state.filteredErrors}
                        defaultSorted={[{
                            'id': 'timestamp'
                        }]}
                        showPaginationTop
                        columns={[
                            {
                                Header: i18next.t('graphing.date-time'),
                                accessor: "timestamp",
                                Cell: cell => this.props.renderNormalTime(cell),
                                minResizeWidth: 50,
                                width: 150
                            },
                            {
                                Header: i18next.t('table-headers.message'),
                                accessor: "formattedMessage",
                                style: {'whiteSpace': 'unset'},
                                minResizeWidth: 50, 
                                width: 400
                            },
                            {
                                Header: i18next.t('table-headers.caller-class'),
                                accessor: "callerClass",
                                Cell: cell => this.renderCallerClass(cell),
                                minResizeWidth: 50,
                                width: 200                         
                            }, 
                            {
                                Header: i18next.t('table-headers.caller-method'),
                                accessor: "callerMethod",
                                minResizeWidth: 50, 
                                width: 200
                            }, 
                            {
                                Header: i18next.t('table-headers.caller-line'),
                                accessor: "callerLine",
                                minResizeWidth: 50,
                                sortMethod: (a, b) => {
                                    return a - b;
                                }
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default SchedulerErrors;