import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class RoutesService {

  static listAll() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}uiroutes/listAll`).then((data) => {
        resolve({ routes: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }
}

export default RoutesService;
