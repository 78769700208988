import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class EnterprisesService {

  static getAllPageable(options=[], isSearch=false) {
    return new Promise((resolve, reject) => {
      let params = options.length == 0 ? '' : '?' + options.map(option => Object.keys(option).map((k, i) => `${k}=${option[k]}`)).join('&');
      let searchPart = isSearch ? '/search/findBySearch' : '';
      axios.get(`${ROOT}enterprises${searchPart}${params}`).then((data) => {
        resolve({ enterprises: data.data.content, page: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static get(enterpriseId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}enterprises/${enterpriseId}`).then((data) => {
        resolve({ enterprise: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static save(enterprise) {
    return new Promise((resolve, reject) => {
      axios.patch(`${ROOT}enterprises/${enterprise.id}`, enterprise).then((data) => {
        resolve({ enterprise: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static uploadLogoImage(enterpriseId, file) {
    return new Promise((resolve, reject) => {
      var uploadData = new FormData();
      uploadData.append('file', file);
      axios.post(`${ROOT}enterprises/${enterpriseId}/logos`, uploadData).then((data) => {
        resolve({ logoData: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getAll() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}enterprises/listAll`).then((data) => {
        resolve({ enterprises: data.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getPreferences(enterpriseId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}enterprises/${enterpriseId}/preferences`).then((data) => {
        resolve({ preferences: data.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static savePreferences(enterpriseId, preferences) {
    return new Promise((resolve, reject) => {
      axios.patch(`${ROOT}enterprises/${enterpriseId}/editPreferences`, preferences).then((data) => {
        resolve({ enterprise: data.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getDeviceGroups(enterpriseId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}enterprises/${enterpriseId}/groups`).then((data) => {
        resolve({ groups: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static add(enterpriseRequest) {
    return new Promise((resolve, reject) => {
      axios.post(`${ROOT}enterprises`, enterpriseRequest).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }

}

export default EnterprisesService;
