import React, { useEffect, useState } from 'react';
import ReactTable from "react-table";
import { Modal } from 'react-bootstrap';

import { PageTitle } from "../PageTitle";
import { EnterprisesService, TimeFormattingService, AuditService } from '../../services';

const AuditLogRecordDetailModal = ({show, toggle, recordDetail}) => {
    return (
        <Modal show={show} onHide={toggle}>
            <Modal.Header closeButton>
                <Modal.Title>Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    {recordDetail ? Object.keys(recordDetail).map((key) => <li key={key}><strong>{key}</strong>: {recordDetail[key]}</li>) : null}
                </ul>
            </Modal.Body>
        </Modal>
    );
};

const AuditLogsView = () => {
    const [enterprises, setEnterprises] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searching, setSearching] = useState(false);
    const [searchConfig, setSearchConfig] = useState({});
    const [tableConfig, setTableConfig] = useState({tableFilter: ''});
    const [showRecordDetailModal, setShowRecordDetailModal] = useState(false);
    const [currentRecordDetail, setCurrentRecordDetail] = useState(null);
    const [updateTimer, setUpdateTimer] = useState(null);

    useEffect(() => {
        if (searchConfig.enterpriseId && searchConfig.enterpriseId !== -1) {
            var queryConfig = [];
            Object.keys(searchConfig).forEach((key) => {
                if(searchConfig[key] != null) {
                    var entry = {};
                    entry[key] = searchConfig[key];
                    queryConfig.push(entry);
                }
            });
            AuditService.searchAuditEntries(queryConfig).then(({changeEvents, page}) => {
                setSearchResults(changeEvents);
                setTableConfig({...tableConfig, pageCount: page.totalPages, totalElements: page.totalElements})
                setSearching(false);
            }).catch((error) => {
                console.error(error);
                setSearching(false);
            });    
        } else {
            setSearching(false);
        }
    }, [searchConfig]);

    useEffect(() => {
        EnterprisesService.getAll().then(({enterprises}) => {
            setEnterprises(enterprises);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    const enterpriseSelected = (enterpriseId) => {
        if (enterpriseId == -1) {
            setSearchResults([]);
        } else {
            setSearching(true);
            setSearchConfig({...searchConfig, enterpriseId: enterpriseId}); 
        }
    };

    const fetchData = (tableConfig) => {
        var sortOptions = [];

        if (tableConfig.sorted && tableConfig.sorted.length > 0) {
            sortOptions = [];
            tableConfig.sorted.forEach(order => sortOptions.push(`${order.id},${order.desc ? "desc":"asc"}`))
        } else {
            sortOptions.push('changedAt');
        }

        setSearching(true);
        setSearchConfig({page: tableConfig.page, size: tableConfig.pageSize, enterpriseId: searchConfig.enterpriseId, sort: sortOptions});
    };

    const renderNormalTime = (data) => {
        const date = TimeFormattingService.renderDate(data, null);
        const time = TimeFormattingService.renderTime(data, null);
        return (<span>{date} {time}</span>);
    };

    const displayRecordDetail = (recordDetail) => {
        setCurrentRecordDetail(JSON.parse(recordDetail.recordData));
        setShowRecordDetailModal(true);
    };

    const updateTableFilter = (value) => {
        setTableConfig({...tableConfig, tableFilter: value});

        if (updateTimer) {
            clearInterval(updateTimer);
            setUpdateTimer(null);
        }

        const interval = setInterval(() => {
            clearInterval(interval); 
            setUpdateTimer(null);
            setSearching(true);
            if (value.trim() === '') {
                setSearchConfig({...searchConfig, tableNameFilter: null});
            } else {
                setSearchConfig({...searchConfig, tableNameFilter: value});
            }
        }, 500);

        setUpdateTimer(interval);
    }

    return (
        <div className="admin-devices">
            <div className="row">
                <PageTitle title="Audit Logs" />
            </div>
            <div className="inner op-content-box">
                <div className="row">
                    <div className="col-sm-12">
                        {
                            enterprises != null ?
                            <div className="card-block">
                                Select an Enterprise: 
                                &nbsp;
                                <select onChange={(event) => enterpriseSelected(event.target.value)}>
                                    <option key={-1} value={-1}>Select One</option>
                                    {
                                        enterprises.map((enterprise) => (
                                            <option key={enterprise.id} value={enterprise.id}>
                                                {enterprise.name}
                                            </option>
                                        ))
                                    }
                                </select> <br/>

                                <div className="react-table-custom">            
                                    <ReactTable
                                        className="-striped -highlight"
                                        data={searchResults}
                                        loading={searching}
                                        showPaginationTop
                                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                        pages={tableConfig.pageCount} // Display the total number of pages
                                        totalElements={tableConfig.totalElements}
                                        onFetchData={fetchData} // Request new data when things change                                        
                                        columns={[{
                                            Header: "Changed On",
                                            accessor: "changedAt",
                                            Cell: cell => renderNormalTime(cell.original.changedAt),
                                            minResizeWidth: 100,
                                            width: 200
                                        }, {
                                            Header: "Changed Type",
                                            accessor: "changeType",
                                            minResizeWidth: 100,
                                            width: 150
                                        }, {
                                            Header: "Table",
                                            accessor: "tableName",
                                            minResizeWidth: 100,
                                            width: 300,
                                            filterable: true,
                                            Filter: <div className="filter">
                                                        <input
                                                            style={{width: "100%", margin:"0 auto"}}
                                                            value={tableConfig.tableFilter}
                                                            onChange={(event) => updateTableFilter(event.target.value)}
                                                            type="text"
                                                            name="search"
                                                            placeholder="Filter Table"
                                                            className="table-search"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                        }, {
                                            Header: "Changed By",
                                            accessor: "userInfo",
                                            minResizeWidth: 100,
                                            width: 300
                                        }, {
                                            Cell: cell => <button className="btn btn-sm btn-default" onClick={() => displayRecordDetail(cell.original)}>View Detail</button>,
                                            sortable: false,
                                            minResizeWidth: 150
                                        }]}
                                    />
                                </div>
                            </div>
                            :
                            <div className="loader">Loading...</div> 
                        }
                    </div>
                </div>
            </div>
            <AuditLogRecordDetailModal show={showRecordDetailModal} toggle={() => setShowRecordDetailModal(false)} recordDetail={currentRecordDetail} />
        </div>
    );
};


export default AuditLogsView;