import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class DashboardService {

  static getAlertsByTime(timeConstraint, filterGroupId, filterGroupingId, categoryFilterIds) {
    return new Promise((resolve, reject) => {
      const query = `timeConstraint=${timeConstraint}&categoryFilterIds=${categoryFilterIds.join(',')}&filterGroupId=${filterGroupId == null ? -1 : filterGroupId}&filterGroupingId=${filterGroupingId == null ? -1 : filterGroupingId}`;
      axios.get(`${ROOT}dashboard/alerts?${query}`).then((data) => {
        resolve({ alerts: data.data, page: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getAdminDashboardData() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}admin/dashboard`).then((response) => {
        resolve({ data: response.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getAdminNeedsAttentionDashboardData() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}admin/dashboard/needs-attention`).then((response) => {
        resolve({ data: response.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getAdminLocationsWithDisabledAlertsDashboardData() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}admin/dashboard/locations-with-disabled-alerts`).then((response) => {
        resolve({ data: response.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getKRMCDashboardData() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}dashboard/krmcd`).then((response) => {
        resolve({ data: response.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getNeedsAttentionDashboardData() {
        return new Promise((resolve, reject) => {
      axios.get(`${ROOT}dashboard/needs-attention-dashboard-data`).then((response) => {
        resolve({ data: response.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

}
export default DashboardService;
