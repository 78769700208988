import React from 'react';
import Select from "react-select";
import { VersionService } from "../../services";
import { PageTitle } from '../PageTitle';
import config from "config"

class SystemVersions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    VersionService.getVersions().then(response => {
      let stringArr = response.versions.split(" ")
      let restVersion = stringArr[0] != "null" ? stringArr[0] : "Unknown"
      let serverVersion = stringArr[1] != "null" ? stringArr[1] : "Unknown"
      this.setState({restVersion: restVersion, serverVersion: serverVersion})
    })
  }

  render() {
    if (this.state.restVersion && this.state.serverVersion) {
      return (
        <div>
          <div className="row">
            <PageTitle title="Current Versions"/>
          </div>
            <div className="op-content-box">
            <span> Web Version: {config.VERSION}</span>
            <hr />
            <span> RESTAPI Version: {this.state.restVersion}</span>
            <hr />
            <span> ServerCommon Version: {this.state.serverVersion}</span>
          </div>
        </div>
      );
    }
    return (
      <div className="loader">Loading...</div>
    )
  }
}

export default SystemVersions;
