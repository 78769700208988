import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { TextInput } from '../TextInput';
import i18next from 'i18next';

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = i18next.t('messages.please-enter-new-password')
  }
  if (values.password && values.password.length < 8) {
    errors.password = i18next.t('messages.password-eight-char-minimum');
  }
  if (!values.repeat_password) {
    errors.repeat_password = i18next.t('messages.please-enter-repeat-password')
  }
  if (!(values.repeat_password === values.password)) {
    errors.repeat_password = i18next.t('messages.passwords-do-not-match')
  }
  return errors
};

class UserUpdatePasswordForm extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
        <div>
          <form onSubmit={handleSubmit}>
            <div className="op-content-box op-card form-group">
              <div>
                <p className="panel-heading">{i18next.t('pages.users.update-password')}:</p>
              </div>
              <div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <Field
                        name="password"
                        type="password"
                        component={TextInput}
                        label={i18next.t('form-labels.new-password')}
                    />
                  </li>
                  <li className="list-group-item">
                    <Field
                        name="repeat_password"
                        type="password"
                        component={TextInput}
                        label={i18next.t('form-labels.repeat-new-password')}
                    /></li>
                </ul>
              </div>
              <div className="pull-right">
                <button type="submit" className="btn btn-primary">{i18next.t('form-labels.save')}</button>
              </div>
            </div>
          </form>
        </div>
    );
  }

}

export default reduxForm({ form: 'UserUpdatePasswordForm', validate })(UserUpdatePasswordForm);
