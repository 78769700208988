import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

const SelectAsyncInput = (props) => (
  <div>
    <AsyncSelect
      {...props}
      value={props.input.value}
      onChange={(value) => props.input.onChange(value)}
      onBlur={() => props.input.onBlur(props.input.value)}
      options={props.options}
      className="react-select-container"
      classNamePrefix={(props.meta.touched && props.meta.error  ? 'react-select-has-error' : 'react-select')}
    />
    {props.meta.touched && ((props.meta.error && <span className="input-error">{props.meta.error}</span>) )}
  </div>
);

export default SelectAsyncInput;
