import React from 'react';
import { reduxForm, Field } from 'redux-form';
import i18next from 'i18next';

import { TextInput } from '../TextInput';
import { PasswordInput } from '../PasswordInput';

const validate = values => {
    const errors = {};

    if (!values.firstName) {
        errors.firstName = 'First name is required';
    }

    if (!values.lastName) {
        errors.lastName = 'Last name is required';
    }

    if (!values.username) {
        errors.username = 'User name is required';
    }

    if (!values.password) {
        errors.password = 'Password is required';
    }

    return errors;
}

class EnterpriseWizardAccessForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
            return (
                <div>
                    <h3>Admin User Configuration</h3>
                    <form onSubmit={this.props.handleSubmit}>
                        <div>
                            <h4>Enterprise Admin User</h4>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <Field
                                    name="firstName"
                                    type="text"
                                    component={TextInput}
                                    label={i18next.t('form-labels.first-name')}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <Field
                                    name="lastName"
                                    type="text"
                                    component={TextInput}
                                    label={i18next.t('form-labels.last-name')}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <Field
                                    name="username"
                                    type="text"
                                    component={TextInput}
                                    label={i18next.t('form-labels.username')}
                                    />
                                </div>
                                <div className="form-group col-sm-12 col-lg-6">
                                    <Field
                                        name="password"
                                        type="password"
                                        component={PasswordInput}
                                        label={i18next.t('form-labels.password')}
                                    />
                                </div>
                            </div> 
                        </div>
                        <hr/>
                        <div className="pull-right">
                            <ul className="list-inline clearfix">
                                <li><button type="button" className="btn btn-primary" onClick={() => this.props.goPrevious()}>Previous</button></li>
                                <li><button type="submit" className="btn btn-primary">Submit</button></li>
                            </ul>
                        </div>
                    </form>
                </div>
            )
    }
}

export default reduxForm({ form: 'EnterpriseAddForm', destroyOnUnmount: false, validate })(EnterpriseWizardAccessForm);