import React from 'react';
import EnterpriseEditForm from './enterprise_edit_form';
import { Redirect } from 'react-router-dom';
import update from 'immutability-helper';
import Dropzone from 'react-dropzone';
import { Modal } from 'react-bootstrap';

import { PageTitle } from '../PageTitle';
import { Message } from '../Message';
import { ContentService, EnterprisesService } from '../../services';

class EnterpriseEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      added: null,
      enterprise: null,
      enterpriseId: props.match.params.enterpriseId,
      errorMessage: null,
      logoImageData:null,
      showEnterpriseLogoModal:false,
      localErrorMessage: null,
      localSuccessMessage:null
    };

    this.toggleShowEnterpriseLogoModal = this.toggleShowEnterpriseLogoModal.bind(this);
  }

  componentDidMount() {
    EnterprisesService.get(this.state.enterpriseId).then((response) => {
      this.setState({ enterprise: response.enterprise });

      ContentService.getEnterpriseLogo(this.state.enterprise.token).then(response => {
        this.setState({enterpriseLogo: response});
      }).catch(err => {
        console.log(err);
        this.setState({enterpriseLogo: null});
      });
      
    }).catch((error) => {
      this.setState({ errorMessage: 'Could not load enterprise for editing.' });
      console.error(error);
    });
  }

  handleSubmit({ name }) {
    const localEnterprise = update(this.state.enterprise, { name: { $set: name } });

    EnterprisesService.save(localEnterprise).then(enterprise => {
      this.setState({ added: enterprise });
    }).catch(error => {
      console.error(error);
      this.setState({ localErrorMessage: error.message });
    });
  }

  messageDismissCallback() {
    this.setState({ 
      localErrorMessage: null, 
      localSuccessMessage: null
    });
  }

  toggleShowEnterpriseLogoModal() {
    this.setState({
      showEnterpriseLogoModal: !this.state.showEnterpriseLogoModal
    });
  }

  onEnterpriseLogoUploadDrop(files, amtValue) {
    // TODO need loading dialog here while uploading
    const existingEnterprise = this.state.enterprise;
    this.setState({ enterprise: null });
    EnterprisesService.uploadLogoImage(existingEnterprise.id, files[0]).then(response => {
      ContentService.getEnterpriseLogo(existingEnterprise.token).then(response => {
        this.setState({enterpriseLogo: response, enterprise: existingEnterprise, localErrorMessage: null, localSuccessMessage: 'Logo updated successfully'});
      }).catch(err => {
        console.error(err);
        this.setState({enterpriseLogo: null, enterprise: existingEnterprise, localErrorMessage: "Error in Logo Upload", localSuccessMessage: null});
      });

      this.toggleShowEnterpriseLogoModal();

    }).catch(error => {
      this.setState({ enterprise: existingEnterprise, localErrorMessage: "Error in Logo Upload.", localSuccessMessage: null });
      console.error(error);
    })

  }

  getDisplayForLogos(amtValue) {

    let existingEnterpriseLogo = null;

    if (this.state.enterpriseLogo) {
      const enterpriseLogo = this.state.enterpriseLogo;
      existingEnterpriseLogo = (
        <div className="op-content-box">
          <div className="store-map-image-wrapper" ref='mapImage' style={{position: 'relative', background: `url(${enterpriseLogo}) no-repeat center`, width:400, height:300}} />
        </div>);
    }
    else {
      existingEnterpriseLogo = (<div>No current Enterprise Logo Image.</div>);
    }

    return (
        <div>
          <hr />
          <ul className="list-inline clearfix floor-plan-heading">
            <li className="pull-left"><h4 className="remove-margin-bottom">Enterprise logo</h4></li>
            <li className="pull-right"><a className="btn btn-default" onClick={this.toggleShowEnterpriseLogoModal}>Add / Change Enterprise Logo</a></li>
          </ul>

          {existingEnterpriseLogo}

          <Modal show={this.state.showEnterpriseLogoModal} onHide={this.toggleShowEnterpriseLogoModal}>
            <Modal.Header closeButton>
              <Modal.Title>Enterprise logo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="floor-plan-dropzone">
                <Dropzone className="dropzone-format" onDrop={(files) => this.onEnterpriseLogoUploadDrop(files, amtValue)}>
                  <div style={{textAlign: "center", top: "45%", position: "relative"}}> Drag and drop your Enterprise logo image here or </div>
                  <button  style = {{margin: "0 auto", display: "block", position: "relative", top: "110px"}} className="btn btn-sm btn-default"> Upload an Image </button>
                </Dropzone>
              </div>
            </Modal.Body>
          </Modal>
        </div>
    );

  }

  render() {
    if (this.state.errorMessage) {
      return (
          <Redirect to={{
            pathname: '/admin-admin/enterprises',
            state: {
              errorMessage: this.state.errorMessage
            }
          }}/>
      );
    }

    if (this.state.added) {
      return (
          <Redirect to={{
            pathname: '/admin-admin/enterprises',
            state: {
              from: this.props.location,
              successMessage: 'Enterprise edited.'
            }
          }}/>
      );
    } else {
      if (this.state.enterprise) {
        const enterpriseLogoDisplayValues = this.getDisplayForLogos(this.state.enterprise);

        return (
            <div>
              <div className="row">
                <PageTitle title={`Edit ${this.state.enterprise.name}`}
                           backLink="/admin-admin/enterprises" />
              </div>

              <Message
                  messages={{ state: { successMessage: this.state.localSuccessMessage } }}
                  errorMessage={this.state.localErrorMessage}
                  messageDismissCallback={this.messageDismissCallback.bind(this)}
              />
              <EnterpriseEditForm
                  enterprise={this.state.enterprise}
                  onSubmit={this.handleSubmit.bind(this)}
                  errorMessage={this.props.errorMessage}
              />

              {enterpriseLogoDisplayValues}

            </div>
        );
      } else {
        return (
            <div className="loader">Loading...</div>
        );
      }
    }

  }
}

export default EnterpriseEdit;
