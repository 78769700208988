import React from "react";
import PropTypes from "prop-types";

export default class Loading extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  static propTypes = {
  };

  componentWillReceiveProps() {

  }

  render() {

    var loading = this.props.loading;
    return (
        <div>
        {
            loading && (
                <div className="rt-table_processing panel panel-default">
                    <div className='table__load'>
                        <div className='rt-loader'></div> 
                    </div>
                </div>
            )
        }
        </div>
    );
  }
}
