import React from "react";
import { PageTitle } from "../PageTitle";

const InventoryEdit = () => (
    <div>
        <div className="row">
            <PageTitle title="Inventory Edit" />
        </div>
        <div className="inner op-content-box">TODO</div>
    </div>
);

export default InventoryEdit;