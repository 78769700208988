import React, { useState } from 'react';

import { PageTitle } from "../PageTitle";
import { Message } from '../Message';
import { CommissioningService, TimeFormattingService } from "../../services";
import config from 'config';
import axios from 'axios';

class CommissionedDeviceView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commissionedDeviceReferenceId: props.match.params.commissionedDeviceReferenceId,
      commissionedDevice: null,
      loading: false,
      localErrorMessage: null,
      localSuccessMessage: null,
      packageDownloading: false
    };
  }

  handleLocalSuccess(localSuccessMessage) {
    this.setState({localSuccessMessage: localSuccessMessage});
  }

  localErrorHandler(localErrorMessage) {
    this.setState({ localErrorMessage: localErrorMessage });
  }

  messageDismissCallback() {
    this.setState({
      localErrorMessage: null,
      localSuccessMessage: null
    });
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({loading: true});

    CommissioningService.getFeeltDeviceDetail(this.state.commissionedDeviceReferenceId).then(response => {
      console.log(response);
      this.setState({
        commissionedDevice: response.commissionedDevice,
        loading: false
      });
    }).catch(error => {
      console.error(error);
      this.localErrorHandler(error.message);
      this.setState({loading: false, hasError: true});
    });
  }

  renderNormalTime(value) {
    const date = TimeFormattingService.renderDate(value, null);
    const time = TimeFormattingService.renderTime(value, null);
    return (<span>{date} {time}</span>);
  }

  wrapDSCDValueIfNeeded(value) {
    if (value.includes("\n")) {
      return (<pre>{value}</pre>);
    } else {
      return (<b>{value}</b>);
    }
  }

  canDownloadDSCD(key) {
    return key === "ca_crt" || key === "gateway_crt" || key === "gateway_key"; 
  }

  downloadDSCD(key) {
    console.log(this.state);
    const deviceTypeSpecificData = this.state.commissionedDevice.deviceTypeSpecificData;

    const element = document.createElement("a");
    const file = new Blob([deviceTypeSpecificData[key]], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    if (key === "ca_crt") {
      element.download = "opsense-ca.crt";
    } else if (key === "gateway_crt") {
      element.download = "opsense-mqtt.crt";
    } else if (key === "gateway_key") {
      element.download = "opsense-mqtt.key";
    } else {
      element.download = key;
    }
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  downloadDevicePackage(downloadURL, downloadFilename) {
    this.setState({packageDownloading: true});
    axios({
      url: downloadURL,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', downloadFilename);
        document.body.appendChild(link);
        link.click();
        this.setState({packageDownloading: false});
    }).catch((error) => {
      this.setState({packageDownloading: false});
    });
  }

  render() {
    if (this.state.commissionedDevice) {
      const deviceInventory = this.state.commissionedDevice.deviceInventory;
      const deviceTypeSpecificData = this.state.commissionedDevice.deviceTypeSpecificData;
      const availablePackageName = this.state.commissionedDevice.availablePackageName;

      return (
        <div className = "admin-devices">
          <div className="row">
            <PageTitle title={ `Commissioned Device Detail` } refreshFunction={this.refresh.bind(this)} />
          </div>
          
          { this.state.localErrorMessage ? <Message errorMessage={ this.state.localErrorMessage } messageDismissCallback={ this.messageDismissCallback.bind(this) } /> : null }

          { this.state.localSuccessMessage ? <Message messages={{ state: { successMessage: this.state.localSuccessMessage } }} messageDismissCallback={ this.messageDismissCallback.bind(this) } /> : null}

          <div className="inner op-content-box">
            <div className="row">
              <div className="col-sm-12">
                <div className="card-block">
                  <div>Device Type: <b>{deviceInventory.deviceType.name}</b></div>
                  <div>Device Class: <b>{deviceInventory.deviceType.deviceSerialNumberType.name}</b></div>
                  <div>Device Function: <b>{deviceInventory.deviceType.deviceFunctionalType.name}</b></div>
                  {deviceInventory.commissioningFailureMessage ? <div>Failure Message: <b>{deviceInventory.commissioningFailureMessage}</b> </div> : null}
                  <div>Device Mac Address: <b>{deviceInventory.deviceRadioMacAddress}</b></div>
                  { deviceInventory.importedOn ? <div>Imported: <b>{this.renderNormalTime(deviceInventory.importedOn)}</b> </div> : null }
                  { deviceInventory.commissionedAt ? <div>Commissioned: <b>{this.renderNormalTime(deviceInventory.commissionedAt)}</b> </div> : null }
                  

                  <CommissionedDevicePackage 
                      availablePackageName={availablePackageName} 
                      download={() => this.downloadDevicePackage(`${config.API_BASE_URL}commissioning/fleets/device/package/${this.state.commissionedDeviceReferenceId}/${availablePackageName}`, availablePackageName)} 
                      downloading={ this.state.packageDownloading }
                  />

                  <CommissionedDeviceAttributes 
                      deviceTypeSpecificData={deviceTypeSpecificData} 
                      wrapValueIfNeeded={this.wrapDSCDValueIfNeeded.bind(this)} 
                      canDownload={this.canDownloadDSCD.bind(this)} 
                      download={this.downloadDSCD.bind(this)} 
                  />
                                 
                </div>
              </div>
            </div>
            
          </div>
        </div>
      );  
    } else {
      return <div className="loader">Loading...</div>;
    }    
  }
}

export default CommissionedDeviceView;

const CommissionedDevicePackage = (props) => {
  return (
    <div>
    {
      props.availablePackageName ?
      <div style={{paddingTop: 5}}>
        <hr/>
        <div style={{paddingBottom: 5}}>
          <div style={{paddingBottom: 5}}>
            Download available installation package <strong>{props.availablePackageName}</strong> 
          </div>
          { props.downloading ? <strong>Generating Download...</strong> : <button onClick={() => { props.download() }}>Download</button> }
        </div>
      </div> : null
    }
    </div>
  );
};

const CommissionedDeviceAttributes = (props) => {
  const [showDSCD, setShowDSCD] = useState(false);

  return (
    <div>
      {
        props.deviceTypeSpecificData ? 
        <div style={{paddingTop: 5}}>
          <hr/>
          <div style={{paddingBottom: 5}}>
            Device Specific Commissioning Data <button onClick={() => setShowDSCD(!showDSCD)}>{showDSCD ? <span>Hide</span> : <span>Show</span>}</button>
          </div>
          { 
            showDSCD ? 
            <div>
              <ul>        
              {
                Object.keys(props.deviceTypeSpecificData).map((key) => (
                  <li id={key} key={key}>
                    Key: <strong>{key} {props.canDownload(key) ? <button onClick={() => { props.download(key) }}>Download</button> : null }</strong> <br/> Value: { props.wrapValueIfNeeded(props.deviceTypeSpecificData[key]) }
                  </li>
                ))
              }
              </ul>
            </div> : null 
          }
        </div> : null 
      }
    </div>
  );
};