import React, { useState } from 'react';
import i18next from 'i18next';

const EnterpriseWizardGeneralForm = ({customerName, currentBusinessStructureDefault, onGeneralUpdate}) => {
    const [name, setName] = useState(customerName);
    const [businessStructureDefault, setBusinessStructureDefault] = useState(currentBusinessStructureDefault)

    const handleSubmit = (event) => {
        event.preventDefault();

        onGeneralUpdate(name, businessStructureDefault);
    }

    return (
        <div>
            <h3>General</h3>
            <form onSubmit={handleSubmit}>
                <div className="row" style={{display: "flex", alignItems: "center"}}>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <input
                            className="form-control"
                            name="name"
                            type="text"
                            value={name}
                            label={ i18next.t('form-labels.name') }
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                </div>

                <div className="row" style={{display: "flex", alignItems: "center", paddingTop: 10}}>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        Business Structure Options: <br/>
                        
                        <label>
                            <input type="radio" id="grocery_default" name="bs_default" value="grocery_default" checked={businessStructureDefault === "grocery_default"}  onChange={(event) => setBusinessStructureDefault(event.target.value)} />
                            &nbsp;
                            Grocery: Corporation -&gt; Location
                        </label>

                        <label htmlFor="qsr_default">
                            <input type="radio" id="qsr_default" name="bs_default" value="qsr_default" checked={businessStructureDefault === "qsr_default"} onChange={(event) => setBusinessStructureDefault(event.target.value)} />
                            &nbsp;
                            QSR: Corporation -&gt; Store
                        </label>

                        <label htmlFor="bs_custom">
                            <input type="radio" id="bs_custom" name="bs_default" value="bs_custom" checked={businessStructureDefault === "bs_custom"} onChange={(event) => setBusinessStructureDefault(event.target.value)} />
                            &nbsp;
                            Custom
                        </label>
                    </div>
                </div>

                <hr/>

                <div className="pull-right">
                    <button type="submit" className="btn btn-primary pull-right" disabled={name === ''}>Next</button>
                </div>
            </form>
        </div>
    )
}

export default EnterpriseWizardGeneralForm;