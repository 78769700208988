import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

class Message extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.setMessages(props)
  }

  setMessages(props) {
    this.state = { errorMessage: null, successMessage: null };

    if (props.errorMessage) {
      this.state.errorMessage = props.errorMessage;
    }

    if (props.messageDismissCallback) {
      this.state.messageDismissCallback = props.messageDismissCallback;
    }

    if (props.messages && props.messages.state && props.messages.state.errorMessage) {
      this.state.errorMessage = props.messages.state.errorMessage;
    }

    if (props.messages && props.messages.state && props.messages.state.successMessage) {
      this.state.successMessage = props.messages.state.successMessage;
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setMessages(nextProps)
  }

  handleErrorAlertDismiss() {
    this.setState({ errorMessage: null });
    if (this.state.messageDismissCallback) {
      this.state.messageDismissCallback()
    }
  }

  handleSuccessAlertDismiss() {
    this.setState({ successMessage: null });
    if (this.state.messageDismissCallback) {
      this.state.messageDismissCallback()
    }
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <Alert bsStyle="danger" onDismiss={this.handleErrorAlertDismiss.bind(this)}>
          {this.state.errorMessage}
        </Alert>
      );
    }
    if (this.state.successMessage) {
      return (
        <Alert bsStyle="success" onDismiss={this.handleSuccessAlertDismiss.bind(this)}>
          {this.state.successMessage}
        </Alert>
      );
    }
    return null;
  }
}

export default Message;
