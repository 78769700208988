import React, { useEffect, useState } from "react";

import ReactTable from "react-table";
import { PageTitle } from "../PageTitle";
import { Link } from "react-router-dom";
import { InventoryService, OrdersService } from "../../services";
import i18next from "i18next";
import Select from "react-select";
import { Alert, Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment-timezone";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faInfo from "@fortawesome/fontawesome-free-solid/faInfo";


const ShipmentParcelFormRow = ({ onChange }) => {
    const [selectedWeightUnit, setSelectedWeightUnit] = useState(null);
    const [weight, setWeight] = useState(null);
    const [height, setHeight] = useState(null);
    const [length, setLength] = useState(null);
    const [width, setWidth] = useState(null);

    // g, kg, lb, oz
    const weightUnitOptions = [
        { label: "Select", value: null },
        { label: "Onces", value: "oz" },
        { label: "Pounds", value: "lb" },
        { label: "Grams", value: "g" },
        { label: "Kilograms", value: "kg" },
    ];

    useEffect(() => {
        if (selectedWeightUnit && selectedWeightUnit.value && parseFloat(weight) && parseFloat(length) && parseFloat(width) && parseFloat(height)) {
            onChange({
                weightUnit: selectedWeightUnit.value,
                weight: parseFloat(weight),
                lengthInInches: parseFloat(length),
                widthInInches: parseFloat(width),
                heightInInches: parseFloat(height),
            });
        }
    }, [selectedWeightUnit, weight, height, length, width]);

    return (
        <div>
            <div className="row">
                <div className="col-sm-4">
                    Weight: <input type="text" style={{width: 75}} onChange={({ target }) => setWeight(target.value)} />
                </div>
                <div className="col-sm-8">
                    Weight Unit: &nbsp;
                    <select onChange={({target}) => setSelectedWeightUnit(weightUnitOptions[target.value])}>
                        {weightUnitOptions.map((option, i) => (<option key={i} value={i}>{ option.label }</option>))}
                    </select>
                </div>
            </div>
            <div className="row" style={{paddingTop: 10}}>
                <div className="col-sm-4">
                    Length: <input type="text" style={{width: 75}} onChange={({ target }) => setLength(target.value)} /> in
                </div>
                <div className="col-sm-4">
                    Width: <input type="text" style={{width: 75}} onChange={({ target }) => setWidth(target.value)} /> in
                </div>
                <div className="col-sm-4">
                    Height: <input type="text" style={{width: 75}} onChange={({ target }) => setHeight(target.value)} /> in
                </div>
            </div>
        </div>
    );
}

const ShipmentForm = ({ onUpdate }) => {
    const [parcels, setParcels] = useState([]);

    useEffect(() => {
        onUpdate({
            parcels: parcels,
            destinationIsResidential: false,
        });
    }, [parcels]);

    const handleParcelChange = (parcel) => {
        // TODO handle more than one...
        setParcels([parcel]); 
    };

    return (
        <div>
            <h2>Parcels</h2>

            <ShipmentParcelFormRow onChange={handleParcelChange} />            
        </div>
    );
};

const ShipmentSelectedRate = ({ selectedRate, showClear }) => (
    <div>
        <h2>Selected Rate</h2>
        <div className="row">
            <div className="col-sm-12">
                <img src={selectedRate.iconUrl} style={{paddingBottom: 10}} />
                <table>
                    <tbody>
                        <tr>
                            <td style={{ paddingRight: 5 }}>
                                <strong>Description:</strong>
                            </td>
                            <td>{selectedRate.description}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: 5 }}>
                                <strong>ETA:</strong>
                            </td>
                            <td>{moment(selectedRate.eta).format("L")}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: 5 }}>
                                <strong>Cost:</strong>
                            </td>
                            <td>${selectedRate.cost}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        { showClear &&
            <div className="row" style={{ paddingTop: 20 }}>
                <div className="col-sm-12">
                    <Button className="pull-right" onClick={() => setSelectedRate(null)}>
                        Clear Selection
                    </Button>
                </div>
            </div>
        }
    </div>
);

const ShipmentRateForm = ({ onUpdate, shipment }) => {
    const [rates, setRates] = useState([]);
    const [selectedRate, setSelectedRate] = useState(null);
    const [destinationValidationErrors, setDestinationValidationErrors] = useState([]);

    useEffect(() => {
        if (shipment) {
            let tmpRates = shipment.rates;
            tmpRates.sort((a, b) => parseFloat(a.cost) - parseFloat(b.cost));
            setRates(tmpRates);

            if (shipment.invalidDestinationDescriptions) {
                setDestinationValidationErrors(shipment.invalidDestinationDescriptions);
            }
        }
    }, [shipment]);

    useEffect(() => {
        onUpdate(selectedRate);
    }, [selectedRate])

    return destinationValidationErrors.length > 0 ? (
        <div>
            <h2>Destination Errors</h2>
            <ul>
            {
                destinationValidationErrors.map((error, index) => <li key={index}>{error}</li>)
            }
            </ul>
        </div>
    )
    : 
    (
        <div>
            { selectedRate && <ShipmentSelectedRate selectedRate={selectedRate} showClear={true} /> }

            { !selectedRate &&
                <>
                    <h2>Available Rates</h2>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>Description</th>
                                <th>ETA</th>
                                <th>Cost</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rates.map((rate) => {
                                return (
                                    <tr key={rate.rateId}>
                                        <td style={{ padding: 5 }}>
                                            <img src={rate.iconUrl} />
                                        </td>
                                        <td style={{ padding: 5 }}>{rate.description}</td>
                                        <td style={{ padding: 5 }}>{moment(rate.eta).format("L")}</td>
                                        <td style={{ padding: 5 }}>${rate.cost}</td>
                                        <td style={{ padding: 5 }}>
                                            <Button onClick={() => setSelectedRate(rate)}>Select</Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {shipment?.serviceMessages && (
                        <div>
                            Service Messages: <br />
                            <ul>
                                {shipment.serviceMessages.map((message, i) => {
                                    return <li key={i}>{message}</li>;
                                })}
                            </ul>
                        </div>
                    )}
                </>
            }
        </div>
    );
};

const ShipmentValidatedAddress = ({ address }) => (
    <div>
        <div className="row">
            <div className="col-sm-6"><strong>Name:</strong> {address.name}</div>
            <div className="col-sm-6"><strong>Attn:</strong> {address.company}</div>
        </div>
        <div className="row" style={{paddingTop: 20}}>
            <div className="col-sm-12"><strong>Street 1:</strong> {address.street1}</div>
            {address.street2 && <div className="col-sm-12"><strong>Street 2:</strong> {address.street2}</div>}
            {address.street3 && <div className="col-sm-12"><strong>Street 3:</strong> {address.street3}</div>}
            <div className="col-sm-4"><strong>City:</strong> {address.city}</div>
            <div className="col-sm-4"><strong>State:</strong> {address.state}</div>
            <div className="col-sm-4"><strong>Zip:</strong> {address.zip}</div>
        </div>
        <div className="row" style={{paddingTop: 20}}>
            <div className="col-sm-6"><strong>Validated:</strong> {address['validation_results']['is_valid'] ? "Yes" : "No"}</div>
        </div>
        { address['validation_results'].messages && address['validation_results'].messages.length > 0 &&
            <div className="row" style={{paddingTop: 20}}>
                <div className="col-sm-12"><strong>Messages:</strong> <ul>{address['validation_results'].messages.map((message, index) => <li key={index}>{message.text}</li>)}</ul></div>
            </div>
        }
    </div>
);

const ShippingTransaction = ({ transaction }) => (
    <div>
        Status: {transaction.status} <br/>

        Tracking Number: <a href={transaction["tracking_url_provider"]} target="_blank">{transaction["tracking_number"]}</a><br/>
        Tracking Status: {transaction["tracking_status"]} <br/>

        Label: <a href={transaction["label_url"]} target="_blank">Open</a>
    </div>
);

const ShipmentConfirmation = ({ address, parcels, rate }) => (
    <div>
        <h2>Shipping To</h2>
        <ShipmentValidatedAddress address={address} />

        <br/>

        <h2>Parcels</h2>
        <table>
            <thead>
                <tr>
                    <th>No.</th>
                    <th>Weight</th>
                    <th>LxWxH</th>
                </tr>
            </thead>
            <tbody>
            {
                parcels.map((parcel, index) => (
                    <tr key={index}>
                        <td style={{paddingRight:25}}>{index+1}.</td>
                        <td style={{paddingRight:10}}>{parcel.weight} {parcel["mass_unit"]}</td>
                        <td>{parcel.length}{parcel["distance_unit"]} x {parcel.width}{parcel["distance_unit"]} x {parcel.height}{parcel["distance_unit"]}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>

        <br/>

        <ShipmentSelectedRate selectedRate={rate} showClear={false} />
    </div>
);

const ShippingLabelModal = ({ show, order, onClose }) => {
    const STEPS = { Address: 0, Shipment: 1, Rates: 2, Confirmation: 3, Transaction: 4 };
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState(null);
    const [step, setStep] = useState(STEPS.Address);
    const [readyForNext, setReadyForNext] = useState(false);
    const [validatedAddress, setValidatedAddress] = useState(null);
    const [shipmentRequest, setShipmentRequest] = useState(null);
    const [shipmentResponse, setShipmentResponse] = useState(null);
    const [selectedRate, setSelectedRate] = useState(null);
    const [shipmentTransaction, setShipmentTransaction] = useState(null);

    useEffect(() => {
        if (order) {
            let requests = [];
            setLoading(true);

            requests.push(OrdersService.getValidatedAddress(order.id));

            if (order.shippingData && order.shippingData["shippo_address_id"]) {
                if (order.shippingData["shippo_transaction_id"]) {
                    requests.push(OrdersService.getOrderShipmentTransaction(order.id));                        
                }
                
                if (order.shippingData["shippo_shipment_id"]) {
                    requests.push(OrdersService.getOrderShipment(order.id));
                }
            }

            Promise.all(requests)
                .then((responses) => {
                    let maxStep = STEPS.Address;
                    responses.forEach((response) => {
                        if (response.transaction) {
                            setShipmentTransaction(response.transaction);
                            maxStep = Math.max(maxStep, STEPS.Transaction);
                        }
                        if (response.shipment) {
                            setShipmentResponse(response.shipment);
                            maxStep = Math.max(maxStep, STEPS.Rates);
                        }
                        if (response.address) {
                            setValidatedAddress(response.address);
                            maxStep = Math.max(maxStep, STEPS.Address);
                        }
                    });
                    setLoading(false);
                    setStep(maxStep);
                })
                .catch((err) => {
                    console.error(err);
                    setStatusMessage(`Error loading shipping data. ${err.message}`);
                    setLoading(false);
                });
        }
    }, [order]);

    useEffect(() => {
        if (step === STEPS.Address) { // Showing validated address
            setReadyForNext(true);
        } else if (step === STEPS.Shipment) { // Showing shipment config
            setReadyForNext(false);
        } else if (step === STEPS.Rates) { // Showing select rate
            setReadyForNext(false);
        } else if (step === STEPS.Confirmation) { // Showing confirmation
            setReadyForNext(true);
        } else if (step === STEPS.Transaction) { // Showing transaction
            setReadyForNext(false);
        } else {
            console.error(`Unknown step: ${step}`);
        }
    }, [step]);

    useEffect(() => {
        if (shipmentRequest && step === STEPS.Shipment) {
            setReadyForNext(shipmentRequest.parcels.length > 0);
        }
    }, [shipmentRequest]);

    useEffect(() => {
        if (selectedRate) {
            setReadyForNext(true);
        } else if (step === STEPS.Rates) {
            setReadyForNext(false);
        }
    }, [selectedRate]);

    const shipmentReadyAction = () => {
        return new Promise((resolve, reject) => {
            if (shipmentRequest != null) {
                setLoading(true);

                OrdersService.createOrderShipment(order.id, shipmentRequest)
                    .then(({ shipment }) => {
                        setShipmentResponse(shipment);
                        setLoading(false);
                        resolve();
                    })
                    .catch((err) => {
                        setLoading(false);
                        reject(err);
                    });
            } else {
                resolve();
            }
        });
    };

    const doNextAction = () => {
        if (step === STEPS.Address) { // Showing validated address
            setStep(STEPS.Shipment);
        } else if (step === STEPS.Shipment) { // Showing shipment config
            shipmentReadyAction()
                .then(() => setStep(STEPS.Rates))
                .catch((err) => {
                    console.error(err);
                    setStatusMessage(`Error creating shipment. ${err.message}`);
                });
        } else if (step === STEPS.Rates) { // Showing select rate
            setStep(STEPS.Confirmation)
        } else if (step === STEPS.Confirmation) { // Showing confirmation
            setLoading(true);

            OrdersService.createOrderShipmentTransaction(order.id, selectedRate.rateId)
                .then(({ transaction }) => {
                    setShipmentTransaction(transaction);
                    setStep(STEPS.Transaction);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setStatusMessage(`Error creating transaction. ${err.message}`);
                    setLoading(false);
                });
        } else if (step === STEPS.Transaction) { // Showing transaction
            // No next step
        } else {
            console.error(`Unknown step: ${step}`);
        }
    };

    const doClose = () => {
        setLoading(false);
        setStatusMessage(null);
        setValidatedAddress(null);
        setShipmentRequest(null);
        setShipmentResponse(null);
        setSelectedRate(null);
        setShipmentTransaction(null);
        setStep(STEPS.Address);
        setReadyForNext(false);

        onClose();
    };

    return (
        <Modal show={show} onHide={doClose}>
            <Modal.Header closeButton>
                <Modal.Title>Generate Shipping Label</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {statusMessage && <div style={{paddingBottom: 15}}>{statusMessage}</div>}

                {loading && <div className="loader">Loading...</div>}

                {step === STEPS.Address && validatedAddress && !loading && <ShipmentValidatedAddress address={validatedAddress} />}

                {step === STEPS.Shipment && !loading && <ShipmentForm onUpdate={(shipmentData) => setShipmentRequest(shipmentData)} />}
                
                {step === STEPS.Rates && !loading && <ShipmentRateForm onUpdate={(rate) => setSelectedRate(rate)} shipment={shipmentResponse} />}

                {step === STEPS.Confirmation && !loading && <ShipmentConfirmation address={validatedAddress} parcels={shipmentResponse.parcels} rate={selectedRate} />}

                {step === STEPS.Transaction && !loading && <ShippingTransaction transaction={shipmentTransaction} />}
            </Modal.Body>
            <Modal.Footer>
                {step !== STEPS.Transaction && <Button onClick={doNextAction} disabled={!readyForNext}>Next</Button>}
                {step === STEPS.Transaction && <Button onClick={doClose}>Close</Button>}
            </Modal.Footer>
        </Modal>
    );
};

const OrderListActions = ({ order, createShippingLabel }) => (
    <div>
        <Link className="btn btn-sm btn-default" to={`/admin-fulfillment/orders/${order.id}/edit`}>Edit</Link> &nbsp;
        <Link className="btn btn-sm btn-default" to={`/admin-fulfillment/orders/${order.id}/view`}>View</Link> &nbsp;
        <a className="btn btn-sm btn-default" target="_blank" href={`/admin-fulfillment/orders/${order.id}/packing-slip?stripnav=true`}>Packing Slip</a> &nbsp;
        {order.orderStatus.token === 'configured' && <Button className="btn btn-sm btn-default" onClick={() => createShippingLabel(order)}>Shipping Label</Button> }
    </div>
);

const OrderPopover = ({ order, ...props }) => (
    <Popover {...props} id="order-tooltip">
        <div style={{padding: 10}}>
            
            <b>Ship To:</b> <br/>
            <div style={{paddingLeft:5}}>
                {order.shippingAttention}
                {order.shippingAddressLineOne} <br/>
                {order.shippingAddressLineTwo && <span>{order.shippingAddressLineTwo} <br/></span>}
                {order.shippingAddressCity}, {order.shippingAddressState}, {order.shippingAddressZip} <br/>
            </div>
            <hr style={{margin:0, padding:2}}/>
            <b>Fields:</b> <br/>
            <div style={{paddingLeft:5}}>
                {
                    order.extraValues.length == 0 ? 
                    <span>None</span> :
                    <table>
                        <tr><td style={{paddingLeft: 5}}>name</td><td style={{paddingLeft: 5}}>Value</td></tr>
                        {order.extraValues.map((field) => <tr key={field.key}><td style={{paddingLeft: 5}}>{field.key}</td><td style={{paddingLeft: 5}}>{field.value}</td></tr>)}
                    </table>
                }
            </div>
            <hr style={{margin:0, padding:2}}/>
            <b>Items:</b> <br/>
            <div style={{paddingLeft:5}}>
                {
                    order.orderItems.length == 0 ? 
                    <span>None</span> :
                    <table>
                        <tr><td style={{paddingLeft: 5}}>Quantity</td><td style={{paddingLeft: 5}}>Description</td></tr>
                        {order.orderItems.map((item) => <tr key={item.id}><td style={{paddingLeft: 5}}>{item.quantity}</td><td style={{paddingLeft: 5}}>{item.description}</td></tr>)}
                    </table>
                }
            </div>
        </div>
    </Popover>
);

const OrderNumberWithPopover = ({ order }) => {
    return (
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<OrderPopover order={order} />}>
            <div>
                <div style={{ paddingTop: 10, display: "inline-block" }}>{order.displayNumber}</div>
                &nbsp;
                <FontAwesomeIcon icon={faInfo} size={"xs"} style={{ padding: 0 }} />
            </div>
        </OverlayTrigger>
    );
};

const tableColumns = (createShippingLabel) => (
    [{
        Header: "Status",
        accessor: "orderStatus.name",
        minResizeWidth: 100,
        width: 100
    }, {
        Header: "Order Number",
        Cell: ({original}) => <OrderNumberWithPopover order={original}/>,
        minResizeWidth: 100,
        width: 150
    }, {
        Header: "Customer",
         accessor: "enterprise.name",
        minResizeWidth: 100,
        width: 150
    }, {
        Header: "Fufillment By",
        accessor: "deviceInventoryLocation.name",
        minResizeWidth: 100,
        width: 150
    }, {
        Header: "Item Count",
        Cell: ({original}) => <span>{original.orderItems.length}</span>,
        minResizeWidth: 100,
        width: 100
    }, {
        Header: "Actions",
        sortable: false,
        Cell: ({original}) => <OrderListActions order={original} createShippingLabel={createShippingLabel} />
    }]
);

const OrderList = () => {
    const statusFilterOptions = [
        { value: "new", label: "New" },
        { value: "started", label: "Started" },
        { value: "configured", label: "Configured" },
        { value: "shipped", label: "Shipped" },
        { value: "completed", label: "Completed" },
    ];

    const [orders, setOrders] = useState([]);
    const [orderStatusFilter, setOrderStatusFilter] = useState([statusFilterOptions[statusFilterOptions.length - 1], statusFilterOptions[statusFilterOptions.length - 2]]);
    const [locationFilter, setLocationFilter] = useState(null);
    const [locationFilterOptions, setLocationFilterOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [shippingLabelOrder, setShippingLabelOrder] = useState(null);

    useEffect(() => {
        setLoading(true);

        InventoryService.getLocations()
            .then(({locations}) => {
                const options = [
                    {value: null, label: "Any"},
                    {value: -1, label: "None"}
                ];
                locations.forEach((location) => options.push({ value: location.id, label: location.name }));
                setLocationFilterOptions(options);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setErrorMessage("Error loading orders");
                setLoading(false);
            });
    }, []);

    const loadOrders = () => {
        setLoading(true);

        OrdersService.getOrders(orderStatusFilter.map((exclude) => exclude.value), locationFilter?.value)
            .then(({orders}) => {
                setOrders(orders);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setErrorMessage("Error loading orders");
                setLoading(false);
            });
    }

    useEffect(loadOrders, [orderStatusFilter, locationFilter]);

    return (
        <div>
            <div className="row">
                <PageTitle title="Orders" addLink={{ link: { pathname: "/admin-fulfillment/orders/add" } }} />
            </div>
            <div className="inner op-content-box">
                {errorMessage && 
                    <Alert bsStyle="danger" onDismiss={() => setErrorMessage(null)}>
                        {errorMessage}
                    </Alert>
                }

                <div className="row">
                    <div className="col-sm-6">
                        Excluded Statuses:
                        <Select
                          options={statusFilterOptions}
                          value={orderStatusFilter}
                          isMulti
                          onChange={setOrderStatusFilter}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder={i18next.t('select')}
                        />
                    </div>

                    <div className="col-sm-6">
                        Included Locations:
                        <Select
                          options={locationFilterOptions}
                          value={locationFilter}
                          onChange={setLocationFilter}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder={i18next.t('select')}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card-block">
                            <div className="react-table-custom">            
                                <ReactTable
                                    className="-striped -highlight"
                                    data={orders}
                                    loading={loading}
                                    showPaginationTop
                                    columns={tableColumns((order) => setShippingLabelOrder(order))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                <ShippingLabelModal 
                    show={shippingLabelOrder != null} 
                    order={shippingLabelOrder} 
                    onClose={() => { loadOrders(); setShippingLabelOrder(null); }}
                />
            </div>
        </div>
    );
};

export default OrderList;