import moment from 'moment-timezone';
import i18next from 'i18next';

class TimeFormattingService {
  static renderFromNowTime(time, timezone) {
    moment.locale(i18next.language);
    return timezone ? moment(time).tz(timezone).fromNow() : moment(time).fromNow();
  }

  static renderNormalTime(time, timezone) {
    moment.locale(i18next.language);
    return timezone ? moment(time).tz(timezone).format('L LTS zz') : moment(time).format('L LTS');
  }

  static renderNow(timezone) {
    moment.locale(i18next.language);
    return timezone ? moment().tz(timezone).format('L LTS zz') : moment().format('L LTS');
  }

  static renderDate(timestamp, timezone) {
    moment.locale(i18next.language);
    return timezone ? moment(timestamp).tz(timezone).format('L') : moment(timestamp).format('L');
  }

  static renderTime(timestamp, timezone) {
    moment.locale(i18next.language);
    return timezone ? moment(timestamp).tz(timezone).format('LTS zz') : moment(timestamp).format('LTS');
  }

  static renderTimeOnDate(timestamp, timezone) {
    moment.locale(i18next.language);
    if (timezone) {
      const fullDT = moment(timestamp).tz(timezone);
      return `${fullDT.format('LTS')} ${i18next.t('pages.event-details.on')} ${fullDT.format('ddd, lll')}`;
    } else {
      return moment(timestamp).format('L LTS');
    }
  }

  static secondsToHoursMinutes(seconds) {
    moment.locale(i18next.language);
    return moment.utc(seconds * 1000).format('LT');
  }
}

export default TimeFormattingService;