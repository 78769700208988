import React, { useEffect, useState } from "react";
import { PageTitle } from "../PageTitle";
import OrderForm from "./order_form";
import { OrdersService } from "../../services";
import { Redirect } from "react-router-dom";

const OrderEdit = ({ match }) => {
    const [existingOrder, setExistingOrder] = useState(null);
    const [loading, setLoading] = useState(false);    
    const [errorMessage, setErrorMessage] = useState(null);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        setLoading(true);

        OrdersService.getOrder(match?.params?.orderId)
            .then(({order}) => {
                setExistingOrder(order);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setErrorMessage("Error loading order");
                setLoading(false);
            });
    }, [match]);

    const onSuccess = () => {
        setRedirect(true);
    };

    const onError = () => {
        setErrorMessage("Error saving order.");
    };

    if (redirect) {
        return <Redirect to={{ pathname: "/admin-fulfillment/orders", state: { successMessage: "Order updated." } }} />;
    }

    return loading ? (<div className='loader'>Loading...</div>) : (
        <div className="animated fadeIn">
            <div className="row">
                <PageTitle title="Edit Order" />
            </div>
            <div className="op-content-box">
                {errorMessage && <div className="row"><div className="col-sm-12">{errorMessage}</div></div>}
                {existingOrder && <div className="row">
                    <div className="col-sm-12">
                        <OrderForm existingOrder={existingOrder} onSuccess={onSuccess} onError={onError} />
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default OrderEdit;