import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageTitle } from '../PageTitle';
import { Message } from '../Message';
import DocumentForm from './document_form';
import DocumentsService from '../../services/documents_service';

class DocumentAdd extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            added: false,
            localErrorMessage: null
        }
    }
    
    handleSubmit(document) {
        DocumentsService.add(document).then(response => {
            this.setState({added: response});
        }).catch(error => {
            console.error(error);
            this.setState({ localErrorMessage: error.message });
      });
    }

    messageDismissCallback() {
        this.setState({ localErrorMessage: null });
        }

    renderMessages() {
        if (this.state.localErrorMessage) {
            return (
            <Message errorMessage={this.state.localErrorMessage} messageDismissCallback={this.messageDismissCallback.bind(this)} />
            );
        }

        return null;
    }
    
    render() {
        if (this.state.added) {
            return (
                <Redirect to={{
                  pathname: '/admin-admin/docs',
                  state: {
                    from: this.props.location,
                    successMessage: 'Document added.'
                  }
                }}/>
            )
        } else
        {
            return (        
                <div>
                    <div className="row">
                        <PageTitle title="Add Document"/>
                    </div>
                    {this.renderMessages()}
                    <div className="row"> 
                        <DocumentForm
                            onSubmit={this.handleSubmit.bind(this)}
                        />
                    </div>
                </div>
            ) 
        }
    }
}

export default DocumentAdd;