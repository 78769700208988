import React from 'react';
import i18next from "i18next";

const RowButton = ({allowView, allowEdit, allowCopy, viewClick, editClick, copyClick, rowId, children}) => (
        <div className="text-center">
         {allowView &&
                  <button className="btn btn-sm btn-default"
                       onClick={() => viewClick(rowId)}
                       >{ i18next.t('form-labels.view') }</button>
         }
         &nbsp;
         {allowEdit &&
                  <button className="btn btn-sm btn-default"
                          onClick={() => editClick(rowId)}
                          >{ i18next.t('form-labels.edit') }</button>
        }
        &nbsp;
        { allowCopy &&
                <button className="btn btn-sm btn-default" 
                        onClick={() => copyClick(rowId)}
                        >{ i18next.t('form-labels.duplicate') }</button>
        }
        { children }
        </div>
  );

export default RowButton;