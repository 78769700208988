import Documents from './documents';
import DocumentList from './document_list';
import DocumentAdd from './document_add';
import DocumentEdit from './document_edit';

export {
    Documents,
    DocumentList,
    DocumentEdit,
    DocumentAdd
};