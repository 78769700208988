import React, { useEffect, useState } from "react";
import Select from "react-select";
import { EnterprisesService, InventoryService, OrdersService } from "../../services";
import i18next from "i18next";

const OrderForm = ({existingOrder, onSuccess, onError}) => {
    const [loading, setLoading] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [orderStatusOptions, setOrderStatusOptions] = useState([]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
    const [enterpriseOptions, setEnterpriseOptions] = useState([]);
    const [selectedEnterprise, setSelectedEnterprise] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [contactEmail, setContactEmail] = useState(existingOrder ? existingOrder.contactEmail : '');
    const [contactName, setContactName] = useState(existingOrder ? existingOrder.contactName : "");
    const [shippingToName, setShippingToName] = useState(existingOrder ? existingOrder.shippingToName : "");
    const [shippingAttention, setShippingAttention] = useState(existingOrder ? existingOrder.shippingAttention : "");
    const [shippingPhone, setShippingPhone] = useState(existingOrder ? existingOrder.shippingPhone : "");
    const [shippingAddressLineOne, setShippingAddressLineOne] = useState(existingOrder ? existingOrder.shippingAddressLineOne : "");
    const [shippingAddressLineTwo, setShippingAddressLineTwo] = useState(existingOrder ? existingOrder.shippingAddressLineTwo : "");
    const [shippingAddressCity, setShippingAddressCity] = useState(existingOrder ? existingOrder.shippingAddressCity : "");
    const [shippingAddressState, setShippingAddressState] = useState(existingOrder ? existingOrder.shippingAddressState : "");
    const [shippingAddressZip, setShippingAddressZip] = useState(existingOrder ? existingOrder.shippingAddressZip : "");
    const [extraValues, setExtraValues] = useState(existingOrder ? existingOrder.extraValues : []);
    const [items, setItems] = useState(existingOrder ? existingOrder.orderItems : []);
    const [orderAttachments, setOrderAttachments] = useState(existingOrder ? existingOrder.orderAttachments : []);
    const isUpdate = existingOrder != null;

    useEffect(() => {
        setLoading(true);

        const requests = [];

        requests.push(OrdersService.getOrderStatuses());
        requests.push(EnterprisesService.getAll());
        requests.push(OrdersService.getDeviceTypes());
        requests.push(InventoryService.getLocations());

        Promise.all(requests)
            .then((results) => {
                results.forEach((result) => {
                    if (result.orderStatuses) {
                        const options = result.orderStatuses
                                .sort((a, b) => a.displayOrder - b.displayOrder)
                                .map((orderStatus) => ({ value: orderStatus.token, label: orderStatus.name, orderStatus: orderStatus }));

                        setOrderStatusOptions(options);

                        const existingStatus = existingOrder ? options.find((option) => option.value == existingOrder.orderStatus.token) : options.find((option) => option.value == 'new');
                        setSelectedOrderStatus(existingStatus);
                    } else if (result.enterprises) {
                        const options = result.enterprises
                                .map((enterprise) => ({ value: enterprise.token, label: enterprise.name, enterprise: enterprise }));

                        setEnterpriseOptions(options);

                        if (existingOrder) {
                            setSelectedEnterprise(options.find((option) => option.value == existingOrder.enterprise?.token));
                        }
                    } else if (result.deviceTypes) {
                        const options = result.deviceTypes
                                .map((deviceType) => ({ value: deviceType.token, label: deviceType.name, deviceType: deviceType }));

                        setDeviceTypeOptions(options);
                    } else if (result.locations) {
                        const options = result.locations
                                .map((location) => ({ value: location.token, label: location.name, location: location }));

                        setLocationOptions(options);

                        if (existingOrder) {
                            setSelectedLocation(options.find((option) => option.value == existingOrder.deviceInventoryLocation?.token));
                        }
                    }
                });

                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                onError("Error loading statuses");
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setCanSubmit(
            selectedOrderStatus != null &&
            contactName != '' &&
            shippingToName != '' &&
            shippingAddressLineOne != '' &&
            shippingAddressCity != '' &&
            shippingAddressState != '' &&
            shippingAddressZip != ''
        );
    }, [selectedOrderStatus, contactName, shippingToName, shippingAddressLineOne, shippingAddressCity, shippingAddressState, shippingAddressZip]);

    const handleSubmit = () => {
        const orderToSubmit = isUpdate ? {
            ...existingOrder,
            orderStatus: selectedOrderStatus?.orderStatus,
            enterprise: selectedEnterprise?.enterprise,
            deviceInventoryLocation: selectedLocation?.location,
            lastUpdatedAt: Date.now(),
            contactEmail: contactEmail,
            contactName: contactName,
            shippingToName: shippingToName,
            shippingAttention: shippingAttention,
            shippingPhone: shippingPhone,
            shippingAddressLineOne: shippingAddressLineOne,
            shippingAddressLineTwo: shippingAddressLineTwo,
            shippingAddressCity: shippingAddressCity,
            shippingAddressState: shippingAddressState,
            shippingAddressZip: shippingAddressZip,
            extraValues: extraValues,
            orderItems: items
        } : {
            orderStatus: selectedOrderStatus?.orderStatus,
            enterprise: selectedEnterprise?.enterprise,
            deviceInventoryLocation: selectedLocation?.location,
            createdAt: Date.now(),
            lastUpdatedAt: Date.now(),
            contactEmail: contactEmail,
            contactName: contactName,
            shippingToName: shippingToName,
            shippingAttention: shippingAttention,
            shippingPhone: shippingPhone,
            shippingAddressLineOne: shippingAddressLineOne,
            shippingAddressLineTwo: shippingAddressLineTwo,
            shippingAddressCity: shippingAddressCity,
            shippingAddressState: shippingAddressState,
            shippingAddressZip: shippingAddressZip,
            extraValues: extraValues,
            orderItems: items
        };

        const uploadFiles = orderAttachments.map((attachment) => ({uploadFilename: attachment.uploadFilename, file: attachment.file}));
        orderToSubmit.orderAttachments = orderAttachments.map((attachment) => ({id: attachment.id, reference: attachment.uploadFilename ?? attachment.reference, description: attachment.description}));

        if (isUpdate) {
            OrdersService.updateOrder(orderToSubmit, uploadFiles)
                .then((response) => {
                    onSuccess(response);
                })
                .catch((err) => {
                    console.error(err);
                    onError(err);
                });
        } else {
            OrdersService.addOrder(orderToSubmit, uploadFiles)
                .then((response) => {
                    onSuccess(response);
                })
                .catch((err) => {
                    console.error(err);
                    onError(err);
                });
        }
    };

    const setExtraValueKey = (key, index) => {
        let updatedExtraValues = [...extraValues];
        updatedExtraValues[index].key = key;
        setExtraValues(updatedExtraValues);
    };

    const setExtraValueValue = (value, index) => {
        let updatedExtraValues = [...extraValues];
        updatedExtraValues[index].value = value;
        setExtraValues(updatedExtraValues);
    };

    const addExtraValue = () => {
        let updatedExtraValues = [...extraValues];
        updatedExtraValues.push({key: '', value: ''});
        setExtraValues(updatedExtraValues);
    };

    const removeExtraValue = (index) => {
        let updatedExtraValues = [...extraValues];
        updatedExtraValues.splice(index, 1);
        setExtraValues(updatedExtraValues);
    };

    const setItemQuantity = (quantity, index) => {
        let updatedItems = [...items];
        if (quantity && quantity != '') {
            updatedItems[index].quantity = parseInt(quantity);
        } else {
            updatedItems[index].quantity = 0;
        }
        setItems(updatedItems);
    };

    const setItemSKU = (sku, index) => {
        let updatedItems = [...items];
        updatedItems[index].sku = sku;
        setItems(updatedItems);
    };

    const setItemDescription = (description, index) => {
        let updatedItems = [...items];
        updatedItems[index].description = description;
        setItems(updatedItems);
    };

    const addItem = () => {
        let updatedItems = [...items];
        updatedItems.push({ quantity: 0, description: "", sku: "", deviceTypes: [] });
        setItems(updatedItems);
    };

    const removeItem = (index) => {
        let updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
    };

    const filterDeviceTypes = (itemDeviceTypes, allOptions) => {
        const currentlySelectedTypeTokens = itemDeviceTypes.map((deviceType) => deviceType.token);
        const enterpriseAvailableTypeTokens = selectedEnterprise?.enterprise ? selectedEnterprise.enterprise.availableDeviceTypes.map((deviceType) => deviceType.token) : [];

        return allOptions
                    .filter((option) => !currentlySelectedTypeTokens.includes(option.deviceType.token))
                    .filter((option) => enterpriseAvailableTypeTokens.length === 0 || enterpriseAvailableTypeTokens.includes(option.deviceType.token));
    };

    const removeItemDeviceType = (index, deviceTypeToken) => {
        let updatedItems = [...items];
        updatedItems[index].deviceTypes = updatedItems[index].deviceTypes.filter((deviceType) => deviceType.token !== deviceTypeToken);
        setItems(updatedItems);
    };

    const addItemDeviceType = (index, deviceTypeToken) => {
        if (deviceTypeToken) {
            const selectedOption = deviceTypeOptions.find((option) => option.deviceType.token === deviceTypeToken);
            let updatedItems = [...items];
            updatedItems[index].deviceTypes = [...updatedItems[index].deviceTypes];
            updatedItems[index].deviceTypes.push(selectedOption.deviceType);
            if (updatedItems[index].description == null || updatedItems[index].description == "") {
                updatedItems[index].description = selectedOption.deviceType.name;
            }
            setItems(updatedItems);
        }
    };

    const setOrderAttachmentDescription = (description, index) => {
        let updatedOrderAttachments = [...orderAttachments];
        updatedOrderAttachments[index].description = description;
        setOrderAttachments(updatedOrderAttachments);
    };

    const setOrderAttachmentFile = (file, index) => {
        let updatedOrderAttachments = [...orderAttachments];
        updatedOrderAttachments[index].file = file;
        setOrderAttachments(updatedOrderAttachments);
    };

    const addOrderAttachment = () => {
        let updatedOrderAttachments = [...orderAttachments];
        updatedOrderAttachments.push({description: "", uploadFilename: Math.random().toString(36).substring(2, 5), file: null});
        setOrderAttachments(updatedOrderAttachments);
    };

    const removeOrderAttachment = (index) => {
        let updatedOrderAttachments = [...orderAttachments];
        updatedOrderAttachments.splice(index, 1);
        setOrderAttachments(updatedOrderAttachments);
    };

    const downloadOrderAttachment = (index) => {
        OrdersService.getOrderAtachmentLink(orderAttachments[index].reference)
            .then(({url}) => {
                window.open(url, "_blank");
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return loading ? (<div className="loader">Loading...</div>) : (
        <div>
            <div className="row">
                <div className="col-sm-12 col-md-2 col-lg-2">
                    <label>Status (Required)</label>
                    <Select
                          options={orderStatusOptions}
                          value={selectedOrderStatus}
                          onChange={setSelectedOrderStatus}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder={i18next.t('select')}
                      />
                </div>

                <div className="col-sm-12 col-md-5 col-lg-5">
                    <label>Customer</label>
                    <Select
                          options={enterpriseOptions}
                          value={selectedEnterprise}
                          onChange={setSelectedEnterprise}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder={i18next.t('select')}
                      />
                </div>

                <div className="col-sm-12 col-md-5 col-lg-5">
                    <label>Fulfillment By</label>
                    <Select
                          options={locationOptions}
                          value={selectedLocation}
                          onChange={setSelectedLocation}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder={i18next.t('select')}
                      />
                </div>
            </div>

            <div className="row" style={{paddingTop:20}}>
                <h3 style={{paddingLeft:10}}>Contact</h3>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <label>Name (Required)</label>
                    <input className="form-control" name="contactName" type="text" value={contactName} onChange={({target}) => setContactName(target.value)} />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <label>Email</label>
                    <input className="form-control" name="contactEmail" type="text" value={contactEmail} onChange={({target}) => setContactEmail(target.value)} />
                </div>
            </div>

            <div className="row form-group" style={{paddingTop:20}}>
                <h3 style={{paddingLeft:10}}>Ship To</h3>

                <div className="col-sm-12 col-md-4 col-lg-4">
                    <label>Name (Required)</label>
                    <input className="form-control" name="shippingToName" type="text" value={shippingToName} onChange={({target}) => setShippingToName(target.value)} />
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4">
                    <label>Attention</label>
                    <input className="form-control" name="shippingAttention" type="text" value={shippingAttention} onChange={({target}) => setShippingAttention(target.value)} />
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4">
                    <label>Phone</label>
                    <input className="form-control" name="shippingPhone" type="text" value={shippingPhone} onChange={({target}) => setShippingPhone(target.value)} minLength="1" maxLength="16" />
                </div>
                
                <div className="col-sm-12 col-md-12 col-lg-12" style={{paddingTop:10}}>
                    <label>Address Line One (Required)</label>
                    <input className="form-control" name="shippingAddressLineOne" type="text" value={shippingAddressLineOne} onChange={({target}) => setShippingAddressLineOne(target.value)} />
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12" style={{paddingTop:10}}>
                    <label>Address Line Two</label>
                    <input className="form-control" name="shippingAddressLineTwo" type="text" value={shippingAddressLineTwo} onChange={({target}) => setShippingAddressLineTwo(target.value)} />
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4" style={{paddingTop:10}}>
                    <label>City (Required)</label>
                    <input className="form-control" name="shippingAddressCity" type="text" value={shippingAddressCity} onChange={({target}) => setShippingAddressCity(target.value)} minLength="1" maxLength="32" />
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4" style={{paddingTop:10}}>
                    <label>State (Required)</label>
                    <input className="form-control" name="shippingAddressState" type="text" value={shippingAddressState} onChange={({target}) => setShippingAddressState(target.value)} minLength="2" maxLength="2" />
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4" style={{paddingTop:10}}>
                    <label>Zip (Required)</label>
                    <input className="form-control" name="shippingAddressZip" type="text" value={shippingAddressZip} onChange={({target}) => setShippingAddressZip(target.value)}  minLength="1" maxLength="16" />
                </div>
            </div>

            <div className="row" style={{paddingTop:20, marginLeft:5, marginRight: 30}}>
                <h3>Field Values</h3>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <td>Field</td>
                            <td>Value</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                    { extraValues.length == 0 && <tr><td colSpan={3}>No values</td></tr> }
                    {
                        extraValues.map((extraValue, index) => (
                            <tr key={index}>
                                <td>
                                    <input className="form-control" type="text" value={extraValue.key} onChange={({target}) => setExtraValueKey(target.value, index)} />
                                </td>
                                <td>
                                    <input className="form-control" type="text" value={extraValue.value} onChange={({target}) => setExtraValueValue(target.value, index)} />
                                </td>
                                <td>
                                    <button onClick={() => removeExtraValue(index)} className="btn btn-sm btn-danger">Remove</button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <div className="col-sm-2 col-md-2 col-lg-2" style={{paddingTop:10}}>
                    <button onClick={addExtraValue} className="btn btn-sm btn-primary">Add Field</button>
                </div>
            </div>

            <div className="row" style={{paddingTop:20, marginLeft:5, marginRight: 30}}>
                <h3>Ordered Items</h3>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <td style={{width:"5%"}}>Quantity</td>
                            <td style={{width:"15%"}}>SKU</td>
                            <td style={{width:"45%"}}>Description</td>
                            <td style={{width:"30%"}}>Device Types</td>
                            <td style={{width:"5%"}}></td>
                        </tr>
                    </thead>
                    <tbody>
                    { items.length == 0 && <tr><td colSpan={5}>No values</td></tr> }
                    {
                        items.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <input className="form-control" type="text" value={item.quantity} onChange={({target}) => setItemQuantity(target.value, index)} />
                                </td>
                                <td>
                                    <input className="form-control" type="text" value={item.sku} onChange={({target}) => setItemSKU(target.value, index)} />
                                </td>
                                <td>
                                    <input className="form-control" type="text" value={item.description} onChange={({target}) => setItemDescription(target.value, index)} />
                                </td>
                                <td>
                                    <table className="table">
                                        <tbody>
                                        {
                                            item.deviceTypes.map((deviceType) => (
                                                <tr key={deviceType.id}>
                                                    <td style={{width:"90%", paddingRight:5, paddingBottom:5}}>
                                                        {deviceType.name}
                                                    </td>
                                                    <td>
                                                        <button onClick={() => removeItemDeviceType(index, deviceType.token)} className="btn btn-sm btn-danger pull-right">X</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                            <tr>
                                                <td style={{width:"90%", paddingRight:5, paddingBottom:5}}>
                                                    <Select
                                                        options={filterDeviceTypes(item.deviceTypes, deviceTypeOptions)}
                                                        value={selectedDeviceType}
                                                        onChange={setSelectedDeviceType}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        placeholder={i18next.t('select')}
                                                    />
                                                </td>
                                                <td>
                                                    <button onClick={() => addItemDeviceType(index, selectedDeviceType?.deviceType.token)} className="btn btn-sm btn-danger pull-right">+</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <button onClick={() => removeItem(index)} className="btn btn-sm btn-danger">Remove</button>
                                </td>                            
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <div className="col-sm-2 col-md-2 col-lg-2" style={{paddingTop:10}}>
                    <button onClick={addItem} className="btn btn-sm btn-primary">Add Item</button>
                </div>                
            </div>

            <div className="row" style={{paddingTop:20, marginLeft:5, marginRight: 30}}>
                <h3>Attachments</h3>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <td>File</td>
                            <td>Description</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                    { orderAttachments.length == 0 && <tr><td colSpan={3}>No attachments</td></tr> }
                    {
                        orderAttachments.map((attachment, index) => (
                            <tr key={index}>
                                <td>
                                    {
                                        attachment.reference ?
                                            <span><button onClick={() => downloadOrderAttachment(index)} className="btn btn-sm btn-link">Download</button></span> :
                                            <input className="form-control"  type="file" onChange={({target}) => setOrderAttachmentFile(target.files[0], index)} />
                                    }
                                </td>
                                <td>
                                    <input className="form-control" type="text" value={attachment.description} onChange={({target}) => setOrderAttachmentDescription(target.value, index)} />
                                </td>
                                <td>
                                    <button onClick={() => removeOrderAttachment(index)} className="btn btn-sm btn-danger">Remove</button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <div className="col-sm-2 col-md-2 col-lg-2" style={{paddingTop:10}}>
                    <button onClick={addOrderAttachment} className="btn btn-sm btn-primary">Add Attachment</button>
                </div>
            </div>

            <div className="row" style={{paddingTop:20}}>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <ul className="list-inline clearfix">
                        <li className="pull-right">
                            <button disabled={!canSubmit} onClick={handleSubmit} className="btn btn-primary btn-block">
                                {isUpdate ? "Save" : "Add"}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default OrderForm;