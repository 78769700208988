import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class ReportConfigsService {

  static getReportConfig(enterpriseId, reportName) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}reports/config/${enterpriseId}/${reportName}`).then(response => {
        resolve({ config: response.data });
      }).catch(err => {
        reject(err);
      });
    });
  }

  static addReportConfig(reportConfig) {
    return new Promise((resolve, reject) => {
        axios.post(`${ROOT}reports/config`, reportConfig).then(response => {
          resolve({ config: response.data });
        }).catch(err => {
          reject(err);
        });
      });
  }

  static deleteReportConfig(reportConfigId) {
    return new Promise((resolve, reject) => {
        axios.delete(`${ROOT}reports/config/${reportConfigId}`).then(response => {
          resolve({ config: response.data });
        }).catch(err => {
          reject(err);
        });
      });
  }

}

export default ReportConfigsService;