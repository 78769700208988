import React, { useEffect, useState } from 'react';
import { OrdersService } from '../../services';

const ShipToArea = ({order}) => (
  <div className='row' style={{paddingTop: 20}}>
    <div className='col-xs-12 col-sm-12 col-md-12'>
      <b>Ship To</b>
      <p>
        {order.shippingToName} <br/>
        {order.shippingAttention} {order.shippingAttention &&<br/>}
        {order.shippingAddressLineOne} <br/>
        {order.shippingAddressLineTwo} {order.shippingAddressLineTwo && <br/>}
        {order.shippingAddressCity}, {order.shippingAddressState} {order.shippingAddressZip}
      </p>
    </div>
  </div>
);

const ExtraInfoArea = ({extraInfo}) => {
  const values = [...extraInfo];
  if (values.length % 2 == 1) {
    values.splice(1, 0, {key: "", value: ""});
  }
  const valuePairs = [];
  for(var i=0; i<values.length; i+=2) {
    valuePairs.push({a: values[i], b: values[i+1]});
  }

  return (
    <div className='row' style={{paddingTop: 20}}>
      <div className='col-xs-12 col-sm-12 col-md-12'>
        <table style={{width:"100%", borderSpacing:10}}>
          <tbody>
            {valuePairs.map((valuePair, index) => (
              <tr key={index}>
                <td style={{padding:5, border:"1px #eee solid", width: "10%"}}>{valuePair.a.key}</td>
                <td style={{padding:5, border:"1px #eee solid", width: "40%"}}>{valuePair.a.value}</td>
                <td style={{padding:5, border:"1px #eee solid", width: "10%"}}>{valuePair.b.key}</td>
                <td style={{padding:5, border:"1px #eee solid", width: "40%"}}>{valuePair.b.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ItemArea = ({items}) => {
  const hasSKUs = items.find((item) => (item.sku != null && item.sku != ''));
  
  return (
    <div className='row' style={{paddingTop: 20}}>
      <div className='col-xs-12 col-sm-12 col-md-12'>
        <table style={{width:"100%"}}>
          <thead style={{backgroundColor: "#aaa"}}>
            <tr>
              <th style={{padding:5, border:"1px #000 solid", width: "10%"}}>Quantity</th>
              {hasSKUs && <th style={{padding:5, border:"1px #000 solid", width: "10%"}}>SKU</th>}
              <th style={{padding:5, border:"1px #000 solid", width: "80%"}}>Item</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td style={{padding:5, border:"1px #000 solid"}}>{item.quantity}</td>
                {hasSKUs && <td style={{padding:5, border:"1px #000 solid"}}>{item.sku}</td>}
                <td style={{padding:5, border:"1px #000 solid"}}>{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PackingSlip = ({ match }) => {
  const [displayData, setDisplayData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    OrdersService.getOrder(match?.params?.orderId)
      .then(({order}) => {
        setDisplayData(order);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setErrorMessage("Error loading order");
        setLoading(false);
      });
  }, [match]);

  return loading ? (<div className='loading'>Loading...</div>) : (
    <div style={{padding:50}}> 
      {errorMessage && <span>{errorMessage}</span>}
      {displayData && 
        <div>
          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6">
              <img src='/images/opsense-packing-slip-header.png' style={{height:55}} /> <br/>
              <p style={{paddingTop:10}}>
                1-540-274-5334 <br/>
                www.opsense.com <br/>
                console.opsense.com
              </p>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6" style={{textAlign:"right"}}>
              <h2>Packing Slip</h2>
              <h4>#{displayData.displayNumber}</h4>
            </div>
          </div>

          <ShipToArea order={displayData} />

          {displayData.extraValues && <ExtraInfoArea extraInfo={displayData.extraValues} />}

          {displayData.orderItems && <ItemArea items={displayData.orderItems} />}
        </div>
      }
    </div>
  );
};

export default PackingSlip;
