import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';

import SchedulerErrors from './scheduler_errors';
import ScheduledJobs from './scheduled_jobs';
import { SchedulerService, TimeFormattingService } from '../../services';
import { PageTitle } from '../PageTitle';
import { Message } from '../Message';
import { AccessHelper } from '../../helpers';

@connect(state => {
    return {
      me: state.user.me
    }
  })
class Scheduler extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: null,
            jobs: null,
            localErrorMessage: null,
            jobsUnavailable: false,
            errorsUnavailable: false,
            errorsAllowed: AccessHelper.userHasAccess(this.props.me.availableRoutes, '/admin-admin/scheduler#errors')
        }
    }    

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setState({errorsUnavailable: false, errors: null, jobsUnavailable: false, jobs: null, localErrorMessage: null});

        if (this.state.errorsAllowed) {
            SchedulerService.getSchedulerErrors().then(response => {
                this.setState({errors: response.errors});
            }).catch(err => {
                this.setState({errorsUnavailable: true});
                console.error(err);
            });
        }

        SchedulerService.getSchedulerJobs().then(response => {
            this.setState({jobs: response.jobs});
        }).catch(err => {
            this.setState({jobsUnavailable: true});
            console.error(err);
        });
    }

    messageDismissCallback() {
        this.setState({ localSuccessMessage: null, localErrorMessage: null });
    }
    
    renderMessages() {
        if (this.state.localErrorMessage) {
            return (<Message errorMessage={this.state.localErrorMessage} messageDismissCallback={this.messageDismissCallback.bind(this)} />);
        } 
        return null;
    }

    renderNormalTime(data) {
        if (data.value === -1) {
            return <span>N/A</span>
        }

        let date = TimeFormattingService.renderDate(data.value, 'EST5EDT');
        let time = TimeFormattingService.renderTime(data.value, 'EST5EDT');
        return <span>{date}<br/>{time}</span>;
    }

    renderCallerClass(data) {
        let clazz = data.value.substring(data.value.lastIndexOf(".") + 1, data.value.length);
        return <span title={data.value}>{clazz}</span>;
    }

    render() {
        return (<div>
            <PageTitle title={i18next.t('pages.scheduler.title')} refreshFunction={this.refresh.bind(this)}/>
            {this.renderMessages()}
            {this.state.errorsAllowed &&
                <div className="op-content-box">
                    <h3>{i18next.t('pages.scheduler.errors')}</h3>
                    {!this.state.errors && !this.state.errorsUnavailable ?
                        <div className="loader">{i18next.t('loading')}</div>
                    : this.state.errorsUnavailable ? 
                        <div>{i18next.t('pages.scheduler.unable-to-retrieve-errors')}</div>
                    : 
                    <div className="card-block">
                        <SchedulerErrors errors={this.state.errors} renderNormalTime={this.renderNormalTime}/>
                    </div>}
                </div>
            }
            <div className="op-content-box">
            <h3>{i18next.t('pages.scheduler.job-status')}</h3>
                {!this.state.jobs && !this.state.jobsUnavailable ?
                    <div className="loader">{i18next.t('loading')}</div>
                : this.state.jobsUnavailable ? 
                <div>{i18next.t('pages.scheduler.unable-to-retrieve-status')}</div>
                : <div className="card-bloxk">
                    <ScheduledJobs jobs={this.state.jobs} renderNormalTime={this.renderNormalTime}/>
                </div>}
            </div>
        </div>);
    }
}

export default Scheduler;