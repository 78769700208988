import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class UsersService {

  static getMe() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}users/me`).then((data) => {
        resolve({ me: data.data, response: data.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static saveMe(user) {
    return new Promise((resolve, reject) => {
      axios.patch(`${ROOT}users/me`, user).then((data) => {
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getAll(options=[], isSearch=false) {
    return new Promise((resolve, reject) => {
      let params = options.length == 0 ? '' : '?' + options.map(option => Object.keys(option).map((k, i) => `${k}=${option[k]}`)).join('&');
      let searchPart = isSearch ? '/search/findBySearch' : '';
      axios.get(`${ROOT}users${searchPart}${params}`).then((data) => {
        if (data.data._embedded) {
          resolve({ users: data.data._embedded.users, page: data.data.page, response: data });
        } else {
          resolve({ users: data.data.content, page: data.data, response: data });
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static get(userId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}users/${userId}`).then((data) => {
        resolve({ user: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static save(userId, userData) {
    return new Promise((resolve, reject) => {
      axios.patch(`${ROOT}users/${userId}`, userData).then((data) => {
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static add(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${ROOT}users`, user).then((data) => {
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static archive(userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${ROOT}users/${userId}`).then((data) => {
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getMyNotificationPreferences() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}users/me/notification_preferences`).then((data) => {
        resolve({ notificationPreferences: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static saveMyNotificationPreferences(notificationPreferences) {
    return new Promise((resolve, reject) => {
      axios.patch(`${ROOT}users/me/notification_preferences`, notificationPreferences).then((data) => {
        resolve({ notificationPreferences: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static deleteMyNotificationPreference(notificationPreferenceId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${ROOT}users/me/notification_preferences/${notificationPreferenceId}`).then((data) => {
        resolve({ notificationPreferences: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static testMyNotificationPreference(notificationPreferenceId) {
    return new Promise((resolve, reject) => {
      axios.post(`${ROOT}users/me/notification_preferences/${notificationPreferenceId}/test`, null).then((data) => {
        resolve({ notificationPreferences: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static testNotificationPreferences(notificationPreferences) {
    return new Promise((resolve, reject) => {
      axios.post(`${ROOT}users/me/notification_preferences/test`, notificationPreferences).then((data) => {
        resolve({ notificationPreferences: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }
}

export default UsersService;
