import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class SchedulerService {

  static getSchedulerJobs() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}scheduling/jobs`).then(response => {
        resolve({ jobs: response.data });
      }).catch(err => {
        reject(err);
      });
    });
  }

  static getSchedulerErrors() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}scheduling/errors`).then(response => {
        resolve({ errors: response.data });
      }).catch(err => {
        reject(err);
      });
    });
  }

}

export default SchedulerService;