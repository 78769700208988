import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import moment from 'moment-timezone';
import i18next from "i18next";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

const DatePickerInput = (props) => {
  const format = 'L';
  const locale = i18next.language;
  moment.locale(locale);

  let dayPickerProps = props.dayPickerProps ? props.dayPickerProps : {};
  dayPickerProps.locale = locale;
  dayPickerProps.localeUtils = MomentLocaleUtils;

  return (
      <div className={`adjust-z-index ${props.meta.touched && props.meta.error && " has-error"}`}>
        <DayPickerInput
          {...props}
          inputProps={{onBlur: props.input.onBlur, disabled : props.isDisabled}}
          formatDate={formatDate}
          dayPickerProps={dayPickerProps}
          parseDate={parseDate}
          value = {props.input.value}
          placeholder={moment.localeData().longDateFormat(format)}
          format={format}
          onDayChange={props.onDayChange}
        />
        {props.clearDate &&
          <FontAwesomeIcon className = "date-clear" onClick = {props.clearDate} icon={faTimes} />
        }
        {props.meta.touched && props.meta.error && <span className="input-error">{props.meta.error}</span>}
      </div>
  );
};

export default DatePickerInput;
