import React from 'react';

import { ContentService } from '../../services';

class VideoEmbed extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            signedURL: null
        }
    }

    componentDidMount() {
        ContentService.getSecureStaticResource(this.props.attrs.href).then(response => {
            this.setState({signedURL: response.signedURL});
        }).catch(err => {
            console.error(err);
        })
    }

    render() {
        if (this.state.signedURL) {
            return (
                <video width="520" controls>
                    <source src={this.state.signedURL} type="video/mp4"/>
                </video>
            )
        } else {
            return (
                <div></div>
            )
        }

    }
}

export default VideoEmbed;