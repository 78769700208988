import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class CommissioningService {
  static listFleets(options=[]) {
    return new Promise((resolve, reject) => {
      let params = options.length == 0 ? '' : '?' + options.map(option => Object.keys(option).map((k, i) => `${k}=${option[k]}`)).join('&');
      axios.get(`${ROOT}commissioning/fleets${params}`).then((data) => {
        resolve({ fleets: data.data.content, page: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getFleetDetail(fleetId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}commissioning/fleets/${fleetId}`).then((data) => {
        resolve({ fleet: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getFeeltDeviceDetail(commissionedDeviceReferenceId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}commissioning/fleets/device/${commissionedDeviceReferenceId}`).then((data) => {
        resolve({ commissionedDevice: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getAvailableInventory() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}commissioning/available-inventory`).then((data) => {
        resolve({ inventory: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static createFleet(fleet) {
    return new Promise((resolve, reject) => {
      axios.put(`${ROOT}commissioning/fleets`, fleet).then((data) => {
        resolve({ fleet: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static updateFleet(fleet) {
    return new Promise((resolve, reject) => {
      axios.post(`${ROOT}commissioning/fleets`, fleet).then((data) => {
        resolve({ fleet: data.data.fleet, errors: data.data.errors, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static decommissionDevice(commissionedDeviceReferenceId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}commissioning/decommission/${commissionedDeviceReferenceId}`).then((data) => {
        resolve({ data: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static reinventoryDevice(commissionedDeviceReferenceId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}commissioning/reinventory/${commissionedDeviceReferenceId}`).then((data) => {
        resolve({ data: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static deleteDevice(commissionedDeviceReferenceId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${ROOT}commissioning/${commissionedDeviceReferenceId}`).then((data) => {
        resolve({ response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }


  static deviceSearch(serialNumber) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}commissioning/device-search?serialNumber=${serialNumber}`).then((data) => {
        resolve({ response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }
}

export default CommissioningService;
