import React from 'react';


class PasswordInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {hidePassword: true}
  }

  render() {
      const {input, label, meta: { touched, error, warning, invalid } } = this.props;

    const displayType = this.state.hidePassword ? "password" : "text";
    const message = this.state.hidePassword ? "Show password" : "Hide password";

    let inputType = <input {...input} placeholder={label} type={displayType} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} autoComplete="no"/>;

    return (
      <div className={"form-group " + (touched && error  ? 'has-error' : '')}>
        <div>
          <label>{label}</label>
        </div>

        <div>
          {inputType}
          {touched && ((error && <span className="input-error">{error}</span>) || (warning && <span className="input-warning">{warning}</span>))}
        </div>

        <div style={{marginTop: "5px"}}>
            <label><a style={{cursor: "pointer"}} onClick={() => this.setState({hidePassword: !this.state.hidePassword})}>{message}</a></label>
        </div>
      </div>
    );
  } 
} 

export default PasswordInput;
