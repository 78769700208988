import React from 'react';
import { reduxForm, Field } from 'redux-form';

import { Link } from 'react-router-dom';

const required = value => (value == null ? 'Required' : undefined)

class EnterpriseEditForm extends React.Component {

  constructor(props) {
    super(props);

    const initData = {
      'name': props.enterprise.name
    };
    this.props.initialize(initData);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return <div className="alert alert-danger">
        <strong>Error: </strong>{this.props.errorMessage}
      </div>
    }
  }

  render() {
    const {handleSubmit} = this.props

    return (
      <div className="login-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
           <label>Enterprise Name</label>
            <Field
              type="text"
              className="form-control"
              name="name"
              component="input"
              validate={required}
              ref="name"
              withRef
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block">Save</button>
          <p/>
          <Link to="/admin-admin/enterprises"><button className="btn btn-primary btn-block">Cancel</button></Link>
        </form>
      </div>
    );
  }
}

export default reduxForm({ form: 'EnterpriseEditForm' })(EnterpriseEditForm);
