import axios from 'axios';
import config from 'config';

const ROOT = config.IMAGE_BASE_URL;

class ContentService {

    static getMyEnterpriseLogo() {
        return this.call(`${ROOT}enterprises/logos?ts=${Date.now()}`); 
    }

    static getEnterpriseLogo(token) {
        return this.call(`${ROOT}enterprises/logos/${token}.png`);
    }

    static getDeviceImage(deviceImageUrl) {
        return this.call(deviceImageUrl);
    }

    static getDeviceGroupingImage(groupingId, imageName) {
        return this.call(`${ROOT}groupings/${groupingId}/images/${imageName}`);
    }

    static getSecureStaticResource(url) {
        return new Promise((resolve, reject) => {
            axios.get(url).then(response => {
                resolve({signedURL: response.data});
            }).catch(err => {
                reject(err);
            })
        })
    }

    static call(uri) {
        return new Promise((resolve, reject) => {
            axios.get(uri).then(response => {
                resolve(response.data);
            }).catch(err => {
                console.error(err);
                resolve('images/icon/no_image_icon.svg');
            });
        });
    }
}

export default ContentService;
