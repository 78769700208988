import React from 'react';
import { connect } from 'react-redux';
import { AccessHelper } from '../../helpers';

@connect(state => {
    return {
      me: state.user.me
    }
  })
class PageLevelAccess extends React.Component {

    render() {
        let userHasAccess = AccessHelper.userHasAccess(this.props.me.availableRoutes, this.props.to);
        return userHasAccess ? (<React.Fragment>{this.props.children}</React.Fragment>) : (null);
    }
}

export default PageLevelAccess;