import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import EnterpriseWizardGeneralForm from './enterprise_wizard_general_form';
import EnterpriseWizardBusinessForm from './enterprise_wizard_business_form';
import EnterpriseWizardGroupingForm from "./enterprise_wizard_grouping_form";
import EnterpriseWizardAccessForm from './enterprise_wizard_access_form';
import {findGroupByUUID} from './enterprise_wizard_helpers';
import { EnterprisesService } from '../../services';

const enterpriseShellData = {
    name: '',
    rootGroup: null,
    rootGrouping: null,
    adminUser: {
        firstName: 'OpSense',
        lastName: 'Admin',
        username: null
    },
    adminPassword: null
};

const rootShellData = {
    name: 'Corporation',
    displayOrder: 0, 
    isRoot: true,
    uuid: uuidv4(),
    requiresTimezone: false,
    enableFloorPlan: false,
    groupAssociableMetadataTypes: [],
    parent: null,
    children: []
};

const subnodeShellData = {
    name: 'Location',
    displayOrder: 0, 
    isRoot: false,
    uuid: uuidv4(),
    requiresTimezone: true,
    enableFloorPlan: true,
    groupAssociableMetadataTypes: [{id: 1, token: 'floor_plan_location'}],
    parent: null,
    children: []
};

const EnterpriseWizardAddForm = ({onSuccess, onError}) => {
    const [enterprise, setEnterprise] = useState({...enterpriseShellData});
    const [page, setPage] = useState(1);
    const [saving, setSaving] = useState(false);
    const [currentBusinessStructureDefault, setCurrentBusinessStructureDefault] = useState('grocery_default');
    
    const prevPage = (dec=1) => setPage(page - dec);

    const nextPage = (inc=1) => setPage(page + inc);

    const handleSubmit = ({firstName, lastName, username, password}) => {
        let enterpriseRequest = {
            name: enterprise.name,
            rootGroup: enterprise.rootGroup,
            rootGrouping: enterprise.rootGrouping,
            adminUser: {
                firstName: firstName,
                lastName: lastName,
                username: username,
            }, 
            adminPassword: password
        };

        setSaving(true);

        EnterprisesService.add(enterpriseRequest).then(response => {
            setSaving(false);
            onSuccess('Enterprise added');
        }).catch(err => {
            setSaving(false);
            console.error(err);
            onError('Unable to add enterprise, check console for errors');
        });
    };

    const updateGeneral = (name, businessStructureDefault) => {
        var updatedEnterprise = { ...enterprise };

        setCurrentBusinessStructureDefault(businessStructureDefault);

        updatedEnterprise.name = name.trim();

        if (businessStructureDefault == 'grocery_default') {
            updatedEnterprise.rootGroup = { ...rootShellData, children: [{ ...subnodeShellData, name: 'Location', parent: rootShellData }] };
        } else if (businessStructureDefault == 'qsr_default') {
            updatedEnterprise.rootGroup = { ...rootShellData, children: [{ ...subnodeShellData, name: 'Store', parent: rootShellData }] };
        } else {
            updatedEnterprise.rootGroup = null;
        }

        setEnterprise(updatedEnterprise);

        nextPage(businessStructureDefault === 'bs_custom' ? 1 : 2);
    }; 

    const validateGroupingsAgainstGroups = (currentGrouping, rootGroup) => {
        for (const child of currentGrouping.children) {
            if (!validateGroupingsAgainstGroups(child, rootGroup)) {
                return false;
            }
        }

        return findGroupByUUID(rootGroup, currentGrouping.deviceGroup.uuid) != null;
    };

    const updateRootGroup = (rootGroup) => {
        setEnterprise({ ...enterprise, rootGroup: rootGroup });
        if (enterprise.rootGrouping != null) {
            if (!validateGroupingsAgainstGroups(enterprise.rootGrouping, enterprise.rootGroup)) {            
                setEnterprise({ ...enterprise, rootGrouping: null });
            }
        }
    };

    const updateRootGrouping = (rootGrouping) => {
        setEnterprise({ ...enterprise, rootGrouping: rootGrouping });
    };

    return (
        <div className="row">
            {saving && <div className="loader">Saving...</div>}

            <div className="col-sm-12">
                { page === 1 && <EnterpriseWizardGeneralForm customerName={enterprise.name} onGeneralUpdate={updateGeneral} currentBusinessStructureDefault={currentBusinessStructureDefault} onError={onError} /> } 
                { page === 2 && <EnterpriseWizardBusinessForm rootGroup={enterprise.rootGroup} rootGrouping={enterprise.rootGrouping} onGroupsUpdate={updateRootGroup} goPrevious={prevPage} onSubmit={nextPage} onError={onError} /> }
                { page === 3 && <EnterpriseWizardGroupingForm enterpriseName={enterprise.name} rootGroup={enterprise.rootGroup} currentRootGrouping={enterprise.rootGrouping} onGroupingsUpdate={updateRootGrouping} goPrevious={prevPage} onSubmit={nextPage} onError={onError} /> }
                { page === 4 && <EnterpriseWizardAccessForm goPrevious={prevPage} onSubmit={handleSubmit} onError={onError} /> }
            </div>
        </div>
    );    
}

export default EnterpriseWizardAddForm;