import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class InventoryService {
    static getSupportedDeviceTypes() {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/inventory/supported-device-types`).then((data) => {
                resolve({ deviceTypes: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getTemplateForDeviceType(deviceTypeToken) {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/inventory/templates/${deviceTypeToken}`).then((data) => {
                resolve({ template: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getLocations() {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/inventory/locations`).then((data) => {
                resolve({ locations: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }
    
    static getVendorOrders() {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/inventory/vendor-order-data`).then((data) => {
                resolve({ vendorOrders: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getInventory(options=[]) {
        return new Promise((resolve, reject) => {
            let params = options.length == 0 ? "" : "?" + options.map((option) => Object.keys(option).map((k, i) => `${k}=${option[k]}`)).join("&");
            axios.get(`${ROOT}fulfillment/inventory${params}`).then((data) => {
                resolve({ inventory: data.data.content, page: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static addInventory(formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${ROOT}fulfillment/inventory`, formData, { headers: {'content-type': 'multipart/form-data'} }).then((data ) => {
                resolve({ inventory: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    /*
    static getOrder(orderId) {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/orders/${orderId}`).then((data) => {
                resolve({ order: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }
    
    static updateOrder(order) {
        return new Promise((resolve, reject) => {
            axios.patch(`${ROOT}fulfillment/orders/${order.id}`, order).then((data ) => {
                resolve({ order: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }*/
}

export default InventoryService;
