import React from 'react';
import Editor from 'rich-markdown-editor';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faVideo from '@fortawesome/fontawesome-free-solid/faVideo';

import VideoEmbed from '../MarkdownEditor/video_embed';
import { DocumentsService } from '../../services';

const MarkdownEditor = (props) => {
  return (
      <div>
        <Editor
            readOnly={props.readOnly}
            placeholder='Add content here'
            defaultValue={props.content}
            onChange={value => props.onChange(value)}
            uploadImage={async file => {
                const result = await DocumentsService.uploadImage(file);
                return result.imageURI;
            }}
            embeds={[
                {
                    title: "Video",
                    icon: () => (<FontAwesomeIcon icon={faVideo}/>),
                    matcher: url => {
                        return url.match(/(http|https):\/\/(.*)(ssr\/general)\/(.*)/i)
                    },
                    component: VideoEmbed
                }
            ]}
        />
      </div>);
  }

export default MarkdownEditor;