import React from 'react';
import SigninForm from './signin_form';
import * as actions from '../../actions/auth_actions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

@connect(state => {
  return {
    authenticated: state.auth.authenticated,
    errorMessage: state.auth.error
  }
}, actions)
class Signin extends React.Component {

  componentWillUnmount() {
    if (this.props.errorMessage) {
      this.props.authError(null);
    }
  }

  displayRedirectMessages() {
    const location = this.props.location;
    return location.state && <div className="alert alert-danger">{ location.state.message }</div>;
  }

  handleSubmit({ username, password }) {
    this.props.signinUser({ username, password });
  }

  getRedirectPath() {
    const locationState = this.props.location.state;
    if (locationState && locationState.from.pathname) {
      return locationState.from.pathname; // redirects to referring url
    } else {
      return '/monitoring/locations';
    }
  }

  renderRedirect() {
    return (
      <Redirect to={{
        pathname: this.getRedirectPath(),
        state: {
          from: this.props.location
        }
      }}/>
    );
  }

  renderLogin() {
    return (
      <div>
        {this.displayRedirectMessages()}
        <SigninForm onSubmit={ this.handleSubmit.bind(this) } errorMessage={ this.props.errorMessage }/>
      </div>
    );
  }

  render() {
    return (this.props.authenticated) ? this.renderRedirect() : this.renderLogin();
  }
}

export default Signin;
