import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class LocalesService {
    static getAll() {
        return new Promise((resolve, reject) => {
          axios.get(`${ROOT}locales`).then((response) => {
            resolve({ locales: response.data });
          }).catch((err) => {
            reject(err);
          });
        });
      }

      static getByLanguageTag(tag) {
        return new Promise((resolve, reject) => {
          axios.get(`${ROOT}locales/${tag}`).then((response) => {
            resolve({ locale: response.data });
          }).catch((err) => {
            reject(err);
          });
        });
      }
}

export default LocalesService;