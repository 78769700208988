import React from 'react';

const CheckboxInput = ({ input, label, className, meta: { touched, error, warning, invalid } }) => {
  const inputType = <input {...input} type="checkbox" className={`${className}${touched && invalid ? ' has-danger' : ''}`}/>;
  return (
      <div className={"form-group" + (touched && error  ? ' has-error' : '')} >
        <div>
          <label>{inputType}{label}</label>
          {touched && ((error && <span className="input-error">{error}</span>) || (warning && <span className="input-warning">{warning}</span>))}
        </div>
      </div>
  );
};

export default CheckboxInput;