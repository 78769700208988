import React from 'react';
import ReactTable from 'react-table';
import i18next from 'i18next';

class ScheduledJobs extends React.Component {

    constructor(props) {
        super(props);
    }

    renderJobType(data) {
        if (data.value && data.value === 'ALL_DEVICES_REPORT') {
            return <span>{i18next.t('pages.all-devices-report.header')}</span>
        } else if (data.value && data.value === 'CORRECTIVE_ACTIONS_REPORT') {
            return <span>{i18next.t('pages.corrective-actions-report.header')}</span>
        } else if (data.value) {
            return <span>{data.value}</span>
        } else {
        return <span>{i18next.t('unknown')}</span>
        }
    }

    render() {
        return (
            <div className="react-table-custom">
                <ReactTable
                    className="-striped -highlight"
                    data={this.props.jobs}
                    showPaginationTop
                    columns={[
                        {
                            Header: i18next.t('table-headers.enterprise'),
                            accessor: "enterprise.name",
                            minResizeWidth: 100,
                            width: 200
                        },
                        {
                            Header: i18next.t('table-headers.job-type'),
                            accessor: "jobType",
                            Cell: cell => this.renderJobType(cell),
                            minResizeWidth: 100,
                            width: 200
                        },
                        {
                            Header: i18next.t('table-headers.scheduler-id'),
                            accessor: "jobName",
                            minResizeWidth: 100,
                            width: 350
                        },
                        {
                            Header: i18next.t('table-headers.last-run'),
                            accessor: "prevFireTime",
                            Cell: cell => this.props.renderNormalTime(cell),
                            minResizeWidth: 100
                        },
                        {
                            Header: i18next.t('table-headers.next-run'),
                            accessor: "nextFireTime",
                            Cell: cell => this.props.renderNormalTime(cell),
                            minResizeWidth: 100
                        }

                    ]}
                />
            </div>
            );
    }
}

export default ScheduledJobs;