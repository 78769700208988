import React, { useEffect, useState } from "react";
import Select from "react-select";
import { PageTitle } from "../PageTitle";
import { InventoryService } from "../../services";
import i18next from "i18next";
import InventoryForm from "./inventory_form";
import { Alert } from "react-bootstrap";

const InventoryAdd = () => {
    const [loading, setLoading] = useState(false);
    const [availableDeviceTypes, setAvailableDeviceTypes] = useState([]);
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [availableLocations, setAvailableLocations] = useState([]);
    const [availableVendorOrders, setAvailableVendorOrders] = useState([]);
    const [template, setTemplate] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [preSelectedLocation, setPreSelectedLocation] = useState(null);
    const [preSelectedVendorOrder, setPreSelectedVendorOrder] = useState(null);

    useEffect(() => {
        setLoading(true);

        let requests = [];

        requests.push(InventoryService.getSupportedDeviceTypes());
        requests.push(InventoryService.getLocations());
        requests.push(InventoryService.getVendorOrders());

        Promise.all(requests)
            .then((results) => {
                setLoading(false);
                results.forEach((result) => {
                    if (result.deviceTypes) {
                        setAvailableDeviceTypes(result.deviceTypes.map((deviceType) => ({ value: deviceType.token, label: deviceType.name, deviceType: deviceType })));
                    } else if (result.locations) {
                        setAvailableLocations(result.locations.map((location) => ({ value: location.token, label: location.name, location: location })));
                    } else if (result.vendorOrders) {
                        setAvailableVendorOrders(result.vendorOrders.map((vendorOrder) => ({ value: vendorOrder.id, label: vendorOrder.displayName, vendorOrder: vendorOrder })));
                    }
                })
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
            })

    }, []);

    useEffect(() => {
        if (selectedDeviceType) {
            setTemplate(null);
            setLoading(true);

            InventoryService.getTemplateForDeviceType(selectedDeviceType.value)
                .then(({template}) => {
                    setTemplate(template)
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setErrorMessage(err);
                    setLoading(false);
                });
        }
    }, [selectedDeviceType]);

    const handleSave = (saveValue, uploadFiles, pi) => {
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        const formData = new FormData();
        Object.keys(uploadFiles).forEach((inputToken) => formData.append("uploads", uploadFiles[inputToken]));
        formData.append("deviceInventoryData", new Blob([JSON.stringify(saveValue)], { type: "application/json" }));

        InventoryService.addInventory(formData)
            .then(({ inventory }) => {
                console.log(inventory);

                setSuccessMessage(<span>Inventory record added. <button className="btn btn-sm btn-primary pull-right" onClick={() => setSuccessMessage(null)}>X</button></span>);
                if (pi) {
                    setPreSelectedLocation(pi.selectedLocation);
                    setPreSelectedVendorOrder(pi.selectedVendorOrder);
                }
                setLoading(false);
            })
            .catch((err) => {                
                console.error(err);
                setErrorMessage(`Unable to save device: ${err}`);
                setLoading(false);
            });
    }

    return (
        <div>
            <div className="row">
                <PageTitle title="Inventory Add" />
            </div>
            <div className="inner op-content-box">
                <div className="row">
                    <div className="col-lg-12">
                        { errorMessage && <Alert className="col-sm-12 col-md-10 col-lg-10 alert-danger">{errorMessage}<button className="btn btn-sm btn-primary pull-right" onClick={() => setErrorMessage(null)}>X</button></Alert> }
                        { successMessage && <Alert className="col-sm-12 col-md-10 col-lg-10 alert-success">{successMessage}</Alert> }

                        {loading && <div className="loader">Loading...</div>}

                        {!loading && (
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <label>Device Type</label>
                                    <Select
                                        options={availableDeviceTypes}
                                        value={selectedDeviceType}
                                        onChange={setSelectedDeviceType}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder={i18next.t("select")}
                                    />
                                </div>
                            </div>
                        )}
                        {!loading && selectedDeviceType && template &&
                            <InventoryForm 
                                template={template} 
                                locations={availableLocations} 
                                preSelectedLocation={preSelectedLocation}
                                vendorOrders={availableVendorOrders}
                                preSelectedVendorOrder={preSelectedVendorOrder}
                                onSuccess={handleSave} 
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InventoryAdd;