import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class HologramService { 

    static getDeviceTags() {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}cellular/hologram/devices/tags`).then((response) => {
              resolve(response.data);
            }).catch((err) => {
              reject(err);
            });
          });
    }

    static cacheHologramTag(tagMetadata) {
      return new Promise((resolve, reject) => {
          axios.post(`${ROOT}cellular/hologram/devices/tags`, tagMetadata).then((response) => {
            resolve(response.data);
          }).catch((err) => {
            reject(err);
          });
        });
  }

    static addHologramTag(enterpriseId) {
      return new Promise((resolve, reject) => {
          axios.post(`${ROOT}cellular/hologram/devices/tags/${enterpriseId}`).then((response) => {
            resolve(response.data);
          }).catch((err) => {
            reject(err);
          });
        });
  }

  static activateSIM(deviceId, sim, activationRequest) {
    return new Promise((resolve, reject) => {
        axios.post(`${ROOT}cellular/hologram/links/cellular/${deviceId}?sim=${sim}`, activationRequest).then((response) => {
          resolve(response.data);
        }).catch((err) => {
          reject(err);
        });
      });
}


    static getPastUsage(tagId, starting, ending) {
      return new Promise((resolve, reject) => {
        axios.get(`${ROOT}cellular/hologram/usage/past?tagId=${tagId}&starting=${starting}&ending=${ending}`).then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject('API lookup failed');
          }
        }).catch((err) => {
          reject(err);
        });
      });
    }

    static getCurrentUsage(tagId) {
      return new Promise((resolve, reject) => {
        axios.get(`${ROOT}cellular/hologram/usage/current?tagId=${tagId}`).then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject('API lookup failed');
          }
        }).catch((err) => {
          reject(err);
        });
      });
    }

    static getRatePlans() {
      return new Promise((resolve, reject) => {
        axios.get(`${ROOT}cellular/hologram/plans`).then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject('API lookup failed');
          }
        }).catch((err) => {
          reject(err);
        });
      });
    }

}

export default HologramService;