import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import i18next from "i18next";

class Sidebar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      location: props.location,
    };
  }

  checkLinks(currentHeaderLink, hoveredLink, availableRoutes) {
    let selectedHeaderItem = availableRoutes.filter(headerItem => hoveredLink ?
                hoveredLink.startsWith(headerItem.path) :
                currentHeaderLink.startsWith(headerItem.path)
              );

    if (selectedHeaderItem && selectedHeaderItem.length > 0 && selectedHeaderItem[0].children && selectedHeaderItem[0].children.length > 0) {
      return true
    }

    return false
  }

  renderLinks(currentHeaderLink, hoveredLink, availableRoutes) {
    let selectedHeaderItem = hoveredLink ?
            availableRoutes.filter(headerItem => hoveredLink.startsWith(headerItem.path)) :
            availableRoutes.filter(headerItem => currentHeaderLink.startsWith(headerItem.path));

    if (selectedHeaderItem && selectedHeaderItem.length > 0 && selectedHeaderItem[0].children && selectedHeaderItem[0].children.length > 0) {
      return selectedHeaderItem[0].children.map((menuItem, i) => {
          if (menuItem.showOnMenu) {
            const className = this.props.location.pathname.startsWith(menuItem.path) ? 'active' : '';
            return (
              <li key={i} className={className}><Link className={menuItem.title.replace(/\s+/g, '-').toLowerCase()} to={menuItem.path}>{ i18next.t(`menus.from-router.${menuItem.title}`) }</Link></li>
            );
          }
        });
    }
  }

  render() {
    if (this.props.availableRoutes) {
      let { hoveredLink, location, availableRoutes} = this.props;

      if (this.checkLinks(location.pathname, hoveredLink, availableRoutes)) {
        return (
          <nav className="sidebar">
            <ul className="nav">
              {this.renderLinks(location.pathname, hoveredLink, availableRoutes)}
            </ul>
          </nav>
        );
      }
    }
    return null;
  }
}

export default withRouter(Sidebar);
