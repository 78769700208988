import React from 'react';
import Select from "react-select";
import i18next from 'i18next';
import QueryString from 'query-string';

import { Button, Modal } from 'react-bootstrap';

import ReactTable from 'react-table';

import { Message } from '../Message';
import { BaseTable } from '../../helpers';
import { DevicesService, EnterprisesService, TimeFormattingService } from "../../services";
import moment from 'moment-timezone';

class AdminDevices extends React.Component {
  constructor(props) {
    super(props);

    const passedState = props.history.location ? props.history.location.state : null;

    this.DEFAULT_SORTED = [];

    const parsedOptions = QueryString.parse(this.props.location.search);

    this.state = {
      searchValue: parsedOptions && parsedOptions.serialNumber ? parsedOptions.serialNumber : "",
      data: [],
      pageCount: 0,
      totalElements: 0,
      forcePage: null,
      parsedOptions: parsedOptions,
      searching: false,
      currentHistoryCount: passedState && passedState.currentHistoryCount ? passedState.currentHistoryCount : 0,
      tableData: null,
      showMoveModal: false,
      deviceToMove: null,
      deviceToMoveEvaluation: null,
      enterpriseOptions: null,
      deviceMoveForceAccepted: 0,
      deviceMoveToEnterprise: null,
      localErrorMessage: null,
      localSuccessMessage: null, 
    };
  }

  handleLocalSuccess(localSuccessMessage) {
    this.setState({localSuccessMessage: localSuccessMessage});
  }

  localErrorHandler(localErrorMessage) {
    this.setState({ localErrorMessage: localErrorMessage });
  }

  messageDismissCallback() {
    this.setState({
      localErrorMessage: null,
      localSuccessMessage: null
    });
  }

  updateRequestURL(tableData) {
    let options = BaseTable.buildURLOptions(tableData, this.DEFAULT_SORTED);
    if (this.state.searchValue) {
      options.push({ 'serialNumber': this.state.searchValue });
    } else {
      options.push({ 'serialNumber': '' });
    }

    let params = options.map(option => Object.keys(option).map((k, i) => `${k}=${option[k]}`)).join('&');

    const currentSearch = this.props.location.search ? this.props.location.search : '?';

    if (`?${params}` !== currentSearch) {
      if (this.state.currentHistoryCount > 100) {
        console.error('Exceeded the history count stack of 100.');
        return false;
      }
      this.props.history.push({
        search: `?${params}`,
        state: {
          currentHistoryCount: this.state.currentHistoryCount + 1
        }
      });
      return true;
    } else {
      return false;
    }
  }  
  
  fetchData(tableData) {
    if (this.state.tableData == null) {
      this.setState({tableData: tableData});
    }
    
    if (this.updateRequestURL(tableData)) {
      return;
    }
    
    this.setState({
      searching: true
    });

    let options = BaseTable.buildFetchDataOptions(this.state.parsedOptions, this.DEFAULT_SORTED);

    DevicesService.findBySerialNumber(options).then(response => {
      const pageCount = Math.ceil(response.page.totalElements / tableData.pageSize);

      let forcePage = null;
      if (tableData.page >= pageCount) {
        forcePage = pageCount - 1;
      }

      this.setState({
        data: response.devices,
        pageCount: pageCount,
        forcePage: forcePage,
        totalElements: response.page.totalElements,
        searching: false
      });
    }).catch(error => {
      console.error(error);
      this.localErrorHandler(error.message);
      this.setState({searching: false, hasError: true});
    });
  }

  doSearch(searchInputValue) {
    const updatedValue = searchInputValue.target.value;
    this.setState({searchValue: updatedValue});

    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.state.timeout = setTimeout(() => {
      this.setState({searchValue: updatedValue}, () => {
        this.fetchData(this.state.tableData);
      });
    }, 1000);
  }

  renderNormalTime(data) {
    const timezone = data.original.deviceGrouping && data.original.deviceGrouping.timezone ? data.original.deviceGrouping.timezone : null;
    const date = TimeFormattingService.renderDate(data.value, timezone);
    const time = TimeFormattingService.renderTime(data.value, timezone);
    return (<span>{date}<br/>{time}</span>);
  }

  toggleMoveModal() {
    if (this.state.showMoveModal) {
      this.setState({
        deviceToMove: null,
        deviceToMoveEvaluation: null,
        enterpriseOptions: null,
        deviceMoveForceAccepted: false,
        deviceMoveToEnterprise: null,
        showMoveModal: false
      });
    } else {
      this.setState({
        showMoveModal: !this.state.showMoveModal
      });      
    }
  }

  executeMove() {
    DevicesService.moveDevice(this.state.deviceToMove.id, this.state.deviceMoveToEnterprise.value, this.state.deviceMoveForceAccepted).then(response => {
      this.handleLocalSuccess("Device has been moved.");

      this.setState({
        deviceToMove: null,
        deviceToMoveEvaluation: null,
        enterpriseOptions: null,
        deviceMoveForceAccepted: false,
        deviceMoveToEnterprise: null,
        showMoveModal: false,
        data: this.state.data.map(device => response.device.id === device.id ? response.device : device)
      });
    }).catch(error => {
      console.error(error);
      this.localErrorHandler(error.message);

      this.setState({
        deviceToMove: null,
        deviceToMoveEvaluation: null,
        enterpriseOptions: null,
        deviceMoveForceAccepted: false,
        deviceMoveToEnterprise: null,
        showMoveModal: false
      });
    });
  }

  moveClicked(device) {
    EnterprisesService.getAll().then(response => {
      this.setState({
        enterpriseOptions: response.enterprises.map(enterprise => { return { value: enterprise.id, label: enterprise.name }; })
      });
    }).catch(error => {
      console.error(error);
      this.localErrorHandler(error.message);
    });

    DevicesService.evaluateForMove(device.id).then(response => {
      this.setState({
        deviceToMove: device,
        deviceToMoveEvaluation: response.data,
        showMoveModal: true
      });
    }).catch(error => {
      console.error(error);
      this.localErrorHandler(error.message);
    });
  }

  updateSelectedEnterprise(selection) {
    this.setState({
       deviceMoveToEnterprise: selection
    });
  }

  updateForceMoveAccepted() {
    if (this.state.deviceMoveForceAccepted === 1) {
      this.setState({
        deviceMoveForceAccepted: 0
      });
    } else {
      this.setState({
        deviceMoveForceAccepted: 1
      });
    }
  }

  render() {
    let dynamicOptions = BaseTable.buildDynamicOptions(this.state.parsedOptions, this.state.forcePage, this.DEFAULT_SORTED);
    var requiresForcedMove = false;
    if (this.state.deviceToMove && this.state.deviceToMoveEvaluation) {
      requiresForcedMove = this.state.deviceToMoveEvaluation.groupingName || this.state.deviceToMoveEvaluation.tagCount > 0 || this.state.deviceToMoveEvaluation.alertCount > 0;
    }
    return (
      <div className = "admin-devices">
        <div className="row">
          <div className="op-page-header">
            <div className="op-page-header__left">
              <h2 className="op-page-header__title">{i18next.t('menus.from-router.Reassign devices')}</h2>
            </div>
          </div>
        </div>
        
        { this.state.localErrorMessage ? <Message errorMessage={ this.state.localErrorMessage } messageDismissCallback={ this.messageDismissCallback.bind(this) } /> : null }

        { this.state.localSuccessMessage ? <Message messages={{ state: { successMessage: this.state.localSuccessMessage } }} messageDismissCallback={ this.messageDismissCallback.bind(this) } /> : null}

        <div className="inner op-content-box">
          <div className="row">
            <div className="col-sm-12">
              <div className="card-block">
                <div className="react-table-custom">
                  <ReactTable
                    className="-striped -highlight"
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    data={this.state.data}
                    pages={this.state.pageCount} // Display the total number of pages
                    loading={this.state.loading} // Display the loading overlay when we need it
                    totalElements={this.state.totalElements}
                    loading={this.state.searching} 
                    onFetchData={this.fetchData.bind(this)} // Request new data when things change
                    showPaginationTop
                    columns={[
                                  {
                                  Header: "Serial Number",
                                  id: 'serialNumber',
                                  accessor: "serialNumber",
                                  minResizeWidth: 100,
                                  width: 250,
                                  filterable: true,
                                  Filter: <div className="filter">
                                            <input
                                              style={{width: "100%", margin:"0 auto"}}
                                              value={this.state.searchValue}
                                              onChange={this.doSearch.bind(this)}
                                              type="text"
                                              name="search"
                                              placeholder="Search by Serial Number"
                                              className="table-search"
                                              autoComplete="off"
                                            />
                                          </div>
                                },
                                {
                                  Header: i18next.t('table-headers.name'),
                                  accessor: "displayName",
                                  sortable: false,
                                  minResizeWidth: 100,
                                  width: 250
                                },
                                {
                                  Header: "Status",
                                  accessor: "deviceStatus.name",
                                  sortable: false,
                                  minResizeWidth: 50,
                                  width: 100
                                },
                                {
                                  Header: "Enterprise",
                                  accessor: "enterprise.name",
                                  sortable: false,
                                  minResizeWidth: 25
                                },
                                {
                                  Header: "Grouping",
                                  accessor: "deviceGrouping.name",
                                  sortable: false,
                                  minResizeWidth: 25
                                },                        
                                {
                                  Header: "Last Updated",
                                  accessor: "lastUpdatedAt",
                                  Cell: cell => this.renderNormalTime(cell),
                                  sortable: false,
                                  minResizeWidth: 25
                                },
                                {
                                  Cell: cell => <button className="btn btn-sm btn-default" onClick={() => this.moveClicked(cell.original)}>Reassign</button>,
                                  sortable: false,
                                  minResizeWidth: 50,
                                  width: 100
                                }
                            ]}
                    {...dynamicOptions}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal show={this.state.showMoveModal} onHide={this.toggleMoveModal.bind(this)}>
            <Modal.Header closeButton>
              <Modal.Title>Move Device</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                this.state.enterpriseOptions && this.state.deviceToMove && this.state.deviceToMoveEvaluation ?
                <div>
                  You are about to move the following device: <br/> <br/>
                  
                  Name: <strong>{this.state.deviceToMove.displayName}</strong> <br/>
                  Assigned To: <strong>{this.state.deviceToMove.enterprise.name}</strong> <br/>
                  Last Updated: <strong>{moment(this.state.deviceToMove.lastUpdatedAt).format("L LTS zz")}</strong> <br/>

                  <br/>

                  {
                    requiresForcedMove ?
                    <div style={{paddingBottom: 10}}>
                      One of the following are set or not zero indicating that this device has been used in the past: <br/> <br/>

                      Location: <strong>{this.state.deviceToMoveEvaluation.groupingName}</strong>  <br/>
                      Tag Count: <strong>{this.state.deviceToMoveEvaluation.tagCount}</strong> <br/>
                      Alert Count: <strong>{this.state.deviceToMoveEvaluation.alertCount}</strong> <br/> <br/>

                      Please verify that you want to force move this device: <input
                                                value={this.state.deviceMoveForceAccepted}
                                                checked={this.state.deviceMoveForceAccepted === 1}
                                                onChange={this.updateForceMoveAccepted.bind(this)}
                                                type="checkbox"
                                                name="deviceMoveForceAccepted"
                                              />
                    </div> : null
                  }

                  Enterprise to move this device to: <br/>
                  <Select
                          options={this.state.enterpriseOptions}
                          value={this.state.deviceMoveToEnterprise}
                          onChange={this.updateSelectedEnterprise.bind(this)}
                          isSearchable={true}
                          className="business-filter"
                          className="react-select-container "
                          classNamePrefix="react-select"
                          placeholder={i18next.t('select')}
                      />
                  
                </div> : <div>Loading...</div>
              }
            </Modal.Body>
            <Modal.Footer>
              {
                (requiresForcedMove && this.state.deviceMoveForceAccepted === 1 && this.state.deviceMoveToEnterprise) || (!requiresForcedMove && this.state.deviceMoveToEnterprise) ?
                  <Button onClick={this.executeMove.bind(this)}>Execute Move</Button> : null
              }
              <Button onClick={this.toggleMoveModal.bind(this)}>{ i18next.t('form-labels.cancel') }</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default AdminDevices;
