import React from "react";
import i18next from "i18next";

const ScrollModule = () => (
    <div className="scroll-notification">
      <div>
        <span className="btn btn-circle">
          <i className="scroll-indicator angle-left animated" />
        </span>
      </div>
      <div className="scroll-info-text"><em>{ i18next.t('scrollable-content') }</em></div>
      <div>
        <span className="btn btn-circle">
          <i className="scroll-indicator angle-right animated" />
        </span>
      </div>
    </div>
);

export default ScrollModule;
