import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class AdminSearchService {
    static search(searchRequest) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${ROOT}/admin/search`, searchRequest)
                .then((searchResponse) => {
                    resolve(searchResponse);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

export default AdminSearchService;