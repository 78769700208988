import config from 'config';
import axios from 'axios';
import GoogleAnalytics from 'react-ga';

import DashboardService from './dashboard_service';
import UsersService from './users_service';
import EnterprisesService from './enterprises_service';
import DevicesService from './devices_service';
import AuthenticationService from './authentication_service';
import TimeFormattingService from './time_formatting_service';
import RoutesService from './routes_services';
import VersionService from './version_service';
import ForgotPasswordService from './forgotpassword_service';
import ContentService from './content_service';
import ReportConfigsService from './report_configs_service';
import SchedulerService from './scheduler_service';
import HologramService from './hologram_service';
import LocalesService from './locales_service';
import DocumentsService from './documents_service';
import ActionRoleTemplatesService from './action_role_templates_service';
import SetupService from './setup_service';
import CommissioningService from './commissioning_service';
import AuditService from './audit_service';
import OrdersService from './orders_service';
import InventoryService from './inventory_service';

GoogleAnalytics.initialize(config.GA_ID);

axios.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("token");
  if (authToken) {
    config.headers["authorization"] = authToken;
  }
  if (config.metadata) {
    config.metadata['startTime'] = new Date();
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  if (response.config.metadata) {
    const responseTime = new Date() - response.config.metadata.startTime;
    // Only time anything longer than a quarter of a second
    if (responseTime > 250) {
      GoogleAnalytics.timing({
        category: 'API Response Times',
        variable: 'load',
        value: responseTime,
        label: response.config.metadata.serviceCall
      });
    }
  }
  return response;
}, (error) => {
  if (error.response && error.response && error.response.data  && error.response.data.genericSystemErrorMessage) {
    console.error(error.response.data.genericSystemErrorMessage);
    return Promise.reject(error);
  } else {
    console.error(error);
    return Promise.reject(error);
  }
});

export {
  DashboardService,
  UsersService,
  DevicesService,
  EnterprisesService,
  AuthenticationService,
  TimeFormattingService,
  RoutesService,
  VersionService,
  ForgotPasswordService,
  ContentService, 
  ReportConfigsService,
  SchedulerService, 
  HologramService, 
  LocalesService,
  DocumentsService,
  ActionRoleTemplatesService,
  SetupService,
  CommissioningService,
  AuditService,
  OrdersService,
  InventoryService
};
