import React from 'react';
import { reduxForm, Field } from 'redux-form';
import i18next from 'i18next';

import { SelectInput } from '../SelectInput';

class UserUpdateLocaleForm extends React.Component {

  constructor(props) {
    super(props);

    this.props.initialize({locale: this.props.selectedLocale});
  }

  render() {
    const { handleSubmit, locales } = this.props;

    return (
        <div>
          <form onSubmit={handleSubmit}>
            <div className="op-content-box op-card form-group">
              <div>
                <p className="panel-heading">{i18next.t('pages.users.language-preference')}:</p>
              </div>
              <div className="add-margin-bottom">
                  <Field
                    name="locale"
                    options={locales}
                    isSearchable={true}
                    clearable={false}
                    component={SelectInput}
                  />
              </div>
              <div className="pull-right">
                <button type="submit" className="btn btn-primary">{i18next.t('form-labels.save')}</button>
              </div>
            </div>
          </form>
        </div>
    );
  }

}

export default reduxForm({ form: 'UserUpdateLocaleForm' })(UserUpdateLocaleForm);
