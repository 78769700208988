import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

import { PageTitle } from '../PageTitle';
import {DocumentsService} from '../../services';

@connect(state => {
    return {
      me: state.user.me
    }
  })
class HelpListing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            documents: null
        }
    }
    
    componentDidMount() {
        let language = this.props.me.locale.languageTag;
        DocumentsService.getAllForLanguage(language).then(response => {
            this.setState({documents: response.documents});
        }).catch(error => {
            console.error(error);
      });

    }

    renderList() {
        return this.state.documents.map(doc => {
          return (
              <li key={doc.id}><Link to={`/help/${doc.slug}`}>{doc.topic}</Link></li>
          );
        });
      }

    render() {
        if (this.state.documents) {
            return (
                <div className="animated fadeIn">
                    <div className="row">
                        <PageTitle title = {i18next.t('pages.help-documents.header')}/>
                    </div>
                    <div className="op-content-box">
                        <ul>{this.renderList()}</ul>  
                    </div>  
                </div>
            )
        }
        else {
            return (
                <div className="loader">{i18next.t('loading')}</div>
            );
        }
    }
}

export default HelpListing;