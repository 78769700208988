import React from 'react';
import i18next from 'i18next';

import { PageTitle } from '../PageTitle';
import { DocumentsService } from '../../services';
import { MarkdownEditor } from '../MarkdownEditor';

class HelpDetail extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      slug: props.match.params.slug,
      document: null
    };
  }

  componentDidMount() {
    DocumentsService.getWithSlug(this.state.slug).then(response => {
      this.setState({document: response.document});
    }).catch((err) => {
      console.log(err);
    });
  }

  render() {
    if (this.state.document) {
      const {topic, content} = this.state.document;
      return (
        <div className="animated fadeIn">
          <div className="row">
            <PageTitle title = {topic}/>
          </div>
          <div className="op-content-box">
            <MarkdownEditor
              readOnly={true}
              content={content}
            />
          </div>  
        </div>
      );
    } else {
      return (
        <div className="loader">
          {i18next.t('loading')}
        </div>
      );
    }
  }
}

export default HelpDetail;
