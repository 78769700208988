import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import MobileNavLink from './mobile_nav_link';
import { Sidebar } from '../Sidebar/';
import i18next from "i18next";
import * as actions from '../../actions/grouping_actions';

@connect(state => {
  return {
    authenticated: state.auth.authenticated
  }
}, actions)
class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      mobileNavIsOpened: false,
      hoveredLink: null
    };
    this.showMobileNav = this.showMobileNav.bind(this);
  }

  renderLinks(availableRoutes) {
    if (this.props.authenticated) {
      if (availableRoutes) {
        return availableRoutes.map((menuItem, i) => {
          if (menuItem.showOnMenu) {
            return (
                <HeaderLink
                    key={menuItem.id}
                    to={menuItem.path}
                    linktext={menuItem.title}
                    icon={menuItem.icon}
                    currentLinkHovered={this.state.hoveredLink}
                    linkHovered={this.linkHovered.bind(this)}
                  />
            );
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    } else {
      return [ ];
    }
  }

  renderMobileNavLinks(availableRoutes) {
    let allNavLinks = [];
    if (this.props.authenticated) {
      if (availableRoutes) {
        return availableRoutes.map((menuItem, i) => {
          if (menuItem.showOnMenu) {
            allNavLinks = [];
            allNavLinks.push(<HeaderMobileLink key={menuItem.id} to={menuItem.path} dataCategory={menuItem.title} linkText={ i18next.t(`menus.from-router.${menuItem.title}`) } showMobileNav = { this.showMobileNav } />);
            allNavLinks.push(menuItem.children.map((subNavItem, i) => {
              if (subNavItem.showOnMenu) {
                return <HeaderMobileLink subNavItemClass="secondary" dataCategory={menuItem.title} key={subNavItem.id} to={subNavItem.path} linkText={ i18next.t(`menus.from-router.${subNavItem.title}`) } showMobileNav = { this.showMobileNav } />
              }
            }));
            return allNavLinks.map((navLink) => {
              return navLink;
            });
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    } else {
      return [ ];
    }
  }

  showMobileNav(e) {
    // check if mobile nav menu is currently displayed
    const { mobileNavIsOpened } = this.state;

    // clicking the menu toggle button or selecting a subnav link should toggle the menu open/closed
    if (e.target.parentNode.parentNode.className === 'secondary' ||
        e.target.parentNode.parentNode.className === 'top-nav__right-controls-container') {
      this.setState({
        // toggle value of `opened`
        mobileNavIsOpened: !mobileNavIsOpened,
      });
    } else {
      // clicking a "menu category" should accordion that category's subnav links
      e.preventDefault();

      const primaryListItems = Array.from(e.target.closest('ul').querySelectorAll('.primary'));

      primaryListItems.map(listItem => {
        listItem.classList.remove('open');

        if (e.target.getAttribute('data-category') === listItem.querySelector('a').getAttribute('data-category')) {
          listItem.classList.add('open');
        }
      });

      const secondaryLinks = Array.from(e.target.closest('ul').querySelectorAll('.secondary a'));

      secondaryLinks.map(link => {
        const listItem = link.closest('li');

        if (e.target.getAttribute('data-category') === link.getAttribute('data-category')) {
          listItem.classList.remove('collapsed');
        } else {
          listItem.closest('li').classList.add('collapsed');
        }
      });
    }
  }

  linkHovered(hoveredLink) {
    if (this.state.hoveredLink != hoveredLink) {
      this.setState({ hoveredLink: hoveredLink });
    }
  }

  render() {
    let availableRoutes = this.props.availableRoutes.filter((availableRoute) => availableRoute.children && availableRoute.children.filter((childRoute) => childRoute.showOnMenu).length);

    return (
      <div onMouseLeave={() => this.linkHovered(null)} className="header-wrapper">
        <nav className="top-nav">

          <div id="navbar" className="top-nav__nav-bar">
            <ul>
              {this.renderLinks(availableRoutes)}
            </ul>
          </div>
          <div className="top-nav__right-controls-container">
            <div className="top-nav__mobile-menu-toggle">
              <button className={`top-nav__mobile-nav-button${ this.state.mobileNavIsOpened ? ' open':'' }`} onClick={ this.showMobileNav } />
            </div>

            <div className="top-nav__user-icon">
              <Nav activeKey="1">
                <NavDropdown title="" eventKey="4" id="nav-dropdown" className="op-menu-icon op-icon-user" noCaret={true}>
                  <LinkContainer to="/help">
                    <MenuItem eventKey="4.1">{ i18next.t('menus.help') }</MenuItem>
                  </LinkContainer>
                  <LinkContainer to="/user/profile">
                    <MenuItem eventKey="4.2">{ i18next.t('menus.profile') }</MenuItem>
                  </LinkContainer>
                  <LinkContainer to="/signout">
                    <MenuItem eventKey="4.3">{ i18next.t('menus.log-out') }</MenuItem>
                  </LinkContainer>
                </NavDropdown>
              </Nav>
            </div>
          </div>
          { this.state.mobileNavIsOpened && (
              <div className="top-nav__mobile-menu">
                <ul>
                  {this.renderMobileNavLinks(availableRoutes)}
                </ul>
              </div>
          ) }

          <Sidebar availableRoutes={availableRoutes} hoveredLink={this.state.hoveredLink}/>

        </nav>
      </div>
    );
  }
}

class HeaderLink extends React.Component {

  constructor(props) {
    super(props);

    let place = this.props.to;
    let text = i18next.t(`menus.from-router.${this.props.linktext}`);
    let className = "op-menu-icon";
    if (this.props.icon) {
      className += " " + this.props.icon;
    }

    this.state = {
      className: className,
      link: <NavLink
              to={place}
              onMouseEnter={() => props.linkHovered(place)}
              >{ text }</NavLink>,
      location:null
    };
  }

  componentDidMount() {
    this.stopHistoryListener = this.props.history.listen((location, action) => {
      this.setState({
        className: this.state.className,
        link: this.state.link,
        location: location
      });
    });
  }

  componentWillUnmount() {
    this.stopHistoryListener();
  }

  render() {
    let className = this.state.className;
    let { location } = this.props;

    if (this.state.location) {
      location = this.state.location;
    }

    if (!this.state.link) {
      return (<h1>{ i18next.t('loading') }</h1>);
    } else {
      if (this.props.currentLinkHovered) {
        if (this.props.currentLinkHovered.startsWith(this.state.link.props.to)) {
          className += " active";
        }
      } else {
        if (location.pathname.startsWith(this.state.link.props.to)) {
          className += " active";
        }
      }

      return (
          <li className={className}>
            {this.state.link}
          </li>
      );
    }
  }
}

class HeaderMobileLink extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      place: this.props.to,
      linkText: this.props.linkText,
      subNavItemClass: this.props.subNavItemClass,
      location: null,
      showMobileNav: this.props.showMobileNav,
      dataCategory: this.props.dataCategory
    };
  }

  componentDidMount() {
    this.stopHistoryListener = this.props.history.listen((location, action) => {
      this.setState({
        link: this.state.link
      });
    });
  }

  componentWillUnmount() {
    this.stopHistoryListener();
  }

  render() {
    let { location } = this.props.history;

    if (!this.state.place) {
      return (<h1>{ i18next.t('loading') }</h1>);
    } else {
      let categoryPathEnd = this.state.place.indexOf('/', 1);
      const categoryPath = this.state.place.substring( 0, (categoryPathEnd === -1 ? this.state.place.length : categoryPathEnd));

      if (location.pathname.startsWith(categoryPath)) {
        this.state.spanClassName = "active-category";
      }

      if (location.pathname.match(this.state.place) && this.state.subNavItemClass) {
        this.state.spanClassName += " active";
      }

      return (
          <MobileNavLink linkProperties={ this.state } showMobileNav = { this.props.showMobileNav } />
      );
    }
  }
}

HeaderLink = withRouter(HeaderLink);
HeaderMobileLink = withRouter(HeaderMobileLink);

export default withRouter(Header);
