import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class AuditService {
    static searchAuditEntries(options=[]) {
        return new Promise((resolve, reject) => {
            let params = options.length == 0 ? '' : '?' + options.map(option => Object.keys(option).map((k, i) => `${k}=${option[k]}`)).join('&');
            axios.get(`${ROOT}audit${params}`).then((data) => {
                resolve({ changeEvents: data.data.content, page: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }
}

export default AuditService;