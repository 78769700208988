import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class OrdersService {
    static getOrderStatuses() {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/order-statuses`).then((data) => {
                resolve({ orderStatuses: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getDeviceTypes() {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/device-types`).then((data) => {
                resolve({ deviceTypes: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getOrders(excludeStatuses = [], includeLocation) {
        return new Promise((resolve, reject) => {
            let params = '';
            if (excludeStatuses.length > 0) {
                params = `?excludeStatuses=${excludeStatuses.join(',')}`;
            }
            if (includeLocation != null) {
                params = `${params}${params.length == 0 ? '?' : '&'}includeLocation=${includeLocation}`;
            }
            axios.get(`${ROOT}fulfillment/orders${params}`).then((data) => {
                resolve({ orders: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getOrder(orderId) {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/orders/${orderId}`).then((data) => {
                resolve({ order: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getOrderAtachmentLink(attachmentReference) {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/orders/attachment/${attachmentReference}`).then((data) => {
                resolve({ url: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static addOrder(order, uploadFiles) {
        const formData = new FormData();
        uploadFiles.forEach(({ file, uploadFilename }) => {
            if (uploadFilename) {
                formData.append("uploads", file, uploadFilename);
            }
        });
        formData.append("order", new Blob([JSON.stringify(order)], { type: "application/json" }));

        return new Promise((resolve, reject) => {
            axios.post(`${ROOT}fulfillment/orders`, formData, { headers: {'content-type': 'multipart/form-data'} }).then((data ) => {
                resolve({ order: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }
    
    static updateOrder(order, uploadFiles) {
        const formData = new FormData();
        uploadFiles.forEach(({file, uploadFilename}) => {
            if (uploadFilename) {
                formData.append("uploads", file, uploadFilename);
            }
        });
        formData.append("order", new Blob([JSON.stringify(order)], { type: "application/json" }));

        return new Promise((resolve, reject) => {
            axios.patch(`${ROOT}fulfillment/orders/${order.id}`, formData, { headers: {'content-type': 'multipart/form-data'} }).then((data ) => {
                resolve({ order: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static createOrderShipment(orderId, shipmentRequest) {
        return new Promise((resolve, reject) => {
            axios.post(`${ROOT}fulfillment/orders/${orderId}/shipment`, shipmentRequest).then((data) => {
                resolve({ shipment: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getOrderShipment(orderId) {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/orders/${orderId}/shipment`).then((data) => {
                resolve({ shipment: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getValidatedAddress(orderId) {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/orders/${orderId}/validate-address`).then((data) => {
                resolve({ address: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static createOrderShipmentTransaction(orderId, rateId) {
        return new Promise((resolve, reject) => {
            axios.post(`${ROOT}fulfillment/orders/${orderId}/shipment-transaction`, {rateId: rateId}).then((data) => {
                resolve({ transaction: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getOrderShipmentTransaction(orderId) {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT}fulfillment/orders/${orderId}/shipment-transaction`).then((data) => {
                resolve({ transaction: data.data, response: data });
            }).catch((err) => {
                reject(err);
            });
        });
    }
}

export default OrdersService;
