import React from 'react';
import Select, { components } from 'react-select';
import i18next from 'i18next';

const SelectInput = (props) => {
  const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="nope" />;

  const GroupHeading = props => (
    <components.GroupHeading
      {...props}
      className={props.selectProps.filterGroupHeading ? 'filter-heading' : undefined}
    />
  );

  return (
    <div>
      <Select
        components={{ Input, GroupHeading }}
        {...props}
        value={props.clear ? null : props.input.value}
        onChange={value => props.input.onChange(value)}
        onBlur={event => event.preventDefault()}
        options={props.options}
        className="react-select-container"
        classNamePrefix={(props.meta.touched && props.meta.error ? 'react-select-has-error' : 'react-select')}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        placeholder={props.placeholder || i18next.t('select')}
      />
      {props.meta.touched && ((props.meta.error && <span className="input-error">{props.meta.error}</span>))}
    </div>
  );
};

export default SelectInput;
