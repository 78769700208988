import React from "react";
import i18next from "i18next";
import QueryString from "query-string";

import ReactTable from "react-table";

import { Button, Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { PageTitle } from "../PageTitle";
import { Message } from "../Message";
import { BaseTable } from "../../helpers";
import { CommissioningService, TimeFormattingService } from "../../services";
import { Scanner } from "../Scanner";

class FleetManage extends React.Component {
  constructor(props) {
    super(props);

    const passedState = props.history.location
      ? props.history.location.state
      : null;

    this.DEFAULT_SORTED = [];

    const parsedOptions = QueryString.parse(this.props.location.search);

    this.state = {
      fleetId: props.match.params.fleetId,
      searchValue:
        parsedOptions && parsedOptions.serialNumber
          ? parsedOptions.serialNumber
          : "",
      pageCount: 0,
      totalElements: 0,
      forcePage: null,
      parsedOptions: parsedOptions,
      searching: false,
      currentHistoryCount:
        passedState && passedState.currentHistoryCount
          ? passedState.currentHistoryCount
          : 0,
      tableData: null,
      localErrorMessage: null,
      localSuccessMessage: null,
      showAddDeviceModal: false,
      originalAvailableDevicesByDeviceTypeName: null,
      availableDevicesByDeviceTypeName: null,
      allAvailableDevices: null,
      filteredAvailableDevices: [],
      currentAvailableFilter: "",
      selectedDeviceType: null,
      selectedDevices: [],
      filteredSelectedDevices: [],
      currentSelectedFilter: "",
      barcodeToggle: false,
      scannedBarcode: null,
      barcodeError: false,
      lastCodeScanned: null,
      showRemoveDeviceModal: false
    };

    this.selectedDevicesRef = React.createRef();
    this.availableDevciesRef = React.createRef();

    this.toggleRemoveDeviceModal = this.toggleRemoveDeviceModal.bind(this);
    this.toggleShowAddDeviceModal = this.toggleShowAddDeviceModal.bind(this);
    this.toggleShowBarcodeScan = this.toggleShowBarcodeScan.bind(this);
    this.onNewScanResult = this.onNewScanResult.bind(this);
  }

  toggleShowBarcodeScan() {
    this.setState({
      barcodeToggle: !this.state.barcodeToggle,
      scannedBarcode: null,
      barcodeError: false,
    });
  }

  onNewScanResult(decodedText, decodedResult) {
    if (decodedText === this.state.lastCodeScanned) {
      return;
    }

    const foundDevice = this.state.allAvailableDevices.filter(
      (item) => decodedText.toLowerCase() === item.deviceRadioMacAddress.toLowerCase()
    );
    const alreadySelectedDevice = this.state.selectedDevices.filter(
      (item) => decodedText.toLowerCase() === item.deviceRadioMacAddress.toLowerCase()
    );
    if (foundDevice.length && !alreadySelectedDevice.length) {
      this.setState(
        {
          lastCodeScanned: decodedText,
          scannedBarcode: foundDevice[0].id,
          barcodeError: false,
          selectedDeviceType: foundDevice[0].deviceType?.name,
        },
        () => {
          this.addToSelectedDevices();
        }
      );
    } else {
      this.setState({
        lastCodeScanned: null,
        scannedBarcode: null,
        barcodeError: alreadySelectedDevice.length
          ? "THIS DEVICE IS ALREADY IN THE LIST"
          : "ASSET NOT FOUND IN AVAILABLE INVENTORY",
      });
    }
  }

  toggleRemoveDeviceModal() {
    this.setState({ showRemoveDeviceModal: !this.state.showRemoveDeviceModal });
  }

  toggleShowAddDeviceModal() {
    if (this.state.showAddDeviceModal) {
      this.setState(
        {
          showAddDeviceModal: false,
          currentSelectedFilter: "",
          filteredSelectedDevices: [],
          currentAvailableFilter: "",
          filteredAvailableDevices: [],
          selectedDevices: [],
          selectedDeviceType: null,
          availableDevicesByDeviceTypeName: this.state.originalAvailableDevicesByDeviceTypeName,
          barcodeToggle: false,
          scannedBarcode: null,
          barcodeError: false,
        },
        () => {
          const keys = Object.keys(this.state.availableDevicesByDeviceTypeName);
          if (keys.length > 0) {
            this.changeDeviceType(keys[0]);
          }
        }
      );
    } else {
      if (this.state.availableDevicesByDeviceTypeName) {
        this.setState(
          {
            showAddDeviceModal: true,
            currentSelectedFilter: "",
            filteredSelectedDevices: [],
            currentAvailableFilter: "",
            filteredAvailableDevices: [],
            selectedDevices: [],
            selectedDeviceType: null,
          },
          () => {
            const keys = Object.keys(
              this.state.availableDevicesByDeviceTypeName
            );
            if (keys.length > 0) {
              this.changeDeviceType(keys[0]);
            }
          }
        );
      } else {
        this.setState({ searching: true });

        CommissioningService.getAvailableInventory()
          .then((response) => {
            let availableDevicesByDeviceTypeName = {};
            for (const device of response.inventory) {
              const deviceTypeName = device.deviceType.name;
              if (availableDevicesByDeviceTypeName[deviceTypeName] == null) {
                availableDevicesByDeviceTypeName[deviceTypeName] = [];
              }
              availableDevicesByDeviceTypeName[deviceTypeName].push(device);
            }

            this.setState(
              {
                searching: false,
                showAddDeviceModal: true,
                availableDevicesByDeviceTypeName:
                  availableDevicesByDeviceTypeName,
                originalAvailableDevicesByDeviceTypeName:
                  availableDevicesByDeviceTypeName,
                allAvailableDevices: response.inventory,
              },
              () => {
                const keys = Object.keys(
                  this.state.availableDevicesByDeviceTypeName
                );
                if (keys.length > 0) {
                  this.changeDeviceType(keys[0]);
                }
              }
            );
          })
          .catch((error) => {
            console.error(error);
            this.localErrorHandler(error.message);
            this.setState({ searching: false, hasError: true });
          });
      }
    }
  }

  addDeviceCalled() {
    this.setState({ searching: true, showAddDeviceModal: false });

    const fleetUpdate = {
      fleetId: this.state.fleet.id,
      description: this.state.fleet.description,
      deviceInventoryIdsToCommission: this.state.selectedDevices.map(
        (device) => device.id
      ),
    };

    CommissioningService.updateFleet(fleetUpdate)
      .then((response) => {
        if (response.errors.length > 0) {
          this.localErrorHandler("One or more of the devices beeing added failed to commission and will need to be tried again: " + response.errors.join(", "));
          this.setState({ searching: false, hasError: true });
        } else {
          this.setState(
            {
              searching: false,
              selectedDevices: [],
              availableDevices: null,
            },
            () => {
              this.refresh();
            }
          );
        }
      })
      .catch((error) => {
        console.error(error);
        this.localErrorHandler(error.message);
        this.setState({ searching: false, hasError: true });
      });
  }

  handleLocalSuccess(localSuccessMessage) {
    this.setState({ localSuccessMessage: localSuccessMessage });
  }

  localErrorHandler(localErrorMessage) {
    this.setState({ localErrorMessage: localErrorMessage });
  }

  messageDismissCallback() {
    this.setState({
      localErrorMessage: null,
      localSuccessMessage: null,
    });
  }

  componentDidMount() {
    this.refresh();
  }
  
  viewClicked(commissionedDeviceReference) {
    this.setState({
      linkTo: `/admin-devices/fleets/device/view/${commissionedDeviceReference.referenceId}`
    });
  }  

  removeDeviceClicked(commissionedDeviceReference) {
    this.setState({ deviceToRemove: commissionedDeviceReference }, () => {this.toggleRemoveDeviceModal()});    
  }

  executeRemoveDevice(commissionedDeviceReference) {
    this.toggleRemoveDeviceModal();
    
    this.setState({ searching: true });

    CommissioningService.deleteDevice(commissionedDeviceReference.referenceId)
      .then((response) => {
        if (response.errorMessage) {
          this.localErrorHandler(response.errorMessage);
          this.setState({ searching: false, hasError: true }, () => {
            this.refresh();
          });
        } else {
          this.setState(
            { searching: false, localSuccessMessage: "Device removed." },
            () => {
              this.refresh();
            }
          );
        }
      })
      .catch((error) => {
        console.error(error);
        this.localErrorHandler(error.message);
        this.setState({ searching: false, hasError: true });
      });
  }

  reinventoryClicked(commissionedDeviceReference) {
    this.setState({ searching: true });

    CommissioningService.reinventoryDevice(
      commissionedDeviceReference.referenceId
    )
      .then((response) => {
        if (response.data.errorMessage) {
          this.localErrorHandler(response.data.errorMessage);
          this.setState({ searching: false, hasError: true }, () => {
            this.refresh();
          });
        } else {
          this.setState(
            { searching: false, localSuccessMessage: "Reinventoried." },
            () => {
              this.refresh();
            }
          );
        }
      })
      .catch((error) => {
        console.error(error);
        this.localErrorHandler(error.message);
        this.setState({ searching: false, hasError: true });
      });
  }

  refresh() {
    this.setState({ searching: true });

    CommissioningService.getFleetDetail(this.state.fleetId)
      .then((response) => {
        this.augmentDeviceInventoryData(response.fleet);
        this.setState({
          fleet: response.fleet,
          deviceInventoryToCommission: response.fleet.deviceInventoryToCommission,
          searching: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.localErrorHandler(error.message);
        this.setState({ searching: false, hasError: true });
      });
  }

  augmentDeviceInventoryData(fleet) {
    for (const deviceData of fleet.deviceInventoryToCommission) {
      const deviceRadioMacAddress = deviceData.deviceRadioMacAddress;
      const hasRegisteredTime = fleet.deviceRadioMacAddressToRegisteredLastUpdatedTime && fleet.deviceRadioMacAddressToRegisteredLastUpdatedTime[deviceRadioMacAddress];
      const hasUnregisteredTime = fleet.deviceRadioMacAddressToUnregisteredLastReceivedTime && fleet.deviceRadioMacAddressToUnregisteredLastReceivedTime[deviceRadioMacAddress];
      if (hasRegisteredTime) {
        deviceData.registeredLastTime = fleet.deviceRadioMacAddressToRegisteredLastUpdatedTime[deviceRadioMacAddress];
        deviceData.unregisteredLastTime = null;
      } else if (hasUnregisteredTime) {
        deviceData.registeredLastTime = null;
        deviceData.unregisteredLastTime = fleet.deviceRadioMacAddressToUnregisteredLastReceivedTime[deviceRadioMacAddress];
      } else {
        deviceData.registeredLastTime = null;
        deviceData.unregisteredLastTime = null;
      }
    }
  }
    
  renderStatus(device) {
    if (device.registered) {
      return <span>Registered</span>;
    } else {
      const isDecommissioned =
        device.decommissionedAt && device.decommissionedAt !== null;
      const isReinventoried =
        device.reinventoriedAt && device.reinventoriedAt !== null;
      return (
        <span>
          {isDecommissioned && !isReinventoried && <span>Removed</span>}
          {isReinventoried && <span>Reinventoried</span>}
          {!isDecommissioned && !isReinventoried && <span>Commissioned</span>}
        </span>
      );
    }
  }

  renderRegisteredTime(data) {
    if (data.original.registeredLastTime !== null) {
      const lastTime = data.original.registeredLastTime;
      const date = TimeFormattingService.renderDate(lastTime, null);
      const time = TimeFormattingService.renderTime(lastTime, null);
      return (<span>{date} {time}</span>);
    } else {
      return (<span>None</span>);
    }
  }

  renderUnRegisteredTime(data) {
    if (data.original.registeredLastTime !== null) {
      return (<span>NA</span>);
    } else if (data.original.unregisteredLastTime !== null) {
      const lastTime = data.original.unregisteredLastTime;
      const date = TimeFormattingService.renderDate(lastTime, null);
      const time = TimeFormattingService.renderTime(lastTime, null);
      return (<span>{date} {time}</span>);
    } else {
      return (<span>None</span>);
    }
  }

  renderActionButtons(device) {
    if (device.registered) {
      return <span><button className="btn btn-sm btn-default" onClick={() => this.viewClicked(device)}>View</button></span>;
    } else {
      const isDecommissioned =
        device.decommissionedAt && device.decommissionedAt !== null;
      const isReinventoried =
        device.reinventoriedAt && device.reinventoriedAt !== null;
      return (
        <span>
          {!isDecommissioned && <span><button className="btn btn-sm btn-default" onClick={() => this.removeDeviceClicked(device)}>Remove</button>&nbsp;</span>}
          {isDecommissioned && !isReinventoried && <span><button className="btn btn-sm btn-default" onClick={() => this.reinventoryClicked(device)}>Reinventory</button>&nbsp;</span>}
          <button className="btn btn-sm btn-default" onClick={() => this.viewClicked(device)}>View</button>
        </span>
      );
    }
  }

  renderNormalTime(data) {
    const timezone =
      data.original.deviceGrouping && data.original.deviceGrouping.timezone
        ? data.original.deviceGrouping.timezone
        : null;
    const date = TimeFormattingService.renderDate(data.value, timezone);
    const time = TimeFormattingService.renderTime(data.value, timezone);
    return (
      <span>
        {date} {time}
      </span>
    );
  }

  changeDeviceType(deviceType) {
    this.setState({
      selectedDeviceType: deviceType,
      filteredAvailableDevices:
        this.state.availableDevicesByDeviceTypeName[deviceType],
      currentAvailableFilter: "",
    });
  }

  addToSelectedDevices() {
    let selectedDevices = [...this.state.selectedDevices];
    let availableDevices = [
      ...this.state.availableDevicesByDeviceTypeName[
        this.state.selectedDeviceType
      ],
    ];

    let selectedIds = [];
    for (const option of this.availableDevciesRef.current.selectedOptions) {
      selectedIds.push(parseInt(option.value));
    }

    if (this.state.scannedBarcode) selectedIds = [this.state.scannedBarcode];

    selectedDevices = selectedDevices.concat(
      availableDevices.filter((item) => selectedIds.includes(item.id))
    );
    availableDevices = availableDevices.filter(
      (item) => !selectedIds.includes(item.id)
    );

    let availableDevicesByDeviceTypeName = {};
    for (const item in this.state.availableDevicesByDeviceTypeName) {
      availableDevicesByDeviceTypeName[item] =
        this.state.availableDevicesByDeviceTypeName[item];
    }
    availableDevicesByDeviceTypeName[this.state.selectedDeviceType] =
      availableDevices;

    this.setState(
      {
        availableDevicesByDeviceTypeName: availableDevicesByDeviceTypeName,
        selectedDevices: selectedDevices,
        scannedBarcode: null,
      },
      () => {
        this.filterAvailable(this.state.currentAvailableFilter);
        this.filterSelected(this.state.currentSelectedFilter);
      }
    );
  }

  removeFromSelectedDevices() {
    let selectedDevices = [...this.state.selectedDevices];

    let removeIds = [];
    for (const option of this.selectedDevicesRef.current.selectedOptions) {
      removeIds.push(parseInt(option.value));
    }

    let availableDevicesByDeviceTypeName = {};
    for (const item in this.state.availableDevicesByDeviceTypeName) {
      availableDevicesByDeviceTypeName[item] = [
        ...this.state.availableDevicesByDeviceTypeName[item],
      ];
    }
    for (const toReturn of selectedDevices.filter((item) =>
      removeIds.includes(item.id)
    )) {
      availableDevicesByDeviceTypeName[toReturn.deviceType.name].push(toReturn);
    }

    selectedDevices = selectedDevices.filter(
      (item) => !removeIds.includes(item.id)
    );

    this.setState(
      {
        availableDevicesByDeviceTypeName: availableDevicesByDeviceTypeName,
        selectedDevices: selectedDevices,
      },
      () => {
        this.filterAvailable(this.state.currentAvailableFilter);
        this.filterSelected(this.state.currentSelectedFilter);
      }
    );
  }

  filterAvailable(filterBy) {
    this.setState({
      currentAvailableFilter: filterBy,
      filteredAvailableDevices: this.state.availableDevicesByDeviceTypeName[
        this.state.selectedDeviceType
      ].filter((device) =>
        device.deviceRadioMacAddress.includes(filterBy.toLowerCase())
      ),
    });
    return true;
  }

  filterSelected(filterBy) {
    this.setState({
      currentSelectedFilter: filterBy,
      filteredSelectedDevices: this.state.selectedDevices.filter((device) =>
        device.deviceRadioMacAddress.includes(filterBy.toLowerCase())
      ),
    });
    return true;
  }

  doSearch(searchInputValue) {
    const updatedValue = searchInputValue.target.value;
    this.setState({
      searchValue: updatedValue,
      deviceInventoryToCommission:
        this.state.fleet.deviceInventoryToCommission.filter(
          (deviceInventoryData) =>
            deviceInventoryData.deviceRadioMacAddress.includes(updatedValue)
        ),
    });
  }

  render() {
    if (this.state.linkTo) {
      if (this.state.query) {
        return (
          <Redirect
            push
            to={{
              pathname: this.state.linkTo,
              query: this.state.query,
              state: { backLinkSearch: this.state.backLinkSearch },
            }}
          />
        );
      } else {
        return (
          <Redirect
            push
            to={{
              pathname: this.state.linkTo,
              state: { backLinkSearch: this.state.backLinkSearch },
            }}
          />
        );
      }
    }

    let dynamicOptions = BaseTable.buildDynamicOptions(
      this.state.parsedOptions,
      this.state.forcePage,
      this.DEFAULT_SORTED
    );

    return (
      <div className="admin-devices">
        <div className="row">
          <PageTitle
            title={
              this.state.fleet
                ? `Fleet: ${this.state.fleet.description}`
                : "Fleet View"
            }
            refreshFunction={this.refresh.bind(this)}
            addButtonCallback={() => {
              this.toggleShowAddDeviceModal();
            }}
          />
        </div>

        {this.state.localErrorMessage ? (
          <Message
            errorMessage={this.state.localErrorMessage}
            messageDismissCallback={this.messageDismissCallback.bind(this)}
          />
        ) : null}

        {this.state.localSuccessMessage ? (
          <Message
            messages={{
              state: { successMessage: this.state.localSuccessMessage },
            }}
            messageDismissCallback={this.messageDismissCallback.bind(this)}
          />
        ) : null}

        <div className="inner op-content-box">
          <div className="row">
            <div className="col-sm-12">
              <div className="card-block">
                <div className="react-table-custom">
                  <ReactTable
                    className="-striped -highlight"
                    data={
                      this.state.deviceInventoryToCommission == null
                        ? []
                        : this.state.deviceInventoryToCommission
                    }
                    loading={this.state.searching}
                    showPaginationTop
                    columns={[
                      {
                        Header: "Device",
                        id: "deviceRadioMacAddress",
                        accessor: "deviceRadioMacAddress",
                        minResizeWidth: 100,
                        width: 200,
                        filterable: true,
                        Filter: (
                          <div className="filter">
                            <input
                              style={{ width: "100%", margin: "0 auto" }}
                              value={this.state.searchValue}
                              onChange={this.doSearch.bind(this)}
                              type="text"
                              name="search"
                              placeholder="Search by Mac Address"
                              className="table-search"
                              autoComplete="off"
                            />
                          </div>
                        ),
                      },
                      {
                        Header: "Device Type",
                        id: "deviceType",
                        accessor: "deviceType.name",
                        minResizeWidth: 150,
                        width: 200,
                      },
                      {
                        Header: "Status",
                        Cell: (cell) => this.renderStatus(cell.original),
                        sortable: false,
                        minResizeWidth: 50,
                        width: 150,
                      },
                      {
                        Header: "Last Device Time - Registered",
                        accessor: "registeredLastTime",
                        Cell: cell => this.renderRegisteredTime(cell),
                        sortable: true,
                        minResizeWidth: 75,
                        width: 150,
                      },                    
                      {
                        Header: "Last Device Time - Unregistered",
                        accessor: "unregisteredLastTime",
                        Cell: cell => this.renderUnRegisteredTime(cell),
                        sortable: true,
                        minResizeWidth: 75,
                        width: 150,
                      },                      
                      {
                        Header: "Available Actions",
                        Cell: (cell) => this.renderActionButtons(cell.original),
                        sortable: false,
                        minResizeWidth: 50,
                        width: 300,
                      },
                    ]}
                    {...dynamicOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.showRemoveDeviceModal} onHide={this.toggleRemoveDeviceModal}>
          <Modal.Header closeButton>
            <Modal.Title>Remove Device</Modal.Title>
          </Modal.Header>        
          <Modal.Body style={{ height: 200 }}>
            {
              this.state.deviceToRemove && <span>Are you sure you want to remove the device? {this.state.deviceToRemove.deviceRadioMacAddress}</span>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.executeRemoveDevice(this.state.deviceToRemove)}>Remove</Button>
            <Button onClick={this.toggleRemoveDeviceModal}>{i18next.t("form-labels.cancel")}</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showAddDeviceModal} onHide={this.toggleShowAddDeviceModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Device</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: 500 }}>
            <div style={{ width: "100%", height: "100%" }}>
              <select name="selectedDeviceType" style={{ width: "100%", opacity: this.state.barcodeToggle ? 0 : 1 }} onChange={(selection) => this.changeDeviceType(selection.target.value)}>
                {this.state.availableDevicesByDeviceTypeName ? Object.keys(this.state.availableDevicesByDeviceTypeName).map((deviceType) => (<option key={deviceType} value={deviceType}>{deviceType}</option>)) : null}
              </select>
              {this.state.barcodeToggle && (
                <div
                  style={{
                    width: 350,
                    height: 262,
                    position: "absolute",
                    top: "112px",
                    left: "2px",
                    zIndex: "2",
                  }}
                >
                  <Scanner
                    fps={300}
                    disableFlip={false}
                    qrbox={{ width: 300, height: 100 }}
                    qrCodeSuccessCallback={this.onNewScanResult}
                    supportedScanTypes={["SCAN_TYPE_CAMERA"]}
                    experimentalFeatures={{
                      useBarCodeDetectorIfSupported: true,
                    }}
                  />
                  {this.state.barcodeError && (
                    <span
                      style={{
                        position: "absolute",
                        top: "191px",
                        left: "9px",
                        color: "red",
                        backgroundColor: "#ffffff",
                        padding: "20px 5px",
                        fontSize: "12px",
                        fontWeight: "700",
                        width: "333px",
                        textAlign: "center",
                      }}
                    >
                      {this.state.barcodeError} <button onClick={() => { this.setState({barcodeError: false}) }}>X</button>
                    </span>
                  )}
                </div>
              )}
              <div style={{ display: "flex", paddingTop: 15, height: "80%" }}>
                <div
                  style={{
                    width: "40%",
                    height: "100%",
                    opacity: this.state.barcodeToggle ? 0 : 1,
                  }}
                >
                  Available Devices: <br />
                  <input
                    type="text"
                    name="availableFilter"
                    value={this.state.currentAvailableFilter}
                    placeholder="Filter"
                    style={{ marginTop: 5, marginBottom: 5 }}
                    onChange={(e) => this.filterAvailable(e.target.value)}
                  />{" "}
                  <br />
                  <select
                    ref={this.availableDevciesRef}
                    name="availableDevices"
                    id="availableDevices"
                    style={{ width: "100%", height: "100%" }}
                    multiple
                  >
                    {this.state.filteredAvailableDevices
                      ? this.state.filteredAvailableDevices.map((device) => (
                          <option key={device.id} value={device.id}>
                            {device.deviceRadioMacAddress}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div
                  style={{ width: "20%", textAlign: "center", marginTop: 15 }}
                >
                  <a
                    title="Toggle Barcode Scanning"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.toggleShowBarcodeScan()}
                  >
                    <img style={{ width: "50px" }} src="/images/icon/scan.svg" />
                  </a>
                  <br /> <br />
                  <button
                    onClick={() => this.addToSelectedDevices()}
                    style={{ opacity: this.state.barcodeToggle ? 0 : 1 }}
                  >
                    Add &gt;&gt;
                  </button>{" "}
                  <br /> <br />
                  <button
                    onClick={() => this.removeFromSelectedDevices()}
                    style={{ opacity: this.state.barcodeToggle ? 0 : 1 }}
                  >
                    &lt;&lt; Remove
                  </button>
                </div>
                <div style={{ width: "40%", height: "100%" }}>
                  Devices To Add: (
                  {this.state.selectedDevices
                    ? this.state.selectedDevices.length
                    : 0}
                  ) <br />
                  <input
                    type="text"
                    name="selectedFilter"
                    value={this.state.currentSelectedFilter}
                    placeholder="Filter"
                    style={{ marginTop: 5, marginBottom: 5 }}
                    onChange={(e) => this.filterSelected(e.target.value)}
                  />{" "}
                  <br />
                  <select
                    ref={this.selectedDevicesRef}
                    name="selectedDevices"
                    id="selectedDevices"
                    style={{ width: "100%", height: "100%" }}
                    multiple
                  >
                    {this.state.filteredSelectedDevices
                      ? this.state.filteredSelectedDevices.map((device) => (
                          <option key={device.id} value={device.id}>
                            {device.deviceType.deviceFunctionalType.name}:{" "}
                            {device.deviceRadioMacAddress}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.selectedDevices &&
            this.state.selectedDevices.length > 0 ? (
              <Button onClick={() => this.addDeviceCalled()}>
                Add Selected
              </Button>
            ) : null}
            <Button onClick={this.toggleShowAddDeviceModal}>
              {i18next.t("form-labels.cancel")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default FleetManage;
