import React from 'react';
import { Link } from 'react-router-dom';

import { TextInput } from '../TextInput';
import { SelectInput } from '../SelectInput';
import { PageLevelAccess } from '../PageLevelAccess';
import { LocalesService } from '../../services';
import { MarkdownEditor } from '../MarkdownEditor';

/**
 * Notes on how use of Editor has created some constraints:
 *  1) We aren't using redux-form because we can't wire Editor into a Field.
 *     Field's onChange seems to take precendence over Editor's, so we have
 *     no hooks into the changing value in Editor... which we need.
 *  2) We aren't using a form at all. This is due to an issue with Editor's 
 *     insert image button also triggering form submission. This seems to be
 *     due to this button having no type specified so it defaults to submit.
 *  3) Rudimentary validation due to not being able to tap into the powers
 *     of either redux-form or HTML5 given issues 1 and 2, respectively.
 */
class DocumentForm extends React.Component {

    constructor(props) {
      super(props);

      this.state = { 
        missingRequired: false,
        topic: props.document ? props.document.topic : '',
        slug: props.document ? props.document.slug : '',
        sortOrder: props.document ? props.document.sortOrder: '',
        content: props.document ? props.document.content : '',
        locale: props.document && props.document.locale ? {value: props.document.locale.languageTag, label: props.document.locale.description} : null
      }
    }

  componentDidMount() {
    LocalesService.getAll().then(response => {
      this.setState({locales: response.locales.map(locale => {return {value: locale.languageTag, label: locale.description}})});
    }).catch(err => {
      console.error(err);
    })
  }

  onFormSubmit() {
    event.preventDefault();

    if (this.state.topic === '' || this.state.slug === '' || this.state.locale === null) {
      this.setState({missingRequired: true});
      return;
    }

    const document = {
      topic: this.state.topic,
      slug: this.state.slug,
      sortOrder: this.state.sortOrder,
      content: this.state.content,
      locale: this.state.locale.value
    }

    this.props.onSubmit(document);
  }

  handleSlugChange(event) {
    this.setState({slug: event.target.value.split(" ").join("-").toLowerCase()});
  }

  handleContentChange(content) {
    this.setState({content: content()});
  }

  render() {
    if (this.state.locales) {  
        let {topic, slug, sortOrder, locale, locales } = this.state;
        const showMissing = this.state.missingRequired ? <span className="input-error">Topic and Slug and Language Required!</span> : '';
        const deleteButton = this.props.onDelete !== undefined ? <li className="pull-right"><a className="btn btn-danger" onClick={this.props.onDelete}>Delete Document</a></li> : '';
        return (
          <div className="login-form">
              <TextInput
                  input={{value: topic, 
                      onChange: event => this.setState({topic: event.target.value})}}
                  type="text"
                  label="Topic"
                  meta={{}}
              />
              <TextInput
                  input={{value: slug, 
                      onChange: event => this.handleSlugChange(event)}}
                  type="text"
                  label="Slug"
                  meta={{}}
              />
              <TextInput
                  input={{value: sortOrder, 
                      onChange: event => this.setState({sortOrder: event.target.value})}}
                  type="number"
                  label="Sort Order"
                  meta={{}}
              />
              <div className="form-group">
                <label>Language</label>
                <SelectInput
                    input={{
                        value: locale,
                        onChange: event => this.setState({locale: event})
                    }}
                    options={locales}
                    isSearchable={true}
                    meta={{}}
                />
              </div>
              <div className="form-group">
                <div className="op-content-box">
                    <MarkdownEditor
                      readOnly={false}
                      content={this.state.content}
                      onChange={this.handleContentChange.bind(this)} 
                    />
                  </div>
              </div>
              {showMissing}
              <hr />
              <ul className="list-inline clearfix">
                <li><button type="submit" onClick={this.onFormSubmit.bind(this)} className="btn btn-primary">{this.props.document ? 'Update' : 'Save'}</button></li>
                <li><Link to={ this.props.backLinkWithParams ? this.props.backLinkWithParams.link : `/admin-admin/docs`}><button className="btn btn-default">Cancel</button></Link></li>
                <PageLevelAccess to='/admin-admin/docs/edit/:docId#delete'>{deleteButton}</PageLevelAccess>
              </ul>
            </div>
        );
      } else {
        return (<div className="loader">Loading...</div>)
      }
  }
}

export default DocumentForm;
