import React from 'react';

import { PageTitle } from '../PageTitle';
import { EnterprisesService } from '../../services';
import Select from "react-select";
import update from 'immutability-helper';
import { Message } from '../Message';

import ReactTable from "react-table";

class EnterprisePreferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localSuccessMessage: null,
      enterpriseId: props.match.params.enterpriseId
    }
  }

  messageDismissCallback() {
    this.setState({
      localSuccessMessage: null
    });
  }

  componentDidMount() {
    EnterprisesService.getPreferences(this.state.enterpriseId).then(response => {
      this.setState({preferencesToEdit: response.preferences})
    }).catch(error => {
      console.error(error);
      this.state.localErrorHandler(error.message);
    });
  }

  savePreferences() {
    EnterprisesService.savePreferences(this.state.enterpriseId, this.state.preferencesToEdit).then(response => {
      this.setState({localSuccessMessage: "Preferences were saved"})
    }).catch(error => {
      console.error(error);
      this.state.localErrorHandler(error.message);
    });
  }

  handlePreferenceUpdate(event, preference) {
    let data = this.state.preferencesToEdit
    if (data) {
      let index = data.findIndex(prefs => prefs.id === preference.id);
      let updatedPref = null;
      if(preference.stringValue != null) {
        updatedPref = update(data[index], { stringValue: { $set: event.target.value } });
      } else {
        updatedPref = update(data[index], { numericValue: { $set: event.target.value === '' ? null : parseFloat(event.target.value) } });
      }

      this.setState({ preferencesToEdit: update(data, { $splice: [[index, 1, updatedPref]] }) });
    }
  }

  render() {

    if (this.state.preferencesToEdit) {
      return (
        <div className = "enterprise-preferences">
            <div className="row">
            <div className="op-page-header">
              <div className="row">
                <PageTitle title={`Enterprise Preferences`}
                           backLink="/admin-admin/enterprises" />
              </div>
            </div>
          </div>
            <div className = "op-content-box">
              { this.state.localSuccessMessage &&
                <Message
                  messages={{ state: { successMessage: this.state.localSuccessMessage } }}
                  messageDismissCallback={this.messageDismissCallback.bind(this)}
                />
              }
              {
                this.state.preferencesToEdit && this.state.preferencesToEdit.length > 0 ?
                  <div className="grey-box">
                    <div className="col-sm-12 oc-mini-card oc-mini-card">
                      <div className="panel-heading">
                        Preferences
                      </div>
                      <div className="inner metadata-inner form-control">
                        <table className="table table-striped">
                          <thead>
                          <tr>
                            <th>Name</th>
                            <th>Current Value</th>
                            <th>Token</th>
                          </tr>
                          </thead>
                          <tbody>
                          {this.state.preferencesToEdit.map(preference => <PreferenceRow key={preference.id}
                                                                                      preference={preference}
                                                                                    handlePreferenceUpdate = {this.handlePreferenceUpdate.bind(this)}/>)}
                          </tbody>
                        </table>
                        <button className="btn btn-sm btn-default" onClick = {this.savePreferences.bind(this)}> Save </button>
                      </div>
                    </div>
                </div>
                    : <div> No Preferences </div>
              }
            </div>
        </div>
      );
    }
    return (
        <div className="loader">Loading...</div>
    );
  }
}

class PreferenceRow extends React.Component {
  render() {
    let displayValue = this.props.preference.stringValue ? this.props.preference.stringValue : this.props.preference.numericValue;

    return (
        <tr key={this.props.preference.id}>
          <td>{this.props.preference.name}</td>
          <td>
            <input
                type="text"
                name="preferenceValue"
                value={displayValue !== null ? displayValue : ''}
                className="input-sm form-control"
                onChange={(event) => this.props.handlePreferenceUpdate(event, this.props.preference)}
            />
          </td>
          <td>{this.props.preference.token}</td>
        </tr>
    );
  }
}


export default EnterprisePreferences;
