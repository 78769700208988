import React from 'react';
import { Link } from 'react-router-dom';
import ForgotPasswordForm from './forgotpassword_form';
import * as actions from '../../actions/auth_actions';
import { connect } from 'react-redux';
import { ForgotPasswordService } from '../../services';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faUndoAlt from '@fortawesome/fontawesome-free-solid/faUndoAlt';
import i18next from "i18next";

@connect(state => {
    return {
      authenticated: state.auth.authenticated,
      errorMessage: state.auth.error
    }
  }, actions)  
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      localErrorMessage: this.props.errorMessage,
      sentEmail: false
    };

    if (this.props.location && this.props.location.errorMessage) {
      this.state.localErrorMessage = this.props.location.errorMessage;
    }
  }
  
  handleSubmit({ username }) {
    //this.setState({waitingForResult: true});
    ForgotPasswordService.sendEmailInstructions(username).then(response => {
      if (response.action.successful) {
        this.setState({localErrorMessage: null, sentEmail: true});
      }
      else {
        this.setState({localErrorMessage: response.action.message, sentEmail: false});
      }
    }).catch(error => {
      this.setState({localErrorMessage: i18next.t('messages.username-not-recognized'), sentEmail: false});
      console.error(error);
    });
  }

  renderForm() {
    return (
        <div>
          <ForgotPasswordForm onSubmit={ this.handleSubmit.bind(this) } errorMessage={ this.state.localErrorMessage }/>
        </div>
    );
  }

  renderSentEmail() {
    return (
      <div className="login-wrapper">
        <div className="login-form-wrapper">
          <div className="login-form">
            <div className="login-form-branding text-center">
            </div>
            <div className="login-form-alert alert alert-success">
              <strong>{i18next.t('success')}</strong><br />
              {i18next.t('messages.forgot-password-request-success-message')}
            </div>
            <div className="login-form-title">
              {i18next.t('messages.check-your-inbox')}
            </div>
            <div className="login-form-instructions">
              {i18next.t('messages.dont-see-an-email')}
            </div>
            <div className="login-form-forgot-password">
              <Link to="/signin"><FontAwesomeIcon icon={faUndoAlt} />&nbsp;{i18next.t('return-to-login-screen')}</Link>
              &nbsp; &nbsp;
              &nbsp; &nbsp;
              <a href="#" onClick={(event) => { event.preventDefault(); this.setState({localErrorMessage: null, sentEmail: false}); }}>
                <img src="images/icon/send-email-envelope.svg" />&nbsp;
                {i18next.t('messages.resend-email')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.state.sentEmail ? this.renderSentEmail() : this.renderForm();
  }
}

export default ForgotPassword;
