import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import QueryString from 'query-string';
import * as actions from '../../actions/user_actions';
import { Signout, PrivateRoute } from '../../components/Auth';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { TranslatedStyles } from '../../components/TranslatedStyles';
import PATH_TO_COMPONENTS from './component_paths';
import ALL_AVAILABLE_ROUTES from './available_routes';
import "react-table/react-table.css";

const NotFoundRoute = props => {
  console.error(`Route not found: ${props.location.pathname}`);

  if (props.rootPath === props.location.pathname) {
    console.error('Root path will redirect causing a loop');
    return null;
  } else {
    return (<Redirect to='/' />);
  }
};

@connect(state => {
  return {
    authenticated: state.auth.authenticated,
    me: state.user.me
  }
}, actions)
class Main extends React.Component {

  constructor(props) {
    super(props);

    const query = QueryString.parse(this.props.location.search);

    this.state = {
      stripnav: query['stripnav'] === 'true'
    };
  }

  componentDidMount() {
    this.props.getUserMe();
  }

  fillRoutes(routesToRender, subroutes, key, top) {
    subroutes.forEach(route => {
      let menuChildren = route.children ? route.children.filter(subRoute => subRoute.showOnMenu) : [];

      let routeComponent = PATH_TO_COMPONENTS[route.path];
      if (routeComponent) {
        routesToRender.push(<PrivateRoute key={key++} path={route.path} exact={true} component={routeComponent} />);
      } else {
        if (route.path in PATH_TO_COMPONENTS) {
          console.error(`The component is undefined for path: *${route.path}*`);
        } else {
          console.info(`Could not find component for path: *${route.path}*`);
        }
      }

      if (route.children) {
        // If the top menu item is selected this causes a redirect to the first sidebar menu item
        if (top && route.showOnMenu && menuChildren.length > 0) {
          let firstChildRoute = menuChildren[0];
          routesToRender.push(<Route key={key++} path={route.path} exact={true} render={() => <Redirect to={firstChildRoute.path} />} />);
        }
        key = this.fillRoutes(routesToRender, route.children, key, false);
      }
    });
    return key;
  }

  renderRoutes(availableRoutes) {
    let topRoutes = [].concat(...availableRoutes.map(topRoute => topRoute.children ? topRoute.children.filter(subRoute => subRoute.showOnMenu) : []));
    if (topRoutes.length == 0) {
      console.error('No available top routes, this will be a problem.');
    }

    var key = 0;
    var routesToRender = [
      <Route key={key++} path='/' exact={true} render={() => <Redirect to={topRoutes[0].path} />} />,
      <Route key={key++} path='/signout' exact={ true } component={ Signout } />
    ];

    key = this.fillRoutes(routesToRender, availableRoutes, key, true);

    routesToRender.push(<Route key={key++} render={(props) => <NotFoundRoute {...props} rootPath={topRoutes[0].path} />} />);

    return routesToRender;
  }

  render() {
    if (!this.props.authenticated) {
      localStorage.removeItem('token');
      return (
        <Redirect to={{
          pathname: '/signin',
          state: {
            from: this.props.location,
            message: 'You need to sign in'
          }
        }}/>
      );
    } else {
      if (this.props.me) {
        return this.state.stripnav ? (
          <div style={{backgroundColor:"white",width: "100%", height: "100%"}}>
            <Switch>
              {this.renderRoutes(ALL_AVAILABLE_ROUTES)}
            </Switch>
          </div>
          ) : (
          <div className="app">
            <TranslatedStyles/>

            <div className="top-menu-bg"></div>

            <div className="sub-menu-bg"></div>

            <Header availableRoutes={ALL_AVAILABLE_ROUTES} />

            <div className={`l-wrapper`}>
              <div className="l-wrapper__content">
                <Switch>
                  {this.renderRoutes(ALL_AVAILABLE_ROUTES)}
                </Switch>
              </div>
            </div>

            <Footer />
          </div>
        );
      }
    }

    return <div className="loader">Loading...</div>;
  }
}

export default Main;
