import React from 'react';
import { reduxForm, Field } from 'redux-form';
import i18next from 'i18next';
import { TextInput } from '../../TextInput';
import { CheckboxInput } from '../../CheckboxInput';

// TODO this could be refactored
const validate = values => {
  const errors = {}
  const isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email) {
    errors.email = i18next.t('messages.please-enter-email');
  }else if (!isValidEmail.test(`${values.email}`)) {
    errors.email = `'${values.email}' ${i18next.t('messages.is-not-a-valid-email-address')}`;
  }
  return errors
}

class NotificationAddEmail extends React.Component {
  handleCancel() {
    this.props.onCancel();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return <div className="alert alert-danger">
        <strong>{i18next.t('error')}: </strong>{this.props.errorMessage}
      </div>
    }
  }

  render() {
    const {handleSubmit} = this.props

    return (
      <div className="login-form">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Field
                  name="email"
                  type="text"
                  component={TextInput}
                  label={i18next.t('form-labels.email')}
                />
              <Field
                className="inline"
                name="textOnlyEmail"
                type="checkbox"
                component={CheckboxInput}
                label={i18next.t('form-labels.text-only-emails')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 col-md-4"></div>
              <div className="col-sm-4 col-md-4" style={{marginBottom: '5px'}}>
                <button type="submit" className="btn btn-primary btn-block">{i18next.t('form-labels.submit')}</button>
              </div>
              <div className="col-sm-4 col-md-4">
                <button type="button" onClick={this.handleCancel.bind(this)} className="btn btn-primary btn-block">{i18next.t('form-labels.cancel')}</button>
              </div>
            </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'NotificationAddEmail', validate
})(NotificationAddEmail);
