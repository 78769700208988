import React from 'react';

const TextInput = ({ input, className, label, type, textarea, readOnly, login, login_label, meta: { touched, error, warning, invalid } }) => {
  let textareaType = <textarea {...input} placeholder={label} type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} readOnly={readOnly} />;
  let inputType = <input {...input} placeholder={label} type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} readOnly={readOnly} />;
  if (login) {
    textareaType = <textarea {...input} placeholder={label} type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} readOnly={readOnly} autoComplete={login_label} />;
    inputType = <input {...input} placeholder={label} type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} readOnly={readOnly} autoComplete={login_label} />;
  }

  return (
    <div className={`${className ? `${className}` : 'form-group'}${touched && error ? ' has-error' : ''}`} >
      {label && (
        <label>{label}</label>
      )}
      <div>
        {textarea ? textareaType : inputType}
        {touched && ((error && <span className="input-error">{error}</span>) || (warning && <span className="input-warning">{warning}</span>))}
      </div>
    </div>
  );
};

export default TextInput;
