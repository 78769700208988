import React from 'react';
import ReactTable from "react-table";
import { Redirect } from 'react-router-dom';

import { PageTitle } from '../PageTitle';
import { Message } from '../Message';
import { EnterprisesService } from '../../services';

class Enterprises extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      localErrorMessage: null
    };
  }

  messageDismissCallback() {
    this.setState({ localErrorMessage: null });
  }

  renderMessages() {
    if (this.state.localErrorMessage) {
      return (
          <Message errorMessage={this.state.localErrorMessage} messageDismissCallback={this.messageDismissCallback.bind(this)} />
      );
    }

    if (this.props.location.state) {
      return (
          <Message messages={this.props.location} />
      );
    }
    return null;
  }

  handleLocalError(localErrorMessage) {
    this.setState({ localErrorMessage: localErrorMessage });
  }

  render() {
    return (
        <div className="animated fadeIn">
          <div className="row">
            <PageTitle 
              title="Enterprises"
              addLink={{link: {pathname: "/admin-admin/enterprises/add"}}}
            />
          </div>

          {this.renderMessages()}

          <div className="op-content-box">
            <div className="row">
              <div className="col-sm-12">

                  <EnterpriseList localErrorHandler={this.handleLocalError.bind(this)} />

                </div>
              </div>
          </div>
        </div>
    );
  }
}

class EnterpriseList extends React.Component {

  constructor(props) {
    super(props);
    this.refresh = this.refresh.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.datafetched = this.datafetched.bind(this);
    this.makeDatatableRequest = this.makeDatatableRequest.bind(this);

    this.state = {
      linkTo: null,
      localErrorHandler: this.props.localErrorHandler,
      data: [],
      pages: null,
      loading: true,
      totalElements: null,
    };
  }

  viewClicked(id) {
    this.setState({ linkTo: `/admin-admin/enterprises/view/${id}`});
  }

  editClicked(id) {
    this.setState({ linkTo: `/admin-admin/enterprises/edit/${id}`});
  }

  editPreferencesClicked(id) {
    this.setState({ linkTo: `/admin-admin/enterprises/${id}/preferences`});
  }

  viewCellUsageClicked(id) {
    this.setState({ linkTo: `/admin-admin/enterprises/${id}/cellular`});
  }

  renderLink(data) {
    return (
      <div className="text-right">
            <button className="rowLink btn btn-sm btn-default"
                    onClick={() => this.viewClicked(data.original.id)}>View
          </button>
            &nbsp;
            <button className="rowLink btn btn-sm btn-default"
                    onClick={() => this.editClicked(data.original.id)}>Edit
          </button>
            &nbsp;
            <button className="rowLink btn btn-sm btn-default"
                    onClick={() => this.editPreferencesClicked(data.original.id)}>Edit Preferences
          </button>
            &nbsp;
            <button className="rowLink btn btn-sm btn-default"
                    onClick={() => this.viewCellUsageClicked(data.original.id)}>View Cellular Usage
          </button>
      </div>
    );
  }

  refresh () {
    if (this.refs.enterprisesTable) {
        this.fetchData(this.refs.enterprisesTable)
    }
  }

  makeDatatableRequest (data, callback, settings) {
    let options = [
      { 'size': data.pageSize },
      { 'page': data.page }
    ];

    let neworder = data.sorted ? data.sorted :
                                  this.refs.enterprisesTable ? this.refs.enterprisesTable.state.sorted :
                                                           null;

     if (neworder && neworder.length > 0) {
       neworder.forEach(order => {
         options.push({ 'sort': `${order.id},${order.desc ? "desc":"asc"}` });
       })
     }

    let isSearch = false;
    if (data.search && data.search.value != null && data.search.value !== '') {
      options.push({ 'search': data.search.value });
      isSearch = true;
    }

    EnterprisesService.getAllPageable(options, isSearch).then(response => {
      callback({
        data: response.enterprises,
        recordsTotal: response.page.totalElements,
        recordsFiltered: response.page.totalElements
      });
    }).catch(error => {
      console.log(error);
      this.state.localErrorHandler(error.message);
    });
  }

  datafetched(res) {
    let size = this.refs.enterprisesTable.state.pageSize;
    let page = this.refs.enterprisesTable.state.page;
    this.setState({
      data: res.data,
      pages: Math.ceil(res.recordsTotal / size),
      page: page,
      pageSize: size,
      loading: false,
      totalElements: res.recordsTotal
    });
  }
  
  fetchData(state, instance) {
    this.setState({
      loading: true
    });
    this.makeDatatableRequest(state, this.datafetched, null);
  }

  render() {
    const { data, pages, loading, totalElements } = this.state;
    if (this.state.linkTo) {
      return <Redirect push to={this.state.linkTo} />;
    }

    return (
        <div className="react-table-custom">
          <ReactTable
            className="-striped -highlight"
            ref="enterprisesTable"
            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
            data={data}
            pages={pages} // Display the total number of pages
            loading={loading} // Display the loading overlay when we need it
            onFetchData={this.fetchData} // Request new data when things change
            totalElements={totalElements}
            showPaginationTop
            columns={[
                        {
                          Header: "Name",
                          accessor: "name",
                          sortable: true,
                          minResizeWidth: 250
                        },
                        {
                          Cell: cell => this.renderLink(cell),
                          sortable: false,
                          minResizeWidth: 250
                        }
                      ]}
          />
        </div>
    )
  }
}

export default Enterprises;
