import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class AuthenticationService {

  static authenticate(username, password) {
    return axios.post(`${ROOT}auth`, { username, password });
  }

}

export default AuthenticationService;
