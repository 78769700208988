import React from 'react';
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { TextInput } from '../TextInput';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faUndoAlt from '@fortawesome/fontawesome-free-solid/faUndoAlt';
import i18next from "i18next";
import { ValidationHelper } from '../../helpers';

const validate = values => {
  const errors = {}
  if (!values.username) {
    errors.username = i18next.t('messages.please-enter-user-name')
  } else if (!ValidationHelper.validateUsername(values.username)) {
    errors.username = i18next.t('messages.username-invalid');
  }
  
  return errors
}
  
class ForgotPasswordForm extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string
  }

  constructor(props) {
    super(props);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return <div className="login-form-alert alert alert-danger">
        <strong>{i18next.t('error')}</strong><br />
        {this.props.errorMessage}
      </div>
    }
  }

  render() {
    const {handleSubmit} = this.props
  
    return (
      <div className="login-wrapper">
        <div className="login-form-wrapper">
          <div className="login-form">
            <div className="login-form-branding text-center">
            </div>
            {this.renderAlert()}
            <div className="login-form-title">
              {i18next.t('pages.forgot-password.forgot-password')}
            </div>
            <div className="login-form-instructions">
              {i18next.t('pages.forgot-password.instructions')}
            </div>
            <form onSubmit={handleSubmit}>
              <div>
              <Field
                  name="username"
                  type="text"
                  component={TextInput}
                  label={i18next.t('form-labels.username')}
                />
              </div>
              <button type="submit" className="btn btn-primary btn-block">{i18next.t('form-labels.submit')}</button>
            </form>
            <div className="login-form-forgot-password">
              <Link to="/signin"><FontAwesomeIcon icon={faUndoAlt} />&nbsp;{i18next.t('return-to-login-screen')}</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'forgotpassword', validate
})(ForgotPasswordForm)
  