import React from 'react';
import { connect } from 'react-redux';
import QueryString from 'query-string';
import ReactTable from "react-table";
import { Redirect } from 'react-router-dom';
import { RowButton } from '../RowButton';
import { AccessHelper, BaseTable } from '../../helpers';
import DocumentsService from '../../services/documents_service';

@connect(state => {
  return {
    me: state.user.me
  }
})
class DocumentList extends React.Component {
  constructor(props) {
      super(props);
      
      this.DEFAULT_SORTED = [{ id: 'topic', desc: false }];

      this.fetchData = this.fetchData.bind(this);
      this.datafetched = this.datafetched.bind(this);
      this.makeDatatableRequest = this.makeDatatableRequest.bind(this);

      this.state = {
          linkTo: null,
          localErrorHandler: this.props.localErrorHandler,
          localErrorMessage: null,
          data: [],
          pages: null,
          loading: true,
          totalElements: null,
          parsedOptions: QueryString.parse(this.props.currSearch),
          editAllowed: AccessHelper.userHasAccess(this.props.me.availableRoutes, '/admin-admin/docs/edit/:docId'),
      }
  }

  editLinkClick(rowId) {
      this.setState({
        linkTo: `/admin-admin/docs/edit/${rowId}`
      });
    }

  datafetched(res) {
      let size = this.refs.documentsTable.state.pageSize;
      let page = this.refs.documentsTable.state.page;
      const pageCount = Math.ceil(res.recordsTotal / size)
      let forcePage = null;
      if (page >= pageCount && pageCount > 0) {
        forcePage = pageCount - 1;
      }
  
      this.setState({
        data: res.data,
        pages: pageCount,
        page: page,
        pageSize: size,
        forcePage: forcePage,
        loading: false,
        totalElements: res.recordsTotal
      });
    }
    
  fetchData(tableData) {  
    if (this.updateRequestURL(tableData)) {
      return;
    }
      
    this.setState({
      loading: true
    });

    this.makeDatatableRequest(tableData, this.datafetched, null);
  }
  
  makeDatatableRequest (data, callback, settings) {

    let options = BaseTable.buildFetchDataOptions(this.state.parsedOptions, this.DEFAULT_SORTED);
    
    DocumentsService.getAllPageable(options).then(response => {
        callback({
            data: response.documents,
            recordsTotal: response.page.totalElements,
            recordsFiltered: response.page.totalElements
        });
    }).catch(error => {
        console.error(error);
        this.setState({ hasError: true });
        this.state.localErrorHandler(error.message);
    });
  }

  updateRequestURL(tableData) {
    let options = BaseTable.buildURLOptions(tableData, this.DEFAULT_SORTED);

    let params = options.map(option => Object.keys(option).map((k, i) => `${k}=${option[k]}`)).join('&');

    const currentSearch = this.props.currSearch ? this.props.currSearch : '?';

    if (`?${params}` !== currentSearch) {
      if (this.state.currentHistoryCount > 100) {
        console.error('Exceeded the history count stack of 100.');
        return false;
      }
      this.props.pushHistory({
        search: `?${params}`,
        state: {
          currentHistoryCount: this.state.currentHistoryCount + 1,
          groupFilterOptions: this.state.groupFilterOptions,
          selectedFilterByOption: this.state.selectedFilterByOption
        }
      });
      return true;
    } else {
      return false;
    }
  }
  
  render() {
      const { data, pages, loading, totalElements } = this.state;
  
      if (this.state.linkTo) {
        if (this.state.query) {
          return <Redirect push to={{ pathname: this.state.linkTo, query: this.state.query, state: { backLinkSearch: this.state.backLinkSearch }}} />;
        } else {
          return <Redirect push to={{ pathname: this.state.linkTo, state: { backLinkSearch: this.state.backLinkSearch }}} />;
        }
      }
  
      let dynamicOptions = BaseTable.buildDynamicOptions(this.state.parsedOptions, this.state.forcePage, this.DEFAULT_SORTED);
  
      return (
          <div>
            <div className="react-table-custom">
              <ReactTable
                className="-striped -highlight"
                ref="documentsTable"
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                data={data}
                pages={pages} // Display the total number of pages
                loading={loading} // Display the loading overlay when we need it
                onFetchData={this.fetchData} // Request new data when things change
                totalElements={totalElements}
                defaultSorted={[{
                  id: 'topic',
                  desc: false // not necessary unless set to true, but it's good to know its there.
                }]}
                showPaginationTop
                columns={[
                            {
                              Header: "Topic",
                              id: "topic",
                              accessor: "topic",
                              sortable: true,
                              minResizeWidth: 50
                            },
                            {
                              Header: "Slug",
                              id: "slug",
                              accessor: "slug",
                              sortable: true,
                              minResizeWidth: 50
                            },
                            {
                              Header: "Sort Order",
                              id: "sortOrder",
                              accessor: "sortOrder",
                              Cell: cell => cell.original.sortOrder ? cell.original.sortOrder : 'N/A',
                              sortable: true,
                              minResizeWidth: 50
                            },
                            {
                              Header: "Language",
                              id: "locale",
                              accessor: "locale.description",
                              sortable: true,
                              minResizeWidth: 50
                            },
                            {
                              Cell: cell => <RowButton
                                                allowEdit = {this.state.editAllowed}
                                                editClick = {this.editLinkClick.bind(this)}
                                                rowId = {cell.original.id} />,
                              sortable: false,
                              minResizeWidth: 50,
                              width: 230
                            }
                          ]}
                {...dynamicOptions}
              />
            </div>
          </div>
        );
      }
    }

export default DocumentList;