import React, { useEffect, useState } from "react";
import Select from "react-select";
import i18next from "i18next";

const InventoryForm = ({template, locations, preSelectedLocation, vendorOrders, preSelectedVendorOrder, onSuccess}) => {
    const [metadataInputValues, setMetadataInputValues] = useState({});
    const [selectedLocation, setSelectedLocation] = useState(preSelectedLocation);
    const [selectedVendorOrder, setSelectedVendorOrder] = useState(preSelectedVendorOrder);
    const [uploadFiles, setUploadFiles] = useState({});
    const [scanDetail, setScanDetail] = useState(null);

    useEffect(() => {
        if (template) {
            setMetadataInputValues({});
            setUploadFiles({});
            setSelectedLocation(preSelectedLocation);
            setSelectedVendorOrder(preSelectedVendorOrder);

            if (template.scanInfo.type === "qr") {
                const sd = [];
                template.scanInfo.format.split(",").forEach((scanInfoItem) => {
                    const metadataInputToken = scanInfoItem.replace("${", "").replace("}", "");
                    const scanInfoMDI = template.metadataInputs[metadataInputToken];
                    if (scanInfoMDI?.validationRegex?.javascript) {
                        sd.push({ metadataInputToken: metadataInputToken, matchRegex: scanInfoMDI.validationRegex.javascript });
                    }
                });
                if (sd.length > 0) {
                    setScanDetail(sd);
                }
            }
        }
    }, [template, preSelectedLocation, preSelectedVendorOrder]);

    const requiredKeys = Object.keys(template.metadataInputs).map((inputToken) => inputToken).filter((inputToken) => {
        return Object.values(template.requiredMetadata).find((rm) => rm.includes(`\${${inputToken}}`));
    });

    const updateMIValue = (inputToken, value) => {
        const updated = {...metadataInputValues};
        if (value.includes(",") && scanDetail) {
            const splitValues = value.split(",");
            if (splitValues.length === scanDetail.length) {
                let allMatch = true;
                for(var i=0; i<splitValues.length && allMatch; i++ ) {
                    if (!splitValues[i].match(scanDetail[i].matchRegex)) {
                        allMatch = false;
                    }
                }
                
                if(allMatch) {
                    for(var i=0; i<splitValues.length && allMatch; i++ ) {
                        updated[scanDetail[i].metadataInputToken] = splitValues[i];
                    }
                } else {
                    updated[inputToken] = value;
                }
            }
        } else {
            updated[inputToken] = value;
        }

        setMetadataInputValues(updated);
    };

    const updateFileMIValue = (inputToken, file) => {
        const filesUpdated = {...uploadFiles};
        const updated = {...metadataInputValues};
        
        updated[inputToken] = `file:${file.name}`;

        filesUpdated[inputToken] = file;
        setUploadFiles(filesUpdated);
        setMetadataInputValues(updated);
    };

    const isReadyToSave = () => {
        const hasAllRequiredKeys = requiredKeys.every((key) => Object.keys(metadataInputValues).includes(key));
        const hasValidFields = Object.keys(metadataInputValues).every((key) => {
            const regex = template.metadataInputs[key].validationRegex?.javascript;
            if (regex) {
                return metadataInputValues[key].match(regex);
            } else {
                return true;
            }
        });

        return hasAllRequiredKeys && hasValidFields;
    };

    const doSave = () => {
        const saveValue = {
            "device_type_token" : template.deviceTypeToken
        };
        
        Object.keys(metadataInputValues)
              .forEach((key) => {
                    if(metadataInputValues[key] != null && metadataInputValues[key] != '') {
                        saveValue[key] = metadataInputValues[key];
                    }
              });

        if (selectedLocation) {
            saveValue["device_inventory_location"] = selectedLocation.value;
        }

        if (selectedVendorOrder) {
            saveValue["vendor_order_id"] = `${selectedVendorOrder.value}`;
        }

        onSuccess(saveValue, uploadFiles, { selectedLocation: selectedLocation, selectedVendorOrder: selectedVendorOrder });
    };

    return (
        <div className="row" style={{paddingTop: 10}}>
            <div className="col-sm-12 col-md-12 col-lg-12">
                {
                    Object.keys(template.metadataInputs).map((inputToken, index) => (
                        <div key={inputToken} className="row" style={{paddingTop: 10}}>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <label>{template.metadataInputs[inputToken].displayName} {requiredKeys.includes(inputToken) ? "(required)" : ""}</label>
                                { template.metadataInputs[inputToken].valueType === "calibration_image" ? 
                                    <input 
                                        type="file" 
                                        name={`mi_${inputToken}`} 
                                        onChange={({target}) => updateFileMIValue(inputToken, target.files[0])} 
                                        className="input-sm form-control"
                                        autoFocus={index === 0}
                                    />
                                    :
                                    <input 
                                        type="text" 
                                        name={`mi_${inputToken}`} 
                                        onChange={({target}) => updateMIValue(inputToken, target.value)} 
                                        value={metadataInputValues[inputToken]}
                                        className="input-sm form-control" 
                                        placeholder={template.metadataInputs[inputToken].examples?.join(" or ")}
                                        autoFocus={index === 0}
                                    />
                                }
                            </div>
                        </div>
                    ))
                }
                    <div className="row"  style={{paddingTop: 10}}>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label>Location</label>
                            <Select
                                options={locations}
                                value={selectedLocation}
                                onChange={setSelectedLocation}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder={i18next.t("select")}
                            />
                        </div>
                    </div>

                    <div className="row"  style={{paddingTop: 10}}>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label>Vendor Order</label>
                            <Select
                                options={vendorOrders}
                                value={selectedVendorOrder}
                                onChange={setSelectedVendorOrder}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder={i18next.t("select")}
                            />
                        </div>
                    </div>

                    <div className="row" style={{paddingTop: 10}}>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <button className="btn btn-sm btn-primary pull-right" onClick={doSave} disabled={!isReadyToSave()}>Save</button>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default InventoryForm;
