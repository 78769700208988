import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { TextInput } from '../TextInput';
import i18next from "i18next";

const validate = values => {
  const errors = {}

  if (!values.newPassword) {
    errors.newPassword = i18next.t('pages.forgot-password.enter-new-password')
  }

  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = i18next.t('pages.forgot-password.repeat-new-password')
  }

  if (values.newPassword && values.confirmNewPassword && values.newPassword != values.confirmNewPassword) {
    errors.newPassword = i18next.t('messages.passwords-do-not-match')
  }

  return errors
}

class ForgotPasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return <div className="login-form-alert alert alert-danger">
        <strong>{ i18next.t('error') }</strong><br />
        {this.props.errorMessage}
      </div>
    }
  }

  render() {
    const {handleSubmit} = this.props

    return (
      <div className="login-wrapper">
        <div className="login-form-wrapper">
          <div className="login-form">
            <div className="login-form-branding text-center">
            </div>
            {this.renderAlert()}
            <div className="login-form-title">
              { i18next.t('form-labels.reset-password') }
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                <Field
                    name="newPassword"
                    type="password"
                    component={TextInput}
                    label={ i18next.t('form-labels.new-password') }
                />
              </div>
              <div>
                <Field
                    name="confirmNewPassword"
                    type="password"
                    component={TextInput}
                    label={ i18next.t('form-labels.repeat-new-password') }
                />
              </div>
              <button type="submit" className="btn btn-primary btn-block">{ i18next.t('form-labels.submit') }</button>
            </form>
            <div className="login-form-forgot-password">
              <Link to="/signin">{ i18next.t('pages.forgot-password.log-in-instead') }</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'forgotpasswordreset', validate
})(ForgotPasswordResetForm)
  