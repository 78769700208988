import React from 'react';
import { reduxForm, Field } from 'redux-form';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { TextInput } from '../TextInput';
import { CheckboxInput } from '../CheckboxInput';

const validate = (values) => {
    const errors = {}
    
    if (!values.name) {
      errors.name = "Name is required"
    } 
    
    return errors
};

class EnterpriseGroupForm extends React.Component {
    constructor(props) {
        super(props);

        let { group } = this.props;

        this.props.initialize({
            uuid: group?.uuid ? group.uuid : uuidv4(),
            name: group?.name ? group.name : '',
            enableFloorPlan: group?.enableFloorPlan ? group.enableFloorPlan : false,
            requiresTimezone: group?.requiresTimezone ? group.requiresTimezone : false,
            isRoot: props.parentGroupName == null, 
            children: group?.children ? group.children : []
        });
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
            {
                this.props.parentGroupName != null &&
                <div>
                    <label>Parent Group</label>
                    <div>
                        {this.props.parentGroupName}
                    </div>
                </div>
            }

                <div>
                    <Field
                        name="name"
                        label="Name"
                        type="text"
                        component={TextInput}
                    />
                </div>                
                <div className="add-margin-top">
                    <Field 
                        name="requiresTimezone"
                        type="checkbox"
                        className="inline"
                        component={CheckboxInput}
                        label="Requires Timezone"
                    />
                </div>
                <div className="add-margin-top">
                    <Field 
                        name="enableFloorPlan"
                        type="checkbox"
                        className="inline"
                        component={CheckboxInput}
                        label="Enable Floor Plan"
                    />
                </div>

                <hr/>
                
                <ul className="list-inline clearfix">
                    <li><button type="submit" className="btn btn-primary btn-block">{i18next.t('form-labels.save')}</button></li>
                    <li><button type="button" className="btn btn-default" onClick={this.props.onCancel.bind(this)}>{i18next.t('form-labels.cancel')}</button></li>
                </ul>
            </form>
        )
    }
}

export default reduxForm({ form: 'EnterpriseGroupForm', destroyOnUnmount: false, validate })(EnterpriseGroupForm);