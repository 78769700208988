import React, { useEffect, useState } from "react";
import { PageTitle } from "../PageTitle";
import { OrdersService, TimeFormattingService } from "../../services";

const ShipToArea = ({order}) => (
    <div className='col-xs-6 col-sm-6 col-md-6'>
        <label>Ship To Information</label>
        <p>
            {order.shippingToName} <br/>
            {order.shippingAttention} {order.shippingAttention && <br/>}
            {order.shippingPhone} {order.shippingPhone && <br/>}
            {order.shippingAddressLineOne} <br/>
            {order.shippingAddressLineTwo} {order.shippingAddressLineTwo && <br/>}
            {order.shippingAddressCity}, {order.shippingAddressState} {order.shippingAddressZip}
        </p>
    </div>
);

const ExtraInfoArea = ({extraInfo}) => (
    <div className='col-xs-6 col-sm-6 col-md-6'>
        <label>Extra Values</label>
        <ul style={{listStyleType:"none", pading:0, margin:0}}>
        {extraInfo.map((value, index) => (
            <li key={index}>
                {value.key}: {value.value}
            </li>
        ))}
        </ul>
    </div>
);

const ItemArea = ({items}) => (
  <div className='row' style={{paddingTop: 20}}>
    <div className='col-xs-12 col-sm-12 col-md-12'>
      <label>Ordered Items</label>
      <table style={{width:"100%"}}>
        <thead style={{backgroundColor: "#aaa"}}>
          <tr>
            <th style={{padding:5, border:"1px #000 solid", width: "10%"}}>Quantity</th>
            <th style={{padding:5, border:"1px #000 solid", width: "10%"}}>SKU</th>
            <th style={{padding:5, border:"1px #000 solid", width: "60%"}}>Item</th>
            <th style={{padding:5, border:"1px #000 solid", width: "20%"}}>Device Types</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td style={{padding:5, border:"1px #000 solid"}}>{item.quantity}</td>
              <td style={{padding:5, border:"1px #000 solid"}}>{item.sku}</td>
              <td style={{padding:5, border:"1px #000 solid"}}>{item.description}</td>
              <td style={{padding:5, border:"1px #000 solid"}}>{item.deviceTypes?.map((deviceType) => deviceType.name).join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const downloadOrderAttachment = (reference) => {
    OrdersService.getOrderAtachmentLink(reference)
        .then(({ url }) => {
            window.open(url, "_blank");
        })
        .catch((err) => {
            console.error(err);
        });
};

const AttachmentArea = ({attachments}) => (
  <div className='row' style={{paddingTop: 20}}>
    <div className='col-xs-12 col-sm-12 col-md-12'>
      <label>Attachments</label>
      <table style={{width:"100%"}}>
        <thead style={{backgroundColor: "#aaa"}}>
          <tr>
            <th style={{padding:5, border:"1px #000 solid", width: "90%"}}>Description</th>
            <th style={{padding:5, border:"1px #000 solid", width: "10%"}}></th>
          </tr>
        </thead>
        <tbody>
          {attachments.map((attachment, index) => (
            <tr key={index}>
              <td style={{padding:5, border:"1px #000 solid"}}>{attachment.description}</td>
              <td style={{padding:5, border:"1px #000 solid"}}><button onClick={() => downloadOrderAttachment(attachment.reference)} className="btn btn-sm btn-link">Download</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const OrderView = ({ match }) => {
    const [displayData, setDisplayData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setLoading(true);

        OrdersService.getOrder(match?.params?.orderId)
            .then(({order}) => {
                setDisplayData(order);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setErrorMessage("Error loading order");
                setLoading(false);
            });
    }, [match]);

    return loading ? (<div className='loader'>Loading...</div>) : (
        <div className="animated fadeIn">
            <div className="row">            
                <PageTitle title="View Order" editLink={`/admin-fulfillment/orders/${match?.params?.orderId}/edit`} />
            </div>
            <div className="op-content-box">
                {errorMessage && <div>{errorMessage}</div>}

                {displayData && 
                <div> 
                    <div className="row">
                        <div className="col-sm-2">
                            <label>Status</label>
                            {displayData.orderStatus.name}
                        </div>
                        <div className="col-sm-2">
                            <label>Order Number</label>
                            {displayData.displayNumber}
                        </div>                        
                        { displayData.enterprise && <div className="col-sm-3">
                            <label>Customer</label>
                            {displayData.enterprise.name}
                        </div>}
                        { displayData.deviceInventoryLocation && <div className="col-sm-3">
                            <label>Fulfillment By</label>
                            {displayData.deviceInventoryLocation.name}
                        </div>}
                    </div>
                    <div className="row" style={{paddingTop: 20}}>
                        <div className="col-sm-4">
                            <label>Contact</label>
                            {displayData.contactName} <br/> {displayData.contactEmail}
                        </div>
                        <div className="col-sm-3">
                            <label>Created</label>
                            {TimeFormattingService.renderNormalTime(displayData.createdAt, null)}
                        </div>
                        <div className="col-sm-3">
                            <label>Last Updated</label>
                            {TimeFormattingService.renderNormalTime(displayData.lastUpdatedAt, null)}
                        </div>
                    </div>

                    <div className='row' style={{paddingTop: 20}}>
                        <ShipToArea order={displayData} />

                        {displayData.extraValues && <ExtraInfoArea extraInfo={displayData.extraValues} />}
                    </div>

                    {displayData.orderItems && <ItemArea items={displayData.orderItems} />}
                    {displayData.orderAttachments && <AttachmentArea attachments={displayData.orderAttachments} />}
                </div>
                }
            </div>
        </div>
    );
};

export default OrderView;