import React from 'react';
import i18next from 'i18next';
import QueryString from 'query-string';

import ReactTable from 'react-table';

import { PageTitle } from "../PageTitle";
import { Message } from '../Message';
import { BaseTable } from '../../helpers';
import { DevicesService, TimeFormattingService } from "../../services";

class UnregisteredDevices extends React.Component {
  constructor(props) {
    super(props);

    const passedState = props.history.location ? props.history.location.state : null;

    this.DEFAULT_SORTED = [];

    const parsedOptions = QueryString.parse(this.props.location.search);

    this.state = {
      searchValue: parsedOptions && parsedOptions.serialNumber ? parsedOptions.serialNumber : "",
      data: null,
      allData: null,
      pageCount: 0,
      totalElements: 0,
      forcePage: null,
      parsedOptions: parsedOptions,
      searching: false,
      currentHistoryCount: passedState && passedState.currentHistoryCount ? passedState.currentHistoryCount : 0,
      tableData: null,
      localErrorMessage: null,
      localSuccessMessage: null, 
    };
  }

  handleLocalSuccess(localSuccessMessage) {
    this.setState({localSuccessMessage: localSuccessMessage});
  }

  localErrorHandler(localErrorMessage) {
    this.setState({ localErrorMessage: localErrorMessage });
  }

  messageDismissCallback() {
    this.setState({
      localErrorMessage: null,
      localSuccessMessage: null
    });
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({searching: true});

    DevicesService.findUnregisteredBySerialNumber().then(response => {
      this.setState({
        data: response.devices,
        allData: response.devices,
        searching: false
      });
    }).catch(error => {
      console.error(error);
      this.localErrorHandler(error.message);
      this.setState({searching: false, hasError: true});
    });
  }

  doSearch(searchInputValue) {
    const updatedValue = searchInputValue.target.value;
    this.setState({
      searchValue: updatedValue,
      data: this.state.allData.filter(device => device.serial.includes(updatedValue))
    });
  }

  renderNormalTime(data) {
    const timezone = data.original.deviceGrouping && data.original.deviceGrouping.timezone ? data.original.deviceGrouping.timezone : null;
    const date = TimeFormattingService.renderDate(data.value, timezone);
    const time = TimeFormattingService.renderTime(data.value, timezone);
    return (<span>{date} {time}</span>);
  }

  render() {
    let dynamicOptions = BaseTable.buildDynamicOptions(this.state.parsedOptions, this.state.forcePage, this.DEFAULT_SORTED);
    var requiresForcedMove = false;
    if (this.state.deviceToMove && this.state.deviceToMoveEvaluation) {
      requiresForcedMove = this.state.deviceToMoveEvaluation.groupingName || this.state.deviceToMoveEvaluation.tagCount > 0 || this.state.deviceToMoveEvaluation.alertCount > 0;
    }

    return (
      <div className = "admin-devices">
        <div className="row">
          <PageTitle title={ i18next.t('menus.from-router.Unregistered devices') } refreshFunction={this.refresh.bind(this)} />
        </div>
        
        { this.state.localErrorMessage ? <Message errorMessage={ this.state.localErrorMessage } messageDismissCallback={ this.messageDismissCallback.bind(this) } /> : null }

        { this.state.localSuccessMessage ? <Message messages={{ state: { successMessage: this.state.localSuccessMessage } }} messageDismissCallback={ this.messageDismissCallback.bind(this) } /> : null}

        <div className="inner op-content-box">
          <div className="row">
            <div className="col-sm-12">
              <div className="card-block">
                <div className="react-table-custom">
                  <ReactTable
                    className="-striped -highlight"
                    data={this.state.data == null ? [] : this.state.data}
                    loading={this.state.searching} 
                    showPaginationTop
                    columns={[
                                  {
                                  Header: "Serial Number",
                                  id: 'serial',
                                  accessor: "serial",
                                  minResizeWidth: 200,
                                  width: 400,
                                  filterable: true,
                                  Filter: <div className="filter">
                                            <input
                                              style={{width: "100%", margin:"0 auto"}}
                                              value={this.state.searchValue}
                                              onChange={this.doSearch.bind(this)}
                                              type="text"
                                              name="search"
                                              placeholder="Search by Serial Number"
                                              className="table-search"
                                              autoComplete="off"
                                            />
                                          </div>
                                },                    
                                {
                                  Header: "Last Device Time",
                                  accessor: "latestDeviceTime",
                                  Cell: cell => this.renderNormalTime(cell),
                                  sortable: true,
                                  minResizeWidth: 100,
                                },                    
                                {
                                  Header: "Last Received From Time",
                                  accessor: "lastReceivedFromTime",
                                  Cell: cell => this.renderNormalTime(cell),
                                  sortable: true,
                                  minResizeWidth: 100,
                                }
                            ]}
                    {...dynamicOptions}
                  />
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default UnregisteredDevices;
