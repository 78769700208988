import React from 'react';
import * as actions from '../../actions/auth_actions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ForgotPasswordResetForm from './forgotpasswordreset_form';
import { ForgotPasswordService } from '../../services';
import i18next from "i18next";

@connect(state => {
    return {
      authenticated: state.auth.authenticated,
      errorMessage: state.auth.error
    }
  }, actions)
class ForgotPasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toSignin: false,
      authenticated: false,
      token: null,
      localErrorMessage: null
    };
  }

  componentDidMount() {
    ForgotPasswordService.lookupSession(this.props.match.params.token).then(response => {
      if (response.action.successful) {
        this.setState({ toSignin: false, token: response.action.message, localErrorMessage: null });
      }
      else {
        this.setState({ toSignin: true });
      }
    }).catch(error => {
      this.setState({ toSignin: true });
      console.error(error);
    });
  }

  handleSubmit({ newPassword, confirmNewPassword }) {
    ForgotPasswordService.resetPasswordAndLogin(this.state.token, newPassword, confirmNewPassword).then(response => {
      if (response.action.valid === false) {
        this.setState({ toSignin: true });
      }
      else if (response.action.successful) {
        localStorage.setItem('token', response.action.message);
        this.props.dispatchAuthUser();
        this.setState({ toSignin: false, authenticated: true });
      }
      else {
        this.setState({ toSignin: false, authenticated: false, token: this.state.token, localErrorMessage: response.action.message });
      }
    }).catch(error => {
      this.setState({ toSignin: true });
      console.error(error);
    });
  }

  renderLookingUpRequest() {
    return (
        <div className="login-wrapper">
          <div className="login-form-wrapper">
            <div className="login-form">
              <div className="login-form-branding text-center">
              </div>
              <div className="login-form-title">
                { i18next.t('messages.looking-up-request') }
              </div>
            </div>
          </div>
        </div>
    );
  }

  renderChangePasswordForm() {
    return (
      <div>
        <ForgotPasswordResetForm onSubmit={ this.handleSubmit.bind(this) } errorMessage={ this.state.localErrorMessage }/>
      </div>
    );
  }

  render() {
    if (this.state.toSignin === true) {
      return <Redirect to={{
            pathname: '/forgotpassword',
            errorMessage: i18next.t('messages.forgot-password-error-message')
        }} />
    }
    if (this.state.authenticated === true) {
      localStorage.setItem('global_success_message', i18next.t('messages.your-password-has-been-changed'));
      return <Redirect to={{
            pathname: '/monitoring/locations'
        }} />
    }
    return this.state.token ? this.renderChangePasswordForm() : this.renderLookingUpRequest();
  }
}

export default ForgotPasswordReset;
