import React from 'react';
import { connect } from 'react-redux';
import * as authActions from '../../actions/auth_actions';
import * as userActions from '../../actions/user_actions';
import { Redirect } from 'react-router-dom';

@connect(null, {...authActions, ...userActions})
class Signout extends React.Component {

  componentDidMount() {
    localStorage.clear();
    this.props.signoutUser();
    this.props.clearUserMe();
  }

  render() {
    return (<Redirect to={{ pathname: "/signin" }}/>);
  }
}

export default Signout;
