import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class DocumentsService {
    static getAllForLanguage(langaugeTag) {
      return new Promise((resolve, reject) => {
        axios.get(`${ROOT}docs/list?languageTag=${langaugeTag}`).then((data) => {
          resolve({ documents: data.data, response: data});
        }).catch((err) => {
          reject(err);
        });
      });
    }

    static getAllPageable(options=[], isSearch=false) {
        return new Promise((resolve, reject) => {
          let params = options.length == 0 ? '' : '?' + options.map(option => Object.keys(option).map((k, i) => `${k}=${option[k]}`)).join('&');
          axios.get(`${ROOT}docs${params}`).then((data) => {
            resolve({ documents: data.data.content, page: data.data, response: data });
          }).catch((err) => {
            reject(err);
          });
        });
    }  
    
    static get(docId) {
        return new Promise((resolve, reject) => {
          axios.get(`${ROOT}docs/${docId}`).then((data) => {
            resolve({ document: data.data, response: data });
          }).catch((err) => {
            reject(err);
          });
        });
      }

    static getWithSlug(slug) {
        return new Promise((resolve, reject) => {
          axios.get(`${ROOT}docs/slug/${slug}`).then((data) => {
            resolve({ document: data.data, response: data});
          }).catch((err) => {
            reject(err);
          })
        })
    }
      static add(document) {
        return new Promise((resolve, reject) => {
          axios.post(`${ROOT}docs`, document).then((data) => {
            resolve({ response: data });
          }).catch((err) => {
            reject(err);
          });
        });
      }
    
      static save(document, docId) {
        return new Promise((resolve, reject) => {
          axios.patch(`${ROOT}docs/${docId}`, document).then((data) => {
            resolve({ response: data });
          }).catch((err) => {
            reject(err);
          });
        });
      }

      static delete(docId) {
        return new Promise((resolve, reject) => {
          axios.delete(`${ROOT}docs/${docId}`).then((data) => {
            resolve({ response: data });
          }).catch((err) => {
            reject(err);
          });
        });
      }

      static uploadImage(file) {
        return new Promise((resolve, reject) => {
          var uploadData = new FormData();
          uploadData.append('file', file);
          axios.post(`${ROOT}docs/upload/images`, uploadData).then((data) => {
            resolve({ imageURI: `${ROOT}docs/images/${data.data}`});
          }).catch((err) => {
            reject(err);
          });
        });
      }
}

export default DocumentsService;