import React from 'react';
import { Redirect } from 'react-router-dom';

import { PageTitle } from '../PageTitle';
import { Message } from '../Message';
import EnterpriseWizardAddForm from './enterprise_wizard_add_form';
import { ActionRoleTemplatesService } from '../../services';

class EnterpriseAdd extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        ActionRoleTemplatesService.getAll().then(response => {
            this.setState({
              actionRoleTemplates: response.actionRoleTemplates.sort((a, b) => {return a.sortOrder - b.sortOrder}).map(template => ({value: template.id, label: template.name, template: template}))
            });
          }).catch(error => {
            console.error(error);
          });
    }

    handleLocalSuccess(localSuccessMessage) {
        this.setState({localSuccessMessage: localSuccessMessage});
    }
    
    handleLocalError(localErrorMessage) {
        this.setState({ localErrorMessage: localErrorMessage });
    }
    
    messageDismissCallback() {
        this.setState({
          localErrorMessage: null,
          localSuccessMessage: null
        });
    }

    renderMessages() {
        if (this.state.localErrorMessage) {
            return (
                <Message errorMessage={ this.state.localErrorMessage } messageDismissCallback={ this.messageDismissCallback.bind(this) } />
            );
        }

        if (this.props.location.state) {
            return (
                <Message messages={ this.props.location } />
            );
        }
        return null;
    }

    render() {
        if (this.state.localSuccessMessage) {
            return (
                <Redirect to={{
                  pathname: '/admin-admin/enterprises',
                  state: {
                    successMessage: 'Enterprise added'
                  }
                }}/>);
        } else if (this.state.actionRoleTemplates) {
            return (
                <div className="animated fadeIn">
                    <div className="row">
                    <PageTitle title="Add Enterprise"/>
                    </div>
                    {this.renderMessages()}
                    <div className="op-content-box">
                        <div className="row">
                            <div className="col-sm-12">
                                <EnterpriseWizardAddForm
                                    actionRoleTemplates={this.state.actionRoleTemplates}
                                    onSuccess={this.handleLocalSuccess.bind(this)}
                                    onError={this.handleLocalError.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<div className="loader">Loading...</div>)
        }
    }
}

export default EnterpriseAdd;