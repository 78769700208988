import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class SetupService {
    static post(file) {

        let formData = new FormData();
        formData.append('file', file);

        return new Promise((resolve, reject) => {
            axios.post(`${ROOT}setup`, formData).then(response => {
                resolve({results: response.data});
            }).catch(error => {
                reject(error);
            });
        });
    }
}

export default SetupService;