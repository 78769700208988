import React from 'react';
import i18next from 'i18next';

import { Button } from 'react-bootstrap';
import { PageTitle } from "../PageTitle";
import { Message } from '../Message';
import { CommissioningService, TimeFormattingService } from "../../services";

class DeviceSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      localErrorMessage: null,
      serialNumber: "",
      devices: [],
      exceptions: []
    };
  }

  localErrorHandler(localErrorMessage) {
    this.setState({ localErrorMessage: localErrorMessage });
  }

  messageDismissCallback() {
    this.setState({
      localErrorMessage: null,
      localSuccessMessage: null
    });
  }

  doSearch() {
    this.setState({searching: true});
    CommissioningService.deviceSearch(this.state.serialNumber).then(({response}) => {
      this.setState({devices: Object.values(response.data.searchResults), exceptions: response.data.exceptions, searching: false});
    }).catch((error) => {
      console.error(error);
      this.setState({localErrorMessage: error, searching: false});
    });
  }

  render() {
    return (
      <div className = "admin-devices">
        <div className="row">
          <PageTitle title={ "Search" } />
        </div>
        
        { this.state.localErrorMessage ? <Message errorMessage={ this.state.localErrorMessage } messageDismissCallback={ this.messageDismissCallback.bind(this) } /> : null }

        <div className="inner op-content-box">
          <div className="row">
            <div className="col-sm-12">
              <div className="card-block">
                
                  <input type="text" 
                         name="serialNumber" 
                         value={this.state.serialNumber}
                         onChange={(event) => this.setState({serialNumber: event.target.value})}
                         placeholder='Serial Number' 
                         className="input-sm form-control" /> 
                  <br/>
                  <Button onClick={() => this.doSearch()}>Search</Button>

                  {
                    this.state.searching ? 
                      <div className="loader">{i18next.t('loading')}</div> :
                      <div>
                      {
                        this.state.exceptions.length > 0 ? 
                        <div style={{paddingTop:20}}>
                          <h2>Issues:</h2>
                          <ul>
                            {this.state.exceptions.map(exceptionMessage => <li key={exceptionMessage}>{exceptionMessage}</li>)}
                          </ul>
                        </div> : null
                      }

                      {
                        this.state.devices.length > 0 ?
                        <div style={{paddingTop:20}}>
                          <h2>Matching Devices:</h2>

                          {this.state.devices.map(device => <DeviceDetail key={device.serialNumber} device={device}/>)}
                        </div> : null
                      }                      
                      </div>
                  }                  
              </div>
            </div>
          </div>          
        </div>        
      </div>
    );
  }
}

const DeviceDetail = ({device}) => (
  <div style={{border: 1, borderStyle: "solid", borderColor: "#000", padding: 5, marginTop: 10}}>
    Device Serial Number: <strong>{device.deviceIdentifier}</strong> <br/>
    OpSense Serial Number: <strong>{device.serialNumber}</strong> <br/>
    Previously Used: <strong>{device.previouslyUsed == null ? "Unknown" : (device.previouslyUsed ? "Yes" : "No")}</strong> <br/>
    OpSense Device Exists: <strong>{device.hasDevice == null ? "No" : (device.hasDevice ? "Yes" : "No")}</strong> <br/>
    {device.hasDevice && device.lastDeviceTime ? <span>Last Device Time: <strong>{TimeFormattingService.renderNormalTime(device.lastDeviceTime)}</strong><br/></span> : null}
    {
      device.hasInventoryRecord ? 
      <div>
        <i>Inventory Information:</i> <br/>
        <div style={{paddingLeft:10}}>
          Reference: <strong>{device.inventoryReferenceId}</strong> <br/>
          Imported On: <strong>{device.importedOn ? TimeFormattingService.renderDate(device.importedOn) : "Unknown"}</strong> <br/>
        </div>
      </div> : null
    }
    {
      device.commissionedAt ? 
      <div>
        <i>Commissioning Information:</i> <br/>
        <div style={{paddingLeft:10}}>
          Commissioned For: <strong>{device.commissionedForFullName} ({device.commissionedFor})</strong> <br/>
          Commissioned On: <strong>{device.commissionedAt ? TimeFormattingService.renderDate(device.commissionedAt) : "Unknown"}</strong>
        </div>
      </div> : null
    }
    {
      device.decommissionedAt ? <span>Removed On: {TimeFormattingService.renderDate(device.decommissionedAt)}</span> : null
    }
    {
      device.hasRegistration ? 
      <div>
        <i>Registration Information:</i> <br/>
        <div style={{paddingLeft:10}}>
          Registered For: <strong>{device.registeredForFullName} ({device.registeredFor})</strong> <br/>
          Registered On: <strong>{device.registeredAt ? TimeFormattingService.renderDate(device.registeredAt) : "Unknown"}</strong>
        </div>
      </div> : null
    }
  </div>
);

export default DeviceSearch;
