import React from 'react';
import { reduxForm, Field } from 'redux-form';
import i18next from 'i18next';
import { TextInputPhone } from '../../TextInputPhone'

// TODO this could be refactored
const validate = values => {
  const errors = {}
  const STANDARD_LENGTH = 17
   // validation for phone number, 17 being length of +1 (xxx) xxx-xxxx number
   if (!values.phone) {
     errors.phone = i18next.t('messages.please-enter-your-phone-number');
   } else if (values.phone.length != STANDARD_LENGTH) {
     errors.phone = i18next.t('messages.please-enter-valid-phone-number');
   }
  return errors;
};

class NotificationAddPhone extends React.Component {

  handleCancel() {
    this.props.onCancel();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return <div className="alert alert-danger">
        <strong>{i18next.t('error')}: </strong>{this.props.errorMessage}
      </div>
    }
  }


  render() {
    const {handleSubmit} = this.props;

    return (
      <div className="login-form">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-md-12">

              <Field
              name="phone"
              type="text"
              component={TextInputPhone}
              label={i18next.t('messages.phone-must-match-format')}
              />

            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 col-md-4"></div>
            <div className="col-sm-4 col-md-4" style={{ marginBottom: '5px' }}>
              <button type="submit" className="btn btn-primary btn-block">{i18next.t('form-labels.submit')}</button>
            </div>
            <div className="col-sm-4 col-md-4">
              <button type="button" onClick={this.handleCancel.bind(this)} className="btn btn-primary btn-block">{i18next.t('form-labels.cancel')}</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'NotificationAddPhone', validate
})(NotificationAddPhone);
